<template>
  <div id="app">
    <v-app id="inspire">
      <header>
        <v-app-bar app>
          <v-toolbar-title
            >{{ $t('app.title') }}
            {{ $t('navbar.management_screen') }}</v-toolbar-title
          >
          <div class="flex-grow-1"></div>
          <v-btn
            color="success"
            class="font-weight-bold"
            v-on:click="signOut"
            >{{ $t('navbar.logout') }}</v-btn
          >
        </v-app-bar>
      </header>

      <v-main>
        <v-container>
          <v-card class="mx-auto" max-width="800">
            <v-container fluid>
              <v-row dense>
                <v-col
                  v-for="card in cards"
                  :key="card.title"
                  :cols="card.flex"
                >
                  <v-card :color="card.color">
                    <v-btn
                      text
                      block
                      height="200px"
                      :to="{ name: card.name, params: { type: card.type } }"
                    >
                      <v-card-title>
                        <pre align="center">{{ card.title }}</pre>
                      </v-card-title>
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cards: [
        {
          title: this.$t('manager_main.view_child_info'),
          flex: 12,
          name: 'ManagerMemberList',
          type: '',
          color: '#ffefd5',
        },
        {
          title: this.$t('manager_main.attendance_history'),
          flex: 12,
          name: 'ManagerHistory',
          type: '',
          color: '#e0ffff',
        },
        {
          title: this.$t('manager_main.enter_room'),
          flex: 6,
          name: 'ManagerCheckin',
          type: 'in',
          color: 'success',
        },
        {
          title: this.$t('manager_main.leave_room'),
          flex: 6,
          name: 'ManagerCheckin',
          type: 'out',
          color: 'error',
        },
      ],
    }
  },
  computed: {
    APP_TITLE: {
      get: function () {
        return process.env.VUE_APP_TITLE
      },
    },
  },
  created() {
    this.isAuthenticated()
  },
  methods: {
    isAuthenticated() {
      this.$cognito
        .isAuthenticated()
        .then(() => {})
        .catch((_session) => {
          this.$router.replace('/manager/signin')
        })
    },
    signOut() {
      this.$cognito.logout()
      this.$router.replace('/manager/signin')
    },
  },
}
</script>
