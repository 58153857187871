<template>
  <v-row justify="center" class="pt-4 ma-0">
    <v-col cols="12" class="text-center px-0">
      <v-card-text class="text-album pt-0">{{
        $t('album.new_album')
      }}</v-card-text>
      <label class="custom-label"
        ><span>*</span>{{ $t('album.album_name') }}</label
      >
      <v-text-field
        ref="newNameAlbum"
        v-model="newNameAlbum"
        outlined
        dense
        height="44px"
        background-color="#EEEEEE"
        :rules="[required, textRules.regex, lengthRules, checkDuplicateAlbum]"
      >
      </v-text-field>
      <v-card-actions class="text-center d-block mt-3">
        <ENJIBtn
          @click="close"
          color="#333333"
          outlined
          class="px-9 mr-3 btn-font-size"
        >
          {{ $t('button.close') }}
        </ENJIBtn>
        <ENJIBtn
          @click="createAlbum"
          color="#6170E8"
          class="px-9 ml-3 btn-font-size"
        >
          {{ $t('button.save') }}
        </ENJIBtn>
      </v-card-actions>
    </v-col>
  </v-row>
</template>
<script>
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import dayjs from 'dayjs'

export default {
  components: {
    ENJIBtn,
  },
  props: {
    albums: Array,
    companyId: String,
  },
  data() {
    return {
      newNameAlbum: '',
      required: (value) => !!value || this.$t('alert.required_text'),
      textRules: {
        regex: (v) => /\S/.test(v) || this.$t('alert.required_text'),
      },
      lengthRules: (value) =>
        value.length <= 30 || this.$t('alert.max_length', { number: 30 }),
    }
  },
  mounted() {
    this.$refs.newNameAlbum.focus()
  },
  methods: {
    createAlbum() {
      if (this.$refs.newNameAlbum.validate()) {
        this.showLoading({ show: true, text: this.$t('alert.processing') })
        this.$axios
          .post('/album/update', {
            name: this.newNameAlbum.trim(),
            company_id: this.companyId,
            timestamp: dayjs().valueOf(),
          })
          .then((response) => {
            this.showLoading({ show: false })
            this.$emit('createdAlbum', response.data)
          })
          .catch((error) => {
            this.showLoading({ show: false })
            if (error.response && error.response.data?.message) {
              this.showSnack({
                text: this.$t('api_error.' + error.response.data.errorCode),
                isMobile: true,
                timeout: 6000,
              })
            } else {
              this.showSnack({
                text: this.$t('alert.regist_failed'),
                isMobile: true,
                timeout: 6000,
              })
            }
          })
      } else {
        this.$vuetify.goTo(0)
      }
    },
    checkDuplicateAlbum(value) {
      const albumExists = this.albums.some((item) => item.name === value.trim())
      return !albumExists || this.$t('album.album_already_exists')
    },
    close() {
      this.$emit('closeAlbumForm')
    },
  },
}
</script>

<style>
.custom-label {
  display: flex;
  color: #9e9e9e;
}
.custom-label > span {
  color: red;
}
.text-album {
  white-space: pre-wrap;
  font-size: 16px;
  text-align: left;
  padding-left: 0;
}
.btn-font-size {
  font-size: 16px !important;
  height: 44px !important;
}
</style>
