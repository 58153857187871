import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VContainer,{staticClass:"pa-0 staff-master-container",attrs:{"fluid":""}},[_c('div',[_c(VCard,{staticClass:"main mx-auto"},[_c(VCardTitle,[_c(VRow,{attrs:{"justify":"space-between"}},[_c(VCol,{attrs:{"cols":"4"}},[_c('InputSearch',{attrs:{"height":"44"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{staticClass:"d-flex justify-end",attrs:{"cols":"2"}},[_c('ENJIBtn',{staticClass:"px-6",attrs:{"color":"#6170EB","height":"44"},on:{"click":function($event){return _vm.editItem({
                    id: '',
                    name: '',
                    qr: '',
                    email: '',
                    company_id: ''
                  })}}},[_vm._v(" "+_vm._s(_vm.$t('master.create_new'))+" ")])],1)],1)],1),_c('hr',{staticStyle:{"border-top":"1px solid #d0d0d0"},attrs:{"size":"2"}}),_c(VDataTable,{staticClass:"elevation-1",attrs:{"height":"66vh","page":_vm.page,"headers":_vm.headers,"items":_vm.adminList,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"custom-filter":_vm.customFilter,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"hide-default-footer":"","fixed-header":"","loading-text":_vm.$t('alert.loading'),"no-results-text":_vm.$t('alert.not_found'),"item-style":"font-size: 16px"},on:{"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:`item.edit`,fn:function({ item }){return [_c(VIcon,{staticClass:"mr-3",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:`item.name`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:`item.email`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.email)+" ")]}},{key:`item.company`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.company.name)+" ")]}}],null,true)}),_c('Pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)]),(_vm.dialog)?_c('div',[_c(VDialog,{attrs:{"persistent":"","width":"600px","height":"100vh","transition":_vm.dialogTransition,"content-class":"custom-dialog-content"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('AdminForm',{attrs:{"adminData":_vm.adminData,"adminList":_vm.adminList},on:{"closeDialog":_vm.onCloseDialog,"createStaff":_vm.onCreateStaff,"delete":_vm.deleteStaff}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }