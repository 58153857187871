import Vue from 'vue'
import Vuex from 'vuex'
import snackbar from '../plugins/modules/snackbar'
import loading from '../plugins/modules/loading'
import tagSearchResult from '../plugins/modules/tagSearchResult'

Vue.use(Vuex)

export default new Vuex.Store({
  namespaced: true,
  modules: {
    snackbar,
    loading,
    tagSearchResult,
  },
})
