import axios from 'axios'

export default {
  async getLiffId(companyId) {
    try {
      const url = `${process.env.VUE_APP_API_URL}/company/info`
      const response = await axios.post(
        url,
        { id: `${companyId}` },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': process.env.VUE_APP_API_KEY,
          },
        }
      )
      return {
        companyId: response.data.id,
        liffId: response.data.liff_id,
        liffURL: response.data.liff_url,
        oaLineId: response.data.official_line_account_id,
      }
    } catch (error) {
      console.error('Error fetching LIFF ID:', error)
      throw error
    }
  },
}
