<template>
  <div>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-card-title @click="toggleSelectCalendarBox" class="font-weight-bold">
          {{ title(targetDay) }} <v-icon>mdi-menu-down</v-icon>

          <div>
            <v-menu
              v-model="showCalendar"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }"></template>
              <v-date-picker
                v-model="targetDay"
                @input="selectDay"
                :max="maxDate"
                :locale="language == 'ja' ? 'ja' : 'en'"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-card-title>

        <v-card-title class="pt-0">
          <InputSearch
            height="44"
            v-model="search"
            :user="user"
            :isMobile="true"
          />
        </v-card-title>

        <v-tabs v-model="tab" color="#6170E8" class="history-tab" show-arrows>
          <v-tabs-slider color="#6170E8"></v-tabs-slider>
          <v-tab v-for="item in tabList" :key="item.id" style="font-size: 20px">
            {{ item.text }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="1">
            <v-card flat>
              <v-data-table
                height="calc(100vh - 200px)"
                :page.sync="allRecordPage"
                :items-per-page="-1"
                :headers="entry_record_headers"
                :items="allRecordList"
                :search="search"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :custom-filter="customFilter"
                hide-default-footer
                fixed-header
                no-data-text=""
                :no-results-text="$t('alert.child_not_found')"
                class="elevation-1 rounded-0"
                item-style="font-size: 16px"
              >
                <template v-slot:item="{ item }">
                  <v-list-item :key="item.id" class="detail-row my-2 mx-4">
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold mb-2">{{
                        item.name
                      }}</v-list-item-title>

                      <v-list-item-subtitle>
                        <v-row>
                          <v-col>
                            {{ $t('manager_main.enter_room') }}:
                            <v-icon
                              small
                              class="mr-2"
                              :color="item.enter ? '#161A1A' : '#DC313B'"
                            >
                              {{ getEnterIcon(item.enter) }}
                            </v-icon>
                          </v-col>

                          <v-col>
                            {{ $t('manager_main.leave_room') }}:
                            <v-icon
                              small
                              class="mr-2"
                              :color="item.leave ? '#161A1A' : '#DC313B'"
                            >
                              {{ getEnterIcon(item.leave) }}
                            </v-icon>
                          </v-col>

                          <v-col>
                            {{ $t('manager_main.absence') }}:
                            <v-icon
                              small
                              class="mr-2"
                              :color="item.absence ? '#161A1A' : '#DC313B'"
                            >
                              {{ getEnterIcon(item.absence) }}
                            </v-icon>
                          </v-col>
                        </v-row>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <v-tab-item key="2">
            <v-card flat>
              <v-data-table
                height="calc(100vh - 200px)"
                :page.sync="missRecordPage"
                :items-per-page="-1"
                :headers="record_headers"
                :items="missRecordList"
                :search="search"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :custom-filter="customFilter"
                hide-default-footer
                fixed-header
                no-data-text=""
                :no-results-text="$t('alert.child_not_found')"
                class="elevation-1 rounded-0"
                item-style="font-size: 16px"
              >
                <template v-slot:item="{ index, item }">
                  <v-list-item :key="item.id" class="detail-row my-2 mx-4">
                    <v-list-item-content>
                      <v-row>
                        <v-col>
                          <v-list-item-title class="font-weight-bold mb-2">{{
                            item.name
                          }}</v-list-item-title>
                        </v-col>
                        <v-col>
                          {{ $t('manager_main.enter_room') }}:
                          <v-icon
                            small
                            class="mr-2"
                            :color="item.enter ? '#161A1A' : '#DC313B'"
                          >
                            {{ getEnterIcon(item.enter) }}
                          </v-icon>
                        </v-col>

                        <v-col>
                          {{ $t('manager_main.leave_room') }}:
                          <v-icon
                            small
                            class="mr-2"
                            :color="item.leave ? '#161A1A' : '#DC313B'"
                          >
                            {{ getEnterIcon(item.leave) }}
                          </v-icon>
                        </v-col>
                      </v-row>

                      <div
                        class="mt-0 d-flex justify-center pb-2"
                        :class="{
                          'disable-events':
                            missRecordLoadingList[index].loading,
                        }"
                      >
                        <ENJIBtn
                          color="#6170E8"
                          height="28"
                          key="enter_room"
                          :class="{
                            'disable-events': item.enter,
                            'mr-2': true,
                          }"
                          @click="manualCheck(index, 'in', item.memberId)"
                        >
                          {{ $t('manager_main.enter_room') }}
                        </ENJIBtn>
                        <ENJIBtn
                          color="#6170E8"
                          height="28"
                          key="leave_room"
                          :class="{
                            'disable-events': item.leave || !item.enter,
                            'mr-2': true,
                          }"
                          @click="manualCheck(index, 'out', item.memberId)"
                        >
                          {{ $t('manager_main.leave_room') }}
                        </ENJIBtn>
                        <ENJIBtn
                          color="#6170E8"
                          height="28"
                          key="absence"
                          :class="{
                            'disable-events': item.enter || item.leave,
                            'mr-2': true,
                          }"
                          @click="manualCheck(index, 'absence', item.memberId)"
                        >
                          {{ $t('manager_main.absence') }}
                        </ENJIBtn>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>

          <v-tab-item key="3">
            <v-card flat>
              <v-data-table
                height="calc(100vh - 200px)"
                :page.sync="attendancePage"
                :items-per-page="-1"
                :items="historyList"
                :headers="history_headers"
                :search="search"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :custom-filter="customFilter"
                hide-default-footer
                fixed-header
                no-data-text=""
                :no-results-text="$t('alert.child_not_found')"
                class="elevation-1 rounded-0"
                item-style="font-size: 16px"
              >
                <template v-slot:item="{ item }">
                  <v-list-item :key="item.id" class="detail-row my-2 mx-4">
                    <v-list-item-content>
                      <v-list-item-title class="font-weight-bold mb-2">
                        {{
                          item.name === '不明な園児'
                            ? $t('history.noname')
                            : item.name
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <div>
                          {{ $t('history.status') }}:
                          <b>{{ formatStatus(item.status) }}</b>
                        </div>

                        <div class="mt-1">
                          {{ $t('history.auth_method') }}:
                          <v-icon size="20" class="mr-2">
                            {{ authIcon(item.type) }}
                          </v-icon>
                          <b>{{ authIconName(item.type) }}</b>
                        </div>

                        <div class="mt-1">
                          {{ $t('history.entry_exit_time') }}:
                          <b>{{ format(item.timestamp) }}</b>
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <hr size="2" style="border-top: 1px solid #d0d0d0" />
  </div>
</template>
<style>
/*== custom Table ==*/
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th
  > span {
  color: #5d6060;
  font: normal normal normal 16px/18px Noto Sans JP;
  font-size: 16px;
  word-break: break-word !important;
}
.elevation-1.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 16px;
  font: normal normal normal 16px/18px Noto Sans JP;
  color: #161a1a;
  min-height: 57px;
  height: 57px;
}

.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-child(1),
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:nth-child(1) {
  padding-left: 15px;
  padding-right: 0;
}
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-child(2),
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:nth-child(2) {
  padding-left: 6px;
  padding-right: 16px;
}

.history-tab {
  border-bottom: 1px solid #d0d0d0;
}
.disable-events {
  pointer-events: none;
  opacity: 0.3;
}

.detail-row {
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 0px 6px #00000029;
}
</style>

<script>
import dayjs from 'dayjs'
import Pagination from '@/components/Pagination.vue'
import InputSearch from '@/components/inputs/InputSearch.vue'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'

export default {
  components: {
    InputSearch,
    Pagination,
    ENJIBtn,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      memberList: [],
      targetDay: dayjs().format('YYYY-MM-DD'),
      maxDate: new Date().toISOString().substr(0, 10),
      showCalendar: false,
      search: '',
      sortBy: 'timestamp',
      sortDesc: true,
      historyList: [],
      history_headers: [{ value: 'name', sortable: false }],
      record_headers: [{ value: 'name', sortable: false }],
      entry_record_headers: [{ value: 'name', sortable: false }],
      tab: null,
      attendancePage: 1,
      missRecordPage: 1,
      allRecordPage: 1,
      itemsPerPage: 100,
      tabList: [
        { id: 1, text: this.$t('manager_main.enter_and_leave') },
        { id: 2, text: this.$t('manager_main.miss_record') },
        { id: 3, text: this.$t('manager_main.attendance_history') },
      ],
      language: window.navigator.userLanguage || window.navigator.language,
      missRecordLoadingList: [],
    }
  },

  created() {
    var firstDayOfMonth = dayjs().startOf('month').format('YYYY-MM-DD')
    this.updateRange({ start: { date: firstDayOfMonth }, isShowLoading: true })
  },

  computed: {
    attendancePageCount() {
      return Math.ceil(this.historyList.length / this.itemsPerPage)
    },
    missRecordPageCount() {
      return Math.ceil(this.missRecordList.length / this.itemsPerPage)
    },
    allRecordPageCount() {
      return Math.ceil(this.allRecordList.length / this.itemsPerPage)
    },
    missRecordList() {
      var attendance = {}
      var absenceMemberList = {}
      this.historyList.map((v) => {
        // if child is unknow or absent, not add to missRecordList
        if (absenceMemberList[v.memberId]) {
          return
        }
        if (v.status == this.$constants.checkInStatus.absence) {
          absenceMemberList[v.memberId] = true
          return
        }
        attendance[v.memberId] = attendance[v.memberId] || {}
        attendance[v.memberId].name = v.name
        attendance[v.memberId].tags = v.tags
        attendance[v.memberId].memberId = v.memberId
        attendance[v.memberId].loading = false

        switch (v.status) {
          case this.$constants.checkInStatus.checkIn: // enter
            attendance[v.memberId].enter = true
            break
          case this.$constants.checkInStatus.checkOut: // leave
            attendance[v.memberId].leave = true
            break
          default:
            break
        }
      })
      // Add detail of no checkin, checkout childs
      this.memberList.map((v) => {
        // if Child already in missRecordList or child not in missRecordList but in absenceList, dont add this chill to missRecordList
        if (attendance[v.id] || absenceMemberList[v.id]) {
          return
        }

        attendance[v.id] = {
          memberId: v.id,
          name: `${v.firstname} ${v.lastname}`,
          tags: v.tags,
          enter: false,
          leave: false,
          loading: false,
        }
      })

      var absenceList = Object.values(attendance).filter(
        (v) => !(v.enter && v.leave)
      )
      this.missRecordLoadingList = absenceList
      return absenceList
    },

    allRecordList() {
      var attendance = {}
      this.historyList.map((v) => {
        attendance[v.memberId] = attendance[v.memberId] || {}
        attendance[v.memberId].name = v.name
        attendance[v.memberId].tags = v.tags
        attendance[v.memberId].memberId = v.memberId
        switch (v.status) {
          case this.$constants.checkInStatus.checkIn: // enter
            attendance[v.memberId].enter = true
            break
          case this.$constants.checkInStatus.checkOut: // leave
            attendance[v.memberId].leave = true
            break
          case this.$constants.checkInStatus.absence: // absence
            attendance[v.memberId].absence = true
            break
          default:
            break
        }
      })

      this.memberList.forEach((v) => {
        if (attendance[v.id]) {
          return
        }

        attendance[v.id] = {
          memberId: v.id,
          name: `${v.firstname} ${v.lastname}`,
          tags: v.tags,
          enter: false,
          leave: false,
          absence: false,
        }
      })

      return Object.values(attendance)
    },
  },

  methods: {
    title(date) {
      return dayjs(date).format(
        this.$i18n.locale === 'ja'
          ? this.$constants.dateFormatDayJP
          : this.$constants.dateFormat
      )
    },
    setData(selectedDay, historyList) {
      this.attendancePage = 1
      this.missRecordPage = 1
      this.targetDay = selectedDay
      this.historyList = historyList
    },
    format(date) {
      return dayjs(date).format(
        this.$i18n.locale === 'ja' ? 'HH時mm分ss秒' : 'HH:mm:ss'
      )
    },
    formatStatus(value) {
      switch (value) {
        case this.$constants.checkInStatus.checkIn:
          return this.$t('manager_main.enter_room')
        case this.$constants.checkInStatus.checkOut:
          return this.$t('manager_main.leave_room')
        case this.$constants.checkInStatus.absence:
          return this.$t('manager_main.absence')
      }
    },
    authIcon(value) {
      switch (value) {
        case this.$constants.checkInType.face:
          return 'mdi-face-recognition'
        case this.$constants.checkInType.qr:
          return 'mdi-qrcode'
      }
    },
    getEnterIcon(value) {
      switch (value) {
        case true:
          return 'mdi-checkbox-blank-circle-outline'
        case false:
        case undefined:
          return 'mdi-close'
      }
    },
    authIconName(value) {
      switch (value) {
        case this.$constants.checkInType.face:
          return this.$t('member_check.face_reg')
        case this.$constants.checkInType.qr:
          return this.$t('kindergarten_list.qrcode')
        case this.$constants.checkInType.manual:
          return this.$t('member_check.manual')
      }
    },
    getChipColor(value) {
      switch (value) {
        case this.$constants.checkInStatus.checkIn:
          return 'success'
        case this.$constants.checkInStatus.checkOut:
          return 'error'
      }
    },
    update(historyList) {
      this.historyList = historyList
    },
    detail(selectedDay, historyList, detailMemberList) {
      this.memberList = detailMemberList
      this.setData(selectedDay, historyList)
    },
    resetDetail(selectedDay) {
      var firstDayOfMonth = dayjs(selectedDay)
        .startOf('month')
        .format('YYYY-MM-DD')
      this.updateRange({
        start: { date: firstDayOfMonth },
        isShowLoading: false,
      })
    },
    showSelectCalendar() {
      this.showCalendar = true
    },
    updateRange({ start, isShowLoading }) {
      if (isShowLoading !== false) {
        this.showLoading({ show: true, text: this.$t('alert.processing') })
      }
      this.$axios
        .post('/history/list', {
          token: this.user.token,
          company_id: this.user.companyId,
          start: start.date,
        })
        .then((response) => {
          this.showLoading({ show: false })
          const hasAbsenceDays = []
          let attendance = {}
          const memberList = response.data.memberList
          const historyList = response.data.historyList
          historyList.map((history) => {
            const timestamp = dayjs(history.timestamp).format(
              this.$constants.dateFormat
            )
            attendance[timestamp] = attendance[timestamp] || {}

            const member = memberList.find(
              (member) => history.memberId === member.id
            )
            if (member) {
              history.name = `${member.firstname} ${member.lastname}`
              history.tags = member.tags
            } else {
              history.name = this.$t('history.deleted_child')
            }
            attendance[timestamp]['memberCount'] =
              attendance[timestamp]['memberCount'] || history.member_count
            attendance[timestamp][history.memberId] = attendance[timestamp][
              history.memberId
            ] || { in: false, out: false }
            switch (history.status) {
              case this.$constants.checkInStatus.checkIn:
                attendance[timestamp][history.memberId]['in'] = true
                break
              case this.$constants.checkInStatus.checkOut:
                attendance[timestamp][history.memberId]['out'] = true
                break
              case this.$constants.checkInStatus.absence:
                attendance[timestamp][history.memberId]['in'] = true
                attendance[timestamp][history.memberId]['out'] = true
                break
            }
          })
          const startOfNextDay = (timeParam) =>
            dayjs(timeParam).add(1, 'day').startOf('day').valueOf()
          const detailMemberList = memberList.filter(
            (member) => !(member.created_at > startOfNextDay(this.targetDay))
          )
          for (const timeKey in attendance) {
            const memberCountOfThisDay = attendance[timeKey]['memberCount']
            const memberCheckList = attendance[timeKey]
            // get count of full checkin member in this day
            const fullAttendanceCount = Object.values(memberCheckList).reduce(
              (count, value) =>
                count + (value.in === true && value.out === true ? 1 : 0),
              0
            )

            if (fullAttendanceCount < memberCountOfThisDay) {
              hasAbsenceDays.push(timeKey)
            }
          }

          this.update(historyList)
          this.detail(
            this.targetDay,
            historyList.filter((history) => {
              const start = dayjs(history.timestamp).format(
                this.$constants.dateFormat
              )
              if (start === this.targetDay) return true
            }),
            detailMemberList
          )
        })
        .catch((error) => {
          console.log(error)
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.error_get_data_failed'),
            color: 'error',
            timeout: 6000,
          })
        })
    },

    manualCheck(index, type, memberId) {
      this.showLoading({ show: true, text: this.$t('alert.processing') })
      this.missRecordLoadingList[index].loading = true
      let memberCount = this.missRecordList.length
      if (this.historyList.length !== 0) {
        memberCount = this.historyList[0].member_count
      }

      var endOfDay = dayjs(this.targetDay)
        .set('hour', 21)
        .set('minute', 59)
        .valueOf()

      this.$axios
        .post(`/check/${type}/manual`, {
          token: this.user.token,
          id: memberId,
          timestamp: endOfDay,
          company_id: this.user.companyId,
          member_count: memberCount,
        })
        .then((response) => {
          if (response.status == 200) {
            this.resetDetail(this.targetDay)
          }
          this.showLoading({ show: false })
        }).catch((error) => {
          this.resetDetail(this.targetDay)
          console.log(error)
          this.showSnack({
              text: this.$t('alert.send_failed'),
              isMobile: true,
              color: 'error',
              timeout: 3000,
            })
          this.showLoading({ show: false })
        })
    },

    customFilter(value, search, item) {
      const searchLowerCase = this.search.toLowerCase()
      return (
        item.name.toLowerCase().includes(searchLowerCase) ||
        (item.tags?.length > 0 &&
          item.tags.some((tag) =>
            tag.name.trim().toLowerCase().includes(searchLowerCase)
          ))
      )
    },

    selectDay(date) {
      var firstDayOfMonth = dayjs(date).startOf('month').format('YYYY-MM-DD')
      this.updateRange({
        start: { date: firstDayOfMonth },
        isShowLoading: true,
      })
      this.toggleSelectCalendarBox()
    },

    toggleSelectCalendarBox() {
      this.showCalendar = !this.showCalendar
    },
  },
}
</script>
