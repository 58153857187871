import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,{attrs:{"align":"center","height":"100vh"}},[_c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VRow,{attrs:{"justify":"center"}},[(_vm.status == 0)?_c(VCol,{staticClass:"pt-1 px-0"},[_c('MemberList',{attrs:{"member-list":_vm.memberList,"user":_vm.user},on:{"form":_vm.onForm,"back":_vm.onList,"qr":_vm.onDispQR,"edit":_vm.onEdit}})],1):(_vm.status == 1)?_c(VCol,{staticClass:"member-info-screen"},[_c('CreateForm',{attrs:{"user":_vm.user},on:{"back":_vm.onList,"onClickScan":_vm.openQrScan}})],1):(_vm.status == 3)?_c(VCol,{staticClass:"member-info-screen"},[_c('TermOfUse',{on:{"form":_vm.openCreateForm,"back":_vm.onList}})],1):(_vm.status == 5)?_c(VCol,[_c('QrScan',{on:{"onScanned":_vm.handleQr,"back":_vm.openCreateForm}})],1):(_vm.status == 6)?_c(VCol,{staticClass:"pt-1 px-0"},[_c('EditList',{attrs:{"member-list":_vm.memberList,"user":_vm.user},on:{"back":_vm.onList}})],1):_vm._e()],1),_c('div',[_c(VDialog,{attrs:{"persistent":"","width":"358px"},model:{value:(_vm.qrDialog),callback:function ($$v) {_vm.qrDialog=$$v},expression:"qrDialog"}},[_c('DispQR',{attrs:{"qrData":_vm.qrData,"name":_vm.name,"id":_vm.id,"isMobile":"","isMember":""},on:{"clickClose":function($event){_vm.qrDialog = false},"update":_vm.updateQR}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }