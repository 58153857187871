<template>
  <v-main align="center">
    <v-container fill-height fluid>
      <v-row>
        <v-col cols="12" class="pt-1 px-0">
          <h2 style="font-size: 16px; color: #333333; margin-bottom: 16px">
            {{ message }}
          </h2>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  props: {
    user: Object,
  },
  data() {
    return {
      status: -1,
      message: '',
    }
  },
  created() {
    this.connect()
  },
  methods: {
    changeRichMenu(companyId) {
      this.showLoading({ show: true, text: this.$t('alert.processing') })
      this.$axios
        .post('/richmenu/change', {
          token: this.user.token,
          companyId
        })
        .then(() => {
          this.showLoading({ show: false })
          this.message = this.$t('line_connect.user_connect_success')
          liff.closeWindow()
        })
        .catch(() => {
          this.showLoading({ show: false })
          this.message = this.$t('line_connect.user_connect_fail')
          this.showSnack({
            text: this.$t('alert.error_processing_failed'),
            isMobile: true,
            color: 'error',
            timeout: 6000,
          })
        })
    },
    connect() {
      const qr = this.$route.params.qr
      this.showLoading({ show: true })
      this.$axios
        .post('/member/user/connect', {
          token: this.user.token,
          id: qr,
        })
        .then(async (response) => {
          if(response.data !== null){
            this.changeRichMenu(response.data.company_id)
            this.status = 0
            this.message = this.$t('line_connect.user_connect_success')
            this.showLoading({ show: false })
          }
        })
        .catch((error) => {
          console.log(error)
          this.status = 0
          this.message = this.$t('line_connect.user_connect_fail')
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.update_failed'),
            isMobile: true,
            color: 'error',
            timeout: 6000,
          })
        })
    },
  },
}
</script>
