import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c(VApp,{attrs:{"id":"inspire"}},[_c('header',[_c(VAppBar,{attrs:{"app":""}},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.$t('app.title'))+" "+_vm._s(_vm.$t('navbar.management_screen')))]),_c('div',{staticClass:"flex-grow-1"}),_c(VBtn,{staticClass:"font-weight-bold",attrs:{"color":"success"},on:{"click":_vm.signOut}},[_vm._v(_vm._s(_vm.$t('navbar.logout')))])],1)],1),_c(VMain,[_c(VContainer,[_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"800"}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{attrs:{"dense":""}},_vm._l((_vm.cards),function(card){return _c(VCol,{key:card.title,attrs:{"cols":card.flex}},[_c(VCard,{attrs:{"color":card.color}},[_c(VBtn,{attrs:{"text":"","block":"","height":"200px","to":{ name: card.name, params: { type: card.type } }}},[_c(VCardTitle,[_c('pre',{attrs:{"align":"center"}},[_vm._v(_vm._s(card.title))])])],1)],1)],1)}),1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }