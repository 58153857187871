<template>
  <v-card class="mx-auto pt-3">
    <v-card-text class="bg-white py-0 px-3">
      <div>
        <v-img
          v-if="mediaItem.type === 'image'"
          :src="mediaItem.url"
          class="preview-image"
          contain
        ></v-img>
        <video
          v-else-if="mediaItem.type === 'video'"
          controls
          :src="mediaItem.url"
          width="100%"
          :height="isPc ? '550px' : '270px'"
        ></video>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-center bg-white px-9 my-3">
      <ENJIBtn @click="close" color="#333333" outlined>
        {{ $t('button.close') }}
      </ENJIBtn>
    </v-card-actions>
  </v-card>
</template>
<script>
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'

export default {
  components: {
    ENJIBtn,
  },
  props: {
    mediaItem: Object,
    isPc: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.$emit('clickCloseDisplayMedia')
    },
  },
}
</script>

<style scoped>
.bg-white {
  background-color: #ffffff !important;
}
.preview-image {
  min-height: 400px;
  width: 100%;
  height: 100%;
}
</style>
