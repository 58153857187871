<template>
  <div>
    <div class="pa-0" style="height: 100%">
      <Tabs :items="items" slot-scope="" />
    </div>
  </div>
</template>

<script>
import Tabs from '@/components/Tabs.vue'
import StaffList from '@/views/manager/master/StaffList.vue'
import TagList from '@/views/manager/master/TagList.vue'

export default {
  components: {
    Tabs,
  },
  data() {
    return {
      tab: null,
      activeTab: 1,
      items: [
        {
          index: 1,
          title: this.$t('master.staff_tab'),
          content: StaffList,
        },
        {
          index: 2,
          title: this.$t('master.tag_tab'),
          content: TagList,
        },
      ],
    }
  },
}
</script>
