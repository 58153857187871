<template>
  <div>
    <v-container
      v-if="status === 0"
      class="message-bulk-container px-4"
      style="height: 100vh"
    >
      <v-card class="main mx-auto" height="100%">
        <div
          class="pt-4 px-4"
          style="height: calc(100% - 76px); overflow: auto"
        >
          <v-chip-group column multiple v-model="selectedTags">
            <v-chip style="height: 40" v-for="tag in tagList" :key="tag.id">
              {{ tag.name }}
            </v-chip>
          </v-chip-group>
        </div>
        <v-card-actions
          class="py-4 text-center d-block"
          style="border-top: 1px solid #c9c9c9"
        >
          <ENJIBtn
            height="44"
            width="140"
            @click="openMemberDialog"
            color="#333333"
            outlined
            class="mr-3"
          >
            {{ $t('send_bulk_message.sender_confirmation') }}
          </ENJIBtn>
          <ENJIBtn
            height="44"
            width="140"
            @click="openMessageForm"
            color="#6170E8"
            class="ml-3"
            :class="{ 'disable-events': (filteredMemberList.length == 0 || receiverSelected.length == 0) }"
          >
            {{ $t('send_bulk_message.create_message') }}
          </ENJIBtn>
        </v-card-actions>
      </v-card>
    </v-container>
    <div v-else-if="status === 1">
      <MessageBulk
        ref="messageBulk"
        @onOpenMemberDialog="openMemberDialog"
        @clickSend="openMessageConfirm"
        :fileList="fileList"
        @clickRemoveFile="removeFile"
        @uploadFileSuccess="addFile"
        :user="user"
        :isMobile="true"
        @back="back"
      ></MessageBulk>
      <div>
        <v-dialog
          v-if="msgConfirmDialog"
          persistent
          v-model="msgConfirmDialog"
          width="100%"
          content-class="mx-4"
        >
          <MessageBulkConfirm
            @clickClose="onMsgConfirmDialogClose"
            :messageConfirm="messageConfirm"
            :fileList="fileList"
            :is-mobile="true"
            @clickSubmit="sendMsg"
          ></MessageBulkConfirm>
        </v-dialog>
      </div>
    </div>
    <!-- dialog -->
    <div>
      <v-dialog
        persistent
        v-model="memberDialog"
        scrollable
        content-class="mx-4"
      >
        <v-card height="447px">
          <v-card-title
            class="justify-center font-weight-bold"
            style="width: 100%; border-bottom: 1px solid #c9c9c9"
            >{{ $t('send_bulk_message.sender_confirmation') }}</v-card-title
          >
          <v-card-text style="padding: 0px 16px 6px 16px">
            <div v-for="member in filteredMemberList">
                <v-col
                  cols="8"
                  class="d-flex justify-start align-center"
                  style="padding: 6px 0px 6px 0px"
                >
                  <v-checkbox
                    class="text-end checkbox-delete"
                    v-model="receiverSelected"
                    :value="member.id"
                  ></v-checkbox>
                  <v-avatar size="36px">
                    <img
                      alt="Avatar"
                      :src="member.image | base64ToUrl(accountIcon)"
                    />
                  </v-avatar>
                  <span
                    style="
                      font-size: 16px;
                      font-weight: 700;
                      color: #333333;
                      margin-left: 16px;
                    "
                  >
                    {{ member.full_name }}
                  </span>
                </v-col>
            </div>
          </v-card-text>
          <v-card-actions
            class="py-4 text-center d-block"
            style="border-top: 2px solid #c9c9c9; width: 100%"
          >
            <ENJIBtn
              height="44"
              width="140"
              @click="closeMemberDialog"
              color="#333333"
              outlined
              class="mr-3"
            >
              {{ $t('button.close') }}
            </ENJIBtn>
            <ENJIBtn
              height="44"
              width="140"
              @click="openMessageForm"
              color="#6170E8"
              class="ml-3"
              :class="{ 'disable-events': (filteredMemberList.length == 0 || receiverSelected.length == 0) }"
            >
              {{ $t('send_bulk_message.create_message') }}
            </ENJIBtn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<style scoped>
::v-deep(.v-input--selection-controls__ripple:before) {
  background: none !important;
}
.v-chip--active {
  background-color: #6170e8 !important;
  color: white !important;
  border: 1px solid #6170e8;
}
.message-bulk-container .theme--light.v-chip:focus::before,
.message-bulk-container .theme--light.v-chip--active::before {
  opacity: 0;
}
.v-chip.v-chip--clickable.theme--light.v-size--default {
  background-color: #fff;
  border: 1px solid #707070;
}
::v-deep(.v-btn__content) {
  font-size: 16px;
  letter-spacing: 0;
}
.disable-events {
  pointer-events: none;
  opacity: 0.3;
}
</style>
<script>
import accountIcon from '@/assets/account-circle-outline.svg'

import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import MessageBulk from '@/components/MessageBulk.vue'
import MessageBulkConfirm from '@/components/MessageBulkConfirm.vue'
import Tags from '@/components/Tags.vue'

export default {
  components: {
    ENJIBtn,
    MessageBulk,
    MessageBulkConfirm,
    Tags,
  },
  props: {
    user: Object,
  },
  computed: {
    APP_TITLE: {
      get: function () {
        return process.env.VUE_APP_TITLE
      },
    },
  },
  data() {
    return {
      accountIcon: accountIcon,
      status: -1,
      memberList: [],
      tagList: [],
      allTagsIndex: 0,
      selectedTags: [0],
      loading: true,
      memberDialog: false,
      msgConfirmDialog: false,
      messageConfirm: '',
      fileList: [],
      receiverSelected: [],
    }
  },
  mounted() {
    this.showLoading({ show: true })
    this.initialize()
  },
  filters: {
    base64ToUrl: (base64, defaultUrl) => {
      return base64 ? `data:image/jpeg;base64,${base64}` : defaultUrl
    },
  },
  watch: {
    selectedTags(newVal, oldVal) {
      this.handleTagChanges(newVal, oldVal)
    },
    filteredMemberList(newVal) {
      this.receiverSelected = newVal.map(item => item.id)
    }
  },
  computed: {
    filteredMemberList() {
      let filteredData = this.memberList
      // Apply tag filter
      filteredData = filteredData.filter((item) => {
        if (this.selectedTags.includes(this.allTagsIndex)) {
          return true;
        } else {
          return this.selectedTags.some((index) =>
            item.tags.some((tag) => tag.id === this.tagList[index].id)
          );
        }
      })
      return filteredData
    },
  },
  methods: {
    async initialize() {
      Promise.allSettled([this.getTags(), this.getMembers()]).then(
        (results) => {
          const [tagResult, memberResult] = results
          if (
            tagResult.status == 'rejected' ||
            memberResult.status == 'rejected'
          ) {
            this.$router.replace('/500')
            return
          }
          this.tagList = tagResult.value.data
          this.tagList.unshift({name: this.$t('send_bulk_message.all_tag')})
          this.memberList = memberResult.value.data.map((item) => ({
            ...item,
            tags: this.tagList.filter((tag) => item.tags.includes(tag.id)),
          }))
          this.loading = false
          this.status = 0
          this.showLoading({ show: false })
        }
      )
    },
    handleTagChanges(newTags, oldTags) {
      if (!newTags.includes(this.allTagsIndex) && !oldTags.includes(this.allTagsIndex)) {
        return
      }

      newTags.forEach(newTag => {
        if (!oldTags.includes(newTag)) {
          if (newTag != this.allTagsIndex) {
            this.selectedTags = this.selectedTags.filter(item => item !== this.allTagsIndex)
          } else {
            this.selectedTags = [this.allTagsIndex]
          }
        }
      })
    },
    openMessageForm() {
      this.status = 1
      this.memberDialog = false
    },
    openMemberDialog() {
      this.memberDialog = true
    },
    closeMemberDialog() {
      this.memberDialog = false
    },
    openMessageConfirm(msg) {
      this.msgConfirmDialog = true
      this.messageConfirm = msg
    },
    onMsgConfirmDialogClose() {
      this.msgConfirmDialog = false
    },
    back() {
      this.status = 0
      this.fileList = []
    },
    sendMsg() {
      this.showLoading({ show: true, text: this.$t('alert.processing') })
      let apiPromises = []
      this.filteredMemberList.forEach((member) => {
        if (!this.receiverSelected.includes(member.id)) {
          return
        }
        member.supervisors.forEach((supervisor) => {
          if (supervisor.connected === false) {
            return
          }
          apiPromises.push(
            this.sendSingleMsg(supervisor.userId, supervisor.name)
          )
        })
      })
      Promise.allSettled(apiPromises)
        .then((results) => {
          const isSuccess = results.every(
            (result) =>
              result.status === 'fulfilled' && result.value.status === 200
          )
          if (isSuccess) {
            this.showSnack({
              text: this.$t('alert.sending_completed'),
              isMobile: true,
              timeout: 6000,
            })
          } else {
            this.showSnack({
              text: this.$t('alert.send_failed'),
              isMobile: true,
              color: 'error',
              timeout: 6000,
            })
          }
          this.showLoading({ show: false })
          this.status = 0
          this.msgConfirmDialog = false
          this.memberDialog = false
        })
        .catch((_error) => {
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.send_failed'),
            isMobile: true,
            color: 'error',
            timeout: 6000,
          })
        })
      this.fileList = []
    },
    sendSingleMsg(userId, parentName) {
      return this.$axios.post('/line/message', {
        token: this.user.token,
        userId,
        receiver: parentName,
        message: this.messageConfirm,
        method: this.$constants.messageMethod.bulk,
        company_id: this.user.companyId,
        attachFile: this.fileList.map((file) => ({ url: file.url, key: `${this.user.companyId}/${file.albumId}/${file.id}` })),
      })
    },
    getMembers() {
      return this.$axios.post('/member/connected/all', {
        token: this.user.token,
        company_id: this.user.companyId,
      })
    },
    getTags() {
      return this.$axios.post('/tag/list', {
        token: this.user.token,
        company_id: this.user.companyId,
      })
    },
    removeFile(index) {
      this.$refs.messageBulk.reduceFileCount()
      this.fileList.splice(index, 1)
      this.showLoading({ show: false })
    },
    addFile(file) {
      this.fileList.unshift(file)
    },
  },
}
</script>
