import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"mt-5"},[_c(VCol,[_c('p',{staticClass:"text-start"},[_vm._v(" "+_vm._s(_vm.$t('member_info.term_of_use_instruction'))+" ")])])],1),_c(VRow,{staticClass:"align-center"},[_c(VCol,{staticClass:"custom-col-term"},[_c(VCheckbox,{staticClass:"pl-2",attrs:{"label":_vm.$t('member_info.term_of_service_agree')},model:{value:(_vm.checkbox1),callback:function ($$v) {_vm.checkbox1=$$v},expression:"checkbox1"}}),_c(VBtn,{attrs:{"icon":"","width":"24","height":"24"},on:{"click":function($event){$event.preventDefault();return _vm.openTermsWindow.apply(null, arguments)}}},[_c('img',{attrs:{"src":_vm.redirect}})])],1)],1),_c(VRow,{staticClass:"align-center"},[_c(VCol,{staticClass:"custom-col-term"},[_c(VCheckbox,{staticClass:"pl-2",attrs:{"label":_vm.$t('member_info.privacy_policy_agree')},model:{value:(_vm.checkbox2),callback:function ($$v) {_vm.checkbox2=$$v},expression:"checkbox2"}}),_c(VBtn,{attrs:{"icon":"","width":"24","height":"24"},on:{"click":function($event){$event.preventDefault();return _vm.openPolicyWindow.apply(null, arguments)}}},[_c('img',{attrs:{"src":_vm.redirect}})])],1)],1),_c(VRow,{staticClass:"d-inline-flex"},[_c(VCol,{attrs:{"cols":"6"}},[_c('ENJIBtn',{staticClass:"custom-btn-cancel",attrs:{"color":"#333333","outlined":"","height":"44","width":"120"},on:{"click":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")])],1),_c(VCol,{attrs:{"cols":"6"}},[_c('ENJIBtn',{staticClass:"custom-btn-agree",attrs:{"color":"#6170E8","disabled":_vm.isDisabled,"height":"44","width":"120"},on:{"click":_vm.form}},[_vm._v(" "+_vm._s(_vm.$t('member_info.agree'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }