<template>
  <div class="d-flex justify-center align-center">
    <div style="width: 100%; max-width: 100%;">
      <Scanner ref="FaceCamera" :leave="leave" @interval="onInterval" />
    </div>
  </div>
</template>

<script>
import Scanner from '@/components/checkin/ScannerM.vue'

export default {
  beforeRouteLeave(_to, _from, next) {
    this.leave = true
    // カメラ停止
    if (this.$refs.FaceCamera) {
      if (this.intervalId) clearInterval(this.intervalId)
      const videoTracks = this.$refs.FaceCamera.video.srcObject
      if (videoTracks) {
        videoTracks.getVideoTracks().forEach((track) => {
          track.stop()
        })
      }
    }
    next()
  },
  watch: {
    '$route.params.type': function () {
      window.location.reload()
    },
  },
  components: {
    Scanner,
  },
  computed: {
    APP_TITLE: {
      get: function () {
        return process.env.VUE_APP_TITLE
      },
    },
  },
  data() {
    return {
      leave: false,
      intervalId: null,
    }
  },
  created() {
    this.isAuthenticated()
  },
  methods: {
    isAuthenticated() {
      this.$cognito
        .isAuthenticated()
        .then(() => {})
        .catch((_session) => {
          this.$router.replace('/manager/signin')
        })
    },
    signOut() {
      this.$cognito.logout()
      this.$router.replace('/manager/signin')
    },
    onInterval(val) {
      this.intervalId = val
    },
  },
}
</script>
