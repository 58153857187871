import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto mt-5",attrs:{"width":"650px"}},[_c(VContainer,[_c(VCardTitle,[_c('h1',{staticClass:"display-1"},[_vm._v(_vm._s(_vm.$t('maintain.under_maintain')))])]),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t('maintain.system_update'))+" ")]),_c(VCardActions,{staticClass:"justify-center mt-5",attrs:{"color":"transparent"}},[_c(VBtn,{attrs:{"color":"success","x-large":"","width":"200px"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }