<template>
  <div>
    <v-container class="pa-0" style="height: 100%">
      <Tabs :items="items" slot-scope="" />
    </v-container>
  </div>
</template>

<script>
import Tabs from '@/components/Tabs.vue'
import AdminList from '@/views/manager/company/AdminList.vue'
import CompanyList from '@/views/manager/company/CompanyList.vue'

export default {
  components: {
    Tabs,
  },
  data() {
    return {
      tab: null,
      activeTab: 1,
      items: [
        {
          index: 1,
          title: this.$t('company.admin_tab'),
          content: AdminList,
        },
        {
          index: 2,
          title: this.$t('company.company_tab'),
          content: CompanyList,
        },
      ],
    }
  },
  created() {
    this.isAuthenticated()
  },
  methods: {
    isAuthenticated() {
      this.$cognito
        .isAuthenticated()
        .then((auth) => {
          if(!this.isAdmin(auth)) {
            this.$cognito.logout();
            return this.$router.replace('/manager/signin')
          }
        })
        .catch((_session) => {
          this.$cognito.logout()
          return this.$router.replace('/manager/signin')
        })
    },
    isAdmin(authUser) {
      return this.$cognito.isAdministrator(authUser.groups);
    }
  }
}
</script>
