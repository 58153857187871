import { render, staticRenderFns } from "./ENJIIconBtn.vue?vue&type=template&id=eb01fd22&scoped=true&"
import script from "./ENJIIconBtn.vue?vue&type=script&lang=js&"
export * from "./ENJIIconBtn.vue?vue&type=script&lang=js&"
import style0 from "./ENJIIconBtn.vue?vue&type=style&index=0&id=eb01fd22&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb01fd22",
  null
  
)

export default component.exports