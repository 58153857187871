import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c(VMain,{attrs:{"align":"center"}},[_c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VRow,{attrs:{"justify":"center"}},[(_vm.status == 0)?_c(VCol,{staticClass:"pt-0 px-0"},[_c('FaceList',{attrs:{"face-list":_vm.faceList},on:{"setting":_vm.onSetting}})],1):(_vm.status == 1)?_c(VCol,{staticClass:"pa-0",staticStyle:{"height":"100vh"}},[_c('FaceCamera',{ref:"faceCamera",attrs:{"leave":_vm.leave},on:{"back":_vm.back,"onImageCaptured":_vm.openFaceRegistConfirm}}),_c(VDialog,{attrs:{"persistent":"","content-class":"mx-4"},model:{value:(_vm.isShowFaceRegisterConfirmDialog),callback:function ($$v) {_vm.isShowFaceRegisterConfirmDialog=$$v},expression:"isShowFaceRegisterConfirmDialog"}},[_c('FaceRegistConfirm',{attrs:{"imgSrc":_vm.imgSrc,"target-id":_vm.targetId,"user":_vm.user},on:{"clickClose":_vm.closeFaceRegistConfirm,"registerFace":_vm.onReturnList}})],1)],1):_vm._e()],1)],1)],1):_c(VMain,{attrs:{"align":"center"}},[_c(VRow,{staticStyle:{"margin-top":"38px"},attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c('h2',{staticStyle:{"font-size":"16px","color":"#333333","margin-bottom":"24px"}},[_vm._v(" "+_vm._s(_vm.$t('line_connect.register_new_child_label'))+" ")]),_c('ENJIBtn',{attrs:{"height":"44","color":"#6170E8"},on:{"click":function($event){return _vm.$router.push('/member/info')}}},[_vm._v(_vm._s(_vm.$t('line_connect.register_new_child')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }