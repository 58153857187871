<template>
  <div class="scanner-screen" style="height: 100%">
    <v-card height="100%">
      <v-card-text class="py-3" style="border-bottom: 2px solid #c9c9c9">
        <v-row>
          <v-col cols="3" class="py-0">
            <v-btn
              icon
              height="44"
              class="pt-3 text-left d-block"
              @click="back"
            >
              <v-icon large color="black">mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" class="py-0">
            <v-card-text class="font-weight-bold custom-text">{{
              $t('member_info.face_photo_registration')
            }}</v-card-text>
          </v-col>
          <v-col cols="3" class="pl-1">
            <v-btn
              v-if="isShowRegisterLateBtn"
              color="#6170E8"
              text
              style="font-size: 16px; white-space: normal"
              @click="registerLater"
              class="px-0 d-block"
              height="30"
            >
              {{ $t('member_info.register_later') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text
        class="text-center pt-6"
        style="padding-bottom: 16px !important"
      >
        <ENJIIconBtn
          color="#6170E8"
          height="44"
          @click="flipCamera"
          class="font-weight-bold px-6"
        >
          <img width="20" height="20" :src="cameraSwitchIcon" />
          {{ $t('attendance.switch_camera') }}
        </ENJIIconBtn>
      </v-card-text>
      <v-card-text class="d-flex justify-center py-0">
        <div
          style="position: relative; width: 100%; aspect-ratio: 358/405"
          class="overflow-hidden"
          v-show="isVideoPlaying"
        >
          <video
            class="full_video"
            ref="video"
            autoplay
            playsInline
            muted
            :style="videoStyle"
            @playing="handleVideoPlaying"
          ></video>
          <div class="camera-eye"></div>
        </div>
      </v-card-text>
      <div class="d-flex justify-center mt-4">
        <v-btn icon :color="isProcessing ? 'red' : '#6170E8'" @click="shutter">
          <v-icon size="60">mdi-circle-slice-8</v-icon>
        </v-btn>
      </div>
    </v-card>
  </div>
</template>
<style scoped>
.scanner-screen {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.camera-eye {
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 240px;
  height: 240px;
  box-shadow: 0 0 0 100pc #00000066;
  border-radius: 100%;
}
video {
  object-fit: cover;
  display: flex;
  height: 100%;
  width: 100%;
}
.v-sheet.v-card {
  border-radius: 0;
}
</style>
<script>
import cameraSwitchIcon from '@/assets/camera-switch.svg'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import ENJIIconBtn from '@/components/buttons/ENJIIconBtn.vue'

export default {
  components: {
    ENJIIconBtn,
    ENJIBtn,
  },
  props: {
    isShowRegisterLateBtn: {
      type: Boolean,
      default: false,
    },
    leave: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      cameraSwitchIcon: cameraSwitchIcon,
      MODEL_URI: '/js/face-api.js/weights',
      video: {},
      cameraMode: '',
      videoStyle: {
        transform: 'scaleX(1)',
      },
      isVideoPlaying: false,
      imageSrc: '',
      isProcessing: false,
    }
  },
  mounted() {
    this.cameraMode = this.$constants.cameraMode.back
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri(this.MODEL_URI),
    ]).then(() => {
      // ビデオのリアルタイムキャプチャを canvas に送る
      this.video = this.$refs.video
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({
            video: {
              facingMode: this.cameraMode,
            },
          })
          .then((stream) => {
            if (this.leave) {
              stream.getVideoTracks().forEach((track) => {
                track.stop()
              })
            } else {
              this.video.srcObject = stream
              this.video.play()
            }
          })
          .catch((_error) => {})
      }
    })
  },
  methods: {
    registerLater() {
      this.$emit('registerLater')
    },
    back() {
      this.$emit('back')
    },
    flipCamera() {
      // フロント・バックカメラ切り替え
      if (this.cameraMode === this.$constants.cameraMode.front) {
        this.cameraMode = this.$constants.cameraMode.back
        this.videoStyle.transform = 'scaleX(1)'
      } else {
        this.cameraMode = this.$constants.cameraMode.front
        this.videoStyle.transform = 'scaleX(-1)'
      }
      // カメラ停止
      this.pauseCamera()
      // カメラ再設定
      this.resumeCamera()
    },
    pauseCamera() {
      // カメラ停止
      this.video.srcObject.getVideoTracks().forEach((track) => {
        track.stop()
      })
    },
    resumeCamera() {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: this.cameraMode,
          },
        })
        .then((stream) => {
          this.video.srcObject = stream
        })
    },
    shutter() {
      // 撮影開始
      this.showLoading({ show: true, text: this.$t('alert.processing') })
      this.isProcessing = true
      this.shutterTimeOutId = setTimeout(() => {
        this.showLoading({ show: false })
        this.isProcessing = false
      }, 10000)

      setTimeout(async () => {
        // ウェブカメラの映像から顔データを取得
        const detections = await faceapi.detectAllFaces(
          this.video,
          new faceapi.TinyFaceDetectorOptions()
        )
        clearTimeout(this.shutterTimeOutId)

        if (detections.length <= 0) {
          this.showLoading({ show: false })
          this.isProcessing = false
          return
        }

        const { score } = detections[0]
        if (score >= 0.5) {
          const faceImages = await faceapi.extractFaces(this.video, detections)
          if (faceImages.length === 0) {
            this.showLoading({ show: false })
            this.isProcessing = false
          } else {
            faceImages.forEach((cnv) => {
              this.video.pause()
              this.imageSrc = cnv.toDataURL('image/jpeg')
              this.$emit('onImageCaptured', this.imageSrc)
            })
            this.showLoading({ show: false })
            this.isProcessing = false
          }
        } else {
          this.showLoading({ show: false })
          this.isProcessing = false
        }
      }, 100)
    },
    handleVideoPlaying() {
      this.isVideoPlaying = true
    },
  },
}
</script>
