import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js'
import { Config, CognitoIdentityCredentials } from 'aws-sdk'
import Vue from 'vue'

Config.region = 'ap-northeast-1'
Config.credentials = new CognitoIdentityCredentials({
  IdentityPoolId: process.env.VUE_APP_USER_POOL_ID,
})
const userPool = new CognitoUserPool({
  UserPoolId: process.env.VUE_APP_USER_POOL_ID,
  ClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
})

Vue.prototype.$cognito = {
  // ログイン
  login(username, password) {
    const userData = { Username: username, Pool: userPool }
    const cognitoUser = new CognitoUser(userData)
    const authenticationData = { Username: username, Password: password }
    const authenticationDetails = new AuthenticationDetails(authenticationData)
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          const groupName = result.idToken.payload['cognito:groups'].find(
            (item) => item === process.env.VUE_APP_GROUP_NAME || item === process.env.VUE_APP_ADMIN_GROUP_NAME
          )
          if (groupName) {
            resolve(result)
          } else {
            reject()
          }
        },
        onFailure: (err) => {
          reject(err)
        },
      })
    })
  },

  // ログアウト
  logout() {
    userPool.getCurrentUser().signOut()
  },

  // ログインしているかの判定
  isAuthenticated() {
    const cognitoUser = userPool.getCurrentUser()
    return new Promise((resolve, reject) => {
      if (cognitoUser === null) {
        reject(cognitoUser)
      }
      cognitoUser.getSession((err, session) => {
        const auth = {
          id: session.idToken.payload['sub'],
          groups: session.idToken.payload['cognito:groups'] || [],
          name: session.idToken.payload['name'],
          companyId: session.idToken.payload['custom:company_id'] || '',
          email: session.idToken.payload['email'],
          idToken: session.idToken.jwtToken,
        }
        if (err) {
          reject(err)
        } else if (!session.isValid()) {
          reject(auth)
        } else {
          resolve(auth)
        }
      })
    })
  },
  // User is adminstrator
  isAdministrator(authGroups) {
    return authGroups.some((item) => item === process.env.VUE_APP_ADMIN_GROUP_NAME)
  }
}

export default (context) => {
  context.$cognito = Vue.prototype.$cognito
}
