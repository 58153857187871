import i18n from '@/plugins/i18n'
const getters = {}

const state = {
  text: i18n.t('alert.loading'),
  show: false,
}

const mutations = {
  SHOW_LOADING(state, payload) {
    if (payload.text) state.text = payload.text
    state.show = payload.show
  },
}

const actions = {
  showLoading({ commit }, payload) {
    commit('SHOW_LOADING', payload)
  },
}

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
}
