<template>
  <v-container>
    <v-card style="height: 90vh">
      <h1 class="text-center">Dymmy Buttons</h1>
      <v-row>
        <v-col cols="3">
          <v-card class="pa-1 text-center" outlined tile>
            <h2>ENJIBtn</h2>
            <ENJIBtn>{{ $t('QRコード') }}</ENJIBtn>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card class="pa-1 text-center" outlined tile>
            <h2>ENJIIconBtn</h2>
            <ENJIIconBtn color="#6170E8" icon="mdi-qrcode">
              {{ $t('メッセージ') }}
            </ENJIIconBtn>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import ENJIBtn from '@/components/buttons/ENJIBtn'
import ENJIIconBtn from '@/components/buttons/ENJIIconBtn'
export default {
  components: {
    ENJIBtn,
    ENJIIconBtn,
  },
}
</script>
