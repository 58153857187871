<template>
  <div class="scanner-screen" style="height: 100%">
    <v-card height="100%">
      <v-card-text class="instruction font-weight-bold text-center">{{
        $t('member_check.instruction')
      }}</v-card-text>
      <v-card-text
        class="text-center pt-6"
        style="padding-bottom: 16px !important"
      >
        <ENJIIconBtn
          color="#6170E8"
          height="44"
          @click="flipCamera"
          class="font-weight-bold"
        >
          <img width="20" height="20" :src="cameraSwitchIcon" />
          {{ $t('attendance.switch_camera') }}
        </ENJIIconBtn>
      </v-card-text>
      <v-card-text class="d-flex justify-center py-0">
        <div
          style="position: relative; width: 100%; aspect-ratio: 358/405"
          class="overflow-hidden"
        >
          <video
            class="full_video"
            ref="video"
            autoplay
            playsInline
            muted
            :style="videoStyle"
          ></video>
          <canvas ref="canvas" v-show="false"></canvas>
          <div class="camera-eye"></div>
        </div>
      </v-card-text>
      <div class="d-flex justify-center mt-4">
        <ENJIBtn
          height="44"
          @click="toggle"
          :color="isOn ? 'red' : '#6170E8'"
          style="padding: 10px 44px 10px 44px"
          :style="{ 'pointer-events': loading ? 'none' : 'auto' }"
        >
          {{ isOn ? $t('attendance.stop_verifying') : $t('attendance.start') }}
        </ENJIBtn>
      </div>
    </v-card>
  </div>
</template>
<style scoped>
.scanner-screen {
  background: #ffffff 0% 0% no-repeat padding-box;
}
.instruction {
  font-size: 16px;
  letter-spacing: 0px;
  color: #333333 !important;
  border-bottom: 1px solid #c9c9c9;
}
.camera-eye {
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 270px;
  height: 320px;
  box-shadow: 0 0 0 100pc #00000066;
  border-radius: 50%;
}
video {
  object-fit: cover;
  display: flex;
  height: 100%;
  width: 100%;
}
.v-sheet.v-card {
  border-radius: 0;
}
</style>
<script>
import jsQR from 'jsqr'
import cameraSwitchIcon from '@/assets/camera-switch.svg'
import ENJIBtn from '@/components/buttons/ENJIBtn'
import ENJIIconBtn from '@/components/buttons/ENJIIconBtn'

export default {
  components: {
    ENJIIconBtn,
    ENJIBtn,
  },
  data() {
    return {
      intervalId: null,
      cameraSwitchIcon: cameraSwitchIcon,
      MODEL_URI: '/js/face-api.js/weights',
      video: {},
      canvas: {},
      hitFace: 0,
      qrCode: '',
      cameraWait: true,
      dialog: false,
      regist: false,
      loading: false,
      typeDialog: false,
      selType: '0',
      cameraMode: '',
      pauseFlg: false,
      checkMsg: '',
      videoStyle: {
        transform: 'scaleX(-1)',
      },
      isOn: false,
    }
  },
  props: {
    user: Object,
    leave: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    this.checkMsg = this.$t('attendance.checked_in')
    if (this.$route.params.type === this.$constants.checkInMode.out) {
      this.checkMsg = this.$t('attendance.checked_out')
    }

    this.cameraMode = this.$constants.cameraMode.front
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri(this.MODEL_URI),
    ]).then(() => {
      // ビデオのリアルタイムキャプチャを canvas に送る
      this.video = this.$refs.video
      this.canvas = this.$refs.canvas

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({
            video: {
              facingMode: this.cameraMode,
            },
          })
          .then((stream) => {
            if (this.leave) {
              stream.getVideoTracks().forEach((track) => {
                track.stop()
              })
            } else {
              this.cameraWait = false
              this.video.srcObject = stream
              this.video.play()
            }
            this.showLoading({ show: false })
          })
          .catch((_error) => {
            this.cameraWait = true
            this.showLoading({ show: false })
          })
      } else {
        this.showLoading({ show: false })
      }
    })
  },
  methods: {
    flipCamera() {
      // フロント・バックカメラ切り替え
      if (this.cameraMode === this.$constants.cameraMode.front) {
        this.cameraMode = this.$constants.cameraMode.back
        this.videoStyle.transform = 'scaleX(1)'
      } else {
        this.cameraMode = this.$constants.cameraMode.front
        this.videoStyle.transform = 'scaleX(-1)'
      }
      // カメラ停止
      this.pauseCamera()
      // カメラ再設定
      this.resumeCamera()
    },
    onClose() {
      this.dialog = false
      this.video.play()
    },
    pauseCamera() {
      // カメラ停止
      this.pauseFlg = true
      this.video.srcObject.getVideoTracks().forEach((track) => {
        track.stop()
      })
    },
    resumeCamera() {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: this.cameraMode,
          },
        })
        .then((stream) => {
          this.video.srcObject = stream
          this.pauseFlg = false
        })
    },
    onRegist() {
      // カメラ停止
      this.video.srcObject.getVideoTracks().forEach((track) => {
        track.stop()
      })
      this.dialog = false
      this.regist = true
    },
    selQR() {
      this.selType = this.$constants.checkInType.qr
      this.typeDialog = false
    },
    selFace() {
      this.selType = this.$constants.checkInType.face
      this.typeDialog = false
    },
    toggle() {
      if (!this.isOn) {
        this.checkImage()
      } else {
        clearInterval(this.intervalId)
      }
      this.isOn = !this.isOn
    },
    async checkImage() {
      const video = this.video
      const ctx = this.canvas.getContext('2d', { willReadFrequently: true })

      this.canvas.width = 320
      this.canvas.height = 240
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
      const me = this

      try {
        this.intervalId = setInterval(async () => {
          if (this.pauseFlg || this.loading) {
            return
          }
          const detections = await faceapi.detectAllFaces(
            video,
            new faceapi.TinyFaceDetectorOptions()
          )
          if (detections.length > 0) {
            const { score } = detections[0]
            if (score >= 0.5) {
              const faceImages = await faceapi.extractFaces(video, detections)
              me.hitFace = faceImages.length
              this.checkinFace(faceImages)
            }
          } else {
            me.hitFace = 0
          }
          ctx.drawImage(video, 0, 0, 320, 240)
          const imageData = ctx.getImageData(
            0,
            0,
            me.canvas.width,
            me.canvas.height
          )
          const code = jsQR(imageData.data, imageData.width, imageData.height)
          if (code) {
            me.qrCode = code.data
            me.checkinQR(code.data)
          }
          if (!code && me.hitFace === 0) {
            this.loading = false
          }
        }, 1000)
        this.$emit('interval', this.intervalId)
      } catch (e) {
        console.error('Face detection failed:', e)
      }
    },
    checkinQR(val) {
      this.$axios
        .post(`/check/${this.$route.params.type}/qr`, {
          qr: val,
          company_id: this.user.companyId,
        })
        .then((response) => {
          this.loading = false
          if (response.data?.message) {
            this.showSnack({
              text: this.checkMsg,
              timeout: 2000,
              isMobile: true,
            })
          }
        })
        .catch(() => {
          this.loading = false
          this.showSnack({
            color: 'error',
            text: this.$t('alert.error_processing_failed'),
            timeout: 2000,
            isMobile: true,
          })
        })
    },
    checkinFace(faceImages) {
      let apiPromises = []
      faceImages.forEach((cnv) => {
        const data = cnv.toDataURL('image/jpeg').split(',')[1]
        apiPromises.push(this.checkinFaceReq(data))
      })
      Promise.allSettled(apiPromises).then((results) => {
        const isSuccess = results.some(
          (result) =>
            result.status === 'fulfilled' && result.value.data?.message
        )
        if (isSuccess) {
          this.showSnack({
            text: this.checkMsg,
            timeout: 2000,
            isMobile: true,
          })
        } else {
          this.showSnack({
            isMobile: true,
            text: this.$t('alert.error_processing_failed'),
            color: 'error',
            timeout: 2000,
          })
        }
        this.loading = false
      })
    },
    checkinFaceReq(val) {
      return this.$axios.post(`/check/${this.$route.params.type}/face`, {
        inputImage: val,
        company_id: this.user.companyId,
      })
    },
  },
}
</script>
