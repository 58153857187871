<template>
  <v-card height="100vh" class="d-flex flex-column">
    <v-card-title class="text-album pt-8 px-10 custom-title-head">{{
        $t('album.new_album')
      }}
    </v-card-title>
    <v-form ref="newAlbumData" @submit.prevent="createAlbum">
      <v-card-text class="px-10 bt-0">
        <label class="custom-label">
          <span>*</span>{{ $t('album.album_name') }}
        </label>
        <v-text-field
          v-model="newAlbumData.name"
          outlined
          dense
          height="44px"
          background-color="#EEEEEE"
          :rules="[required, textRules.regex, lengthRules, checkDuplicateAlbum]"
        >
        </v-text-field>

        <v-card-actions class="text-center d-block mt-3">
          <ENJIBtn
            @click="close"
            color="#333333"
            outlined
            class="px-9 mr-3 btn-font-size"
          >
            {{ $t('button.close') }}
          </ENJIBtn>
          <ENJIBtn
            @click="createAlbum"
            color="#6170E8"
            class="px-9 ml-3 btn-font-size"
          >
            {{ $t('button.save') }}
          </ENJIBtn>
        </v-card-actions>
      </v-card-text>
    </v-form>
  </v-card>
</template>
<script>
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import dayjs from 'dayjs'

export default {
  components: {
    ENJIBtn,
  },
  props: {
    albums: Array,
    companyId: String,
    newAlbumData: Object
  },
  data() {
    return {
      required: (value) => !!value || this.$t('alert.required_text'),
      textRules: {
        regex: (v) => /\S/.test(v) || this.$t('alert.required_text'),
      },
      lengthRules: (value) =>
        value.length <= 30 || this.$t('alert.max_length', { number: 30 }),
    }
  },
  mounted() {
  },
  methods: {
    createAlbum() {
      if (this.$refs.newAlbumData.validate()) {
        this.showLoading({ show: true, text: this.$t('alert.processing') })
        this.$axios
          .post('/album/update', {
            name: this.newAlbumData.name.trim(),
            company_id: this.companyId,
            timestamp: dayjs().valueOf(),
          })
          .then((response) => {
            this.showLoading({ show: false })
            this.$emit('createdAlbum', response.data)
          })
          .catch((error) => {
            this.showLoading({ show: false })
            if (error.response && error.response.data?.message) {
              this.showSnack({
                text: this.$t('api_error.' + error.response.data.errorCode),
                isMobile: true,
                timeout: 6000,
              })
            } else {
              this.showSnack({
                text: this.$t('alert.regist_failed'),
                isMobile: true,
                timeout: 6000,
              })
            }
          })
      } else {
        this.$vuetify.goTo(0)
      }
    },
    checkDuplicateAlbum(value) {
      const albumExists = this.albums.some((item) => item.name === value.trim())
      return !albumExists || this.$t('album.album_already_exists')
    },
    close() {
      this.$emit('closeAlbumForm')
    },
  },
}
</script>

<style>
.custom-label {
  display: flex;
  color: #9e9e9e;
}
.custom-label > span {
  color: red;
}
.text-album {
  white-space: pre-wrap;
  font-size: 16px;
  text-align: left;
  padding-left: 0;
}
.btn-font-size {
  font-size: 16px !important;
  height: 44px !important;
}
</style>
