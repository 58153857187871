<template>
  <div class="member-list">
    <v-row
      v-if="faceList.length > 0"
      justify="center"
      class="mx-0 navigation-fixed"
    >
      <v-col class="px-0">
        <v-card
          flat
          class="rounded-0"
          style="height: 68px; border-bottom: 2px solid #d0d0d0"
        >
          <v-card-title class="d-flex align-center justify-center">
            <h2
              class="d-block"
              style="font-size: 16px; font-weight: 700; color: #333333"
            >
              {{ $t('member_face.reg_face_list') }}
            </h2>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="faceList.length > 0" style="margin-top: 78px">
      <v-row
        v-for="member in faceList"
        :key="member.id"
        align="center"
        class="member-item"
      >
        <v-col cols="8" class="d-flex justify-start align-center">
          <v-avatar size="48px">
            <img
              alt="Avatar"
              :src="
                member.image
                  ? `data:image/jpeg;base64,${member.image}`
                  : accountIcon
              "
            />
          </v-avatar>
          <span
            style="
              font-size: 16px;
              font-weight: 700;
              color: #333333;
              margin-left: 12px;
            "
          >
            {{ member.firstname }} {{ member.lastname }}
          </span>
        </v-col>

        <v-col
          cols="4"
          class="d-flex justify-end align-center"
          style="padding-right: 12px"
        >
          <img
            alt="Face register"
            :src="member.image ? registedIcon : registerIcon"
            @click="selItem(member)"
            width="44"
            height="44"
          />
        </v-col>
      </v-row>
    </div>
    <v-row v-else style="margin-top: 40px" justify="center">
      <v-col cols="12">
        <h2
          style="font-size: 16px; font-weight: 700, color: #333333; margin-bottom: 24px"
        >
          {{ $t('line_connect.register_new_child_label') }}
        </h2>
        <ENJIBtn
          height="44"
          color="#6170E8"
          @click="$router.push('/member/info')"
          >{{ $t('line_connect.register_new_child') }}</ENJIBtn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import accountIcon from '@/assets/account-circle-outline.svg'
import registerIcon from '@/assets/face_regitster.svg'
import registedIcon from '@/assets/face_registed.svg'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'

export default {
  props: {
    faceList: {
      type: Array,
      required: true,
    },
  },
  components: {
    ENJIBtn,
  },
  data() {
    return {
      accountIcon,
      registerIcon,
      registedIcon,
      headers: [
        {
          text: this.$t('member_info.child_name'),
          value: 'name',
          align: 'left',
        },
        {
          text: this.$t('member_face.face_setting'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    selItem(item) {
      this.$emit('setting', item)
    },
    done() {
      liff.closeWindow()
    },
  },
}
</script>
<style scoped>
.member-list .member-item {
  background: #ffffff;
  margin: 16px;
  border-radius: 6px;
  box-shadow: 0px 0px 6px #00000029;
}
.disable-events {
  pointer-events: none;
  opacity: 0.3;
}
.navigation-fixed {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}
</style>
