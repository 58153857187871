<template>
  <div style="height: 100vh">
    <v-container
      fluid
      class="pa-0"
      style="height: 100%; background-color: #ecedfa"
    >
      <v-card
        style="height: 100%; background-color: #ecedfa"
        class="msg-history-card d-flex flex-column"
      >
        <v-card-title
          class="border-bottom custom-font-title justify-center"
          style="background-color: white"
        >
          {{ $t('navbar.menu_send_history') }}
        </v-card-title>
        <hr size="2" style="border-top: 1px solid #d0d0d0" />
        <v-card-title style="background-color: white" class="pl-4">
          <v-row justify="space-between">
            <v-col>
              <InputSearch height="44" v-model="search" />
            </v-col>
          </v-row>
        </v-card-title>
        <hr size="2" style="border-top: 1px solid #d0d0d0" />
        <v-data-table
          style="background-color: #ecedfa; flex-grow: 1"
          :page.sync="page"
          :items="messageList"
          :search="search"
          :custom-filter="customFilter"
          fixed-header
          :headers="headers"
          :items-per-page="itemsPerPage"
          hide-default-footer
          hide-default-header
          no-data-text=""
          class="overflow-y-auto"
        >
          <template v-slot:item="{ item }">
            <v-list-item :key="item.id" class="history-row my-2 mx-4">
              <v-list-item-icon>
                <v-badge color="green" :icon="icons[item.method]" overlap>
                  <ENJIIconBtn
                    color="#6170E8"
                    icon="mdi-email-outline"
                    @click="openDialogMsg(item)"
                  >
                  </ENJIIconBtn>
                </v-badge>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold shortened-name">
                  {{ item.receiver }}
                </v-list-item-title>

                <v-list-item-subtitle class="shortened-name">
                  {{ $t('message.sender') }}:
                  <span>{{ item.sender }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-list-item-title style="font-size: 13px;">
                {{ formatDate(item.timestamp) }}
                </v-list-item-title>

                <v-list-item-subtitle style="font-size: 12px;">
                  {{ formatHour(item.timestamp) }}
                </v-list-item-subtitle>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-data-table>
        <div class="d-flex justify-center mb-4">
          <Pagination v-model="page" :length="pageCount" :total-visible="5" />
        </div>
      </v-card>
    </v-container>

    <!-- dialog messages -->
    <v-dialog
      persistent
      v-model="showDialogMsg"
      width="794px"
      content-class="mx-4"
      scrollable
    >
      <v-card class="mx-auto">
        <v-card-title class="pt-10 px-16 justify-center custom-title-head"
          >{{ $t('message.message_content') }}
        </v-card-title>
        <v-card-text class="msg-content mt-3 review-msg text-center">
          <p>{{ messagesContent }}</p>

          <v-row class="mt-3 justify-center" v-if="messageAttachments && messageAttachments.length > 0">
            <v-col cols="6" md="3" v-for="(attachment, index) in messageAttachments" :key="index" >
              <v-img :src="attachment.url" width="100%" class="image-preview" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center px-9 my-3">
          <ENJIBtn
            @click="closeDialogMsg"
            color="#333333"
            outlined
            class="px-9 mr-3 btn-font-size-line-card"
          >
            {{ $t('button.close') }}
          </ENJIBtn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import ENJIIconBtn from '@/components/buttons/ENJIIconBtn.vue'
import InputSearch from '@/components/inputs/InputSearch.vue'
import Pagination from '@/components/Pagination.vue'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
export default {
  components: {
    ENJIIconBtn,
    InputSearch,
    Pagination,
    ENJIBtn,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      page: 1,
      search: '',
      sortDesc: true,
      itemsPerPage: 10,
      showDialogMsg: false,
      messagesContent: '',
      messageAttachments: [],
      headers: [
        {
          text: 'time',
          value: 'timestamp',
        },
        { text: this.$t('message.sender'), sortable: false, value: 'sender' },
        {
          text: this.$t('message.receiver'),
          sortable: false,
          value: 'receiver',
        },
        { text: '', sortable: false, value: 'actions', align: 'right' },
      ],
      messageList: [],
      icons: {
        [this.$constants.messageMethod.individual]: 'mdi-account-outline',
        [this.$constants.messageMethod.bulk]: 'mdi-account-group-outline',
      },
    }
  },
  mounted() {
    this.showLoading({ show: true })
    this.initialize()
  },
  computed: {
    pageCount() {
      return Math.ceil(this.messageList.length / this.itemsPerPage)
    },
  },
  methods: {
    initialize() {
      this.$axios
        .post('/message/list', {
          token: this.user.token,
          company_id: this.user.companyId,
        })
        .then((response) => {
          this.showLoading({ show: false })
          this.messageList = response.data.sort(
            (a, b) => b.timestamp - a.timestamp
          )
        })
        .catch((error) => {
          console.log(error)
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.error_get_data_failed'),
            color: 'error',
            timeout: 6000,
          })
        })
    },
    customFilter(value, search, item) {
      return (
        !!search &&
        (item.sender.toLowerCase().includes(search.toLowerCase()) ||
          item.receiver.toLowerCase().includes(search.toLowerCase()) ||
          item.message.toLowerCase().includes(search.toLowerCase()))
      )
    },
    formatMethod(value) {
      switch (value) {
        case this.$constants.messageMethod.individual:
          return this.$t('message.send_method_individual')
        case this.$constants.messageMethod.bulk:
          return this.$t('message.send_method_bulk')
      }
    },
    formatDate(date) {
      return dayjs(date).format(this.$t('datetime.YYYY-MM-DD'))
    },
    formatHour(date) {
      return dayjs(date).format(this.$t('datetime.HH:mm'))
    },
    openDialogMsg(content) {
      this.showDialogMsg = true
      this.messagesContent = content.message
      this.messageAttachments = content.attachment
    },
    closeDialogMsg() {
      this.showDialogMsg = false
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  line-height: 1.5;
}
. .msg-content {
  font-size: 16px !important;
  color: #161a1a;
  padding: 24px 80px !important;
}
.review-msg {
  white-space: pre-wrap;
}

.history-row {
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 0px 6px #00000029;
}
.msg-history-card.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.msg-history-card ::v-deep(.div-pagination) {
  margin-left: 0 !important;
}
::v-deep(.div-pagination
    .v-pagination
    > li
    > button:hover
    .theme--light.v-icon) {
  color: white !important;
}
.image-preview {
  aspect-ratio: 78/70;
}
.shortened-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 130px; /* Độ rộng tối đa của phần tử hiển thị */
}
</style>
