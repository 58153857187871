<template>
  <v-card height="100vh">
    <v-card-title class="pt-8 px-10 custom-title-head"
      >{{ $t('line_connect.line_sending') }}
    </v-card-title>
    <v-card-text class="px-10 pb-0 text-line-card">{{
      $t('line_connect.enter_your_message')
    }}</v-card-text>
    <v-card-text class="px-10 py-4 text-line-card">{{
      $t('line_connect.mr', {
        name: formatSupervisorNames(lineData.supervisors),
      })
    }}</v-card-text>
    <v-card-text class="px-10 bt-0">
      <v-form ref="send_data">
        <label
          ><span class="span-color-line-card">*</span
          >{{ $t('kindergarten_list.line_message') }}</label
        >
        <v-textarea
          v-model="message"
          outlined
          :rules="[required, messageLengthRule]"
          :rows="13"
          clearable
          background-color="#EEEEEE"
          class="custom-textarea"
        />
        <v-card-actions class="text-center d-block">
          <ENJIBtn
            @click="close"
            color="#333333"
            outlined
            class="px-9 mr-3 btn-font-size-line-card"
          >
            {{ $t('button.close') }}
          </ENJIBtn>
          <ENJIBtn
            @click="submit"
            color="#6170E8"
            class="px-9 ml-3 btn-font-size-line-card"
          >
            {{ $t('member_contact.send') }}
          </ENJIBtn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import ENJIBtn from './buttons/ENJIBtn.vue'
export default {
  components: { ENJIBtn },
  props: {
    lineData: Object,
  },
  data() {
    return {
      message: '',
      required: (value) => !!value || this.$t('alert.required_text'),
      messageLengthRule: (value) =>
        value.length <= 500 || this.$t('alert.max_length', { number: 500 }),
    }
  },
  methods: {
    formatSupervisorNames(supervisors) {
      const supervisorNames = supervisors.reduce((names, supervisor) => {
        if (supervisor.connected === true) {
          names.push(supervisor.name)
        }
        return names
      }, [])
      const combinedNames = supervisorNames.join(
        this.$t('kindergarten_list.comma')
      )
      return combinedNames
    },
    close() {
      this.message = ''
      this.$emit('clickClose')
    },
    submit() {
      if (this.$refs.send_data.validate()) {
        this.$emit('clickSend', this.message.trim())
      }
    },
  },
}
</script>
<style>
.text-line-card {
  font-size: 16px;
  color: #161a1a !important;
}

.span-color-line-card {
  color: red;
}
.label-line-card {
  font-size: 14px;
  color: #9e9e9e;
}
.btn-font-size-line-card {
  font-size: 16px !important;
  height: 44px !important;
}
.custom-textarea .v-input__control fieldset {
  border: none !important;
}
</style>
