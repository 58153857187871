<template>
  <div class="member-list">
    <v-row justify="center">
      <v-col>
        <v-card flat class="rounded-0" style="height: 68px">
          <v-card-title class="d-block text-center">
            <h2 class="d-block" style="font-size: 16px; color: #333333">
              {{ $t('line_connect.child_list') }}
            </h2>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="memberList.length > 0">
      <v-row
        v-for="(member, memberIndex) in memberList"
        :key="member.id"
        align="center"
        class="member-item"
      >
        <v-col cols="8" class="d-flex justify-start align-center">
          <v-avatar size="48px">
            <img
              alt="Avatar"
              :src="
                member.image
                  ? `data:image/jpeg;base64,${member.image}`
                  : accountIcon
              "
            />
          </v-avatar>
          <span
            style="
              font-size: 16px;
              font-weight: 700;
              color: #333333;
              margin-left: 12px;
            "
          >
            {{ member.firstname }} {{ member.lastname }}
          </span>
        </v-col>

        <v-col cols="4" class="d-flex justify-end" style="padding-right: 20px">
          <SwitchBtn
            :value="
              member.supervisors[indexOfSupervisor(member.supervisors)]
                .connected
            "
            v-if="indexOfSupervisor(member.supervisors) !== -1"
            @change="
              handleSwitchClick(
                memberIndex,
                indexOfSupervisor(member.supervisors)
              )
            "
          />
        </v-col>
      </v-row>
    </div>
    <v-row v-else style="margin-top: 28px" justify="center">
      <v-col cols="12">
        <h2 style="font-size: 16px; color: #333333; margin-bottom: 16px">
          {{ $t('line_connect.register_new_child_label') }}
        </h2>
        <ENJIBtn
          height="44"
          color="#6170E8"
          @click="$router.push('/member/info')"
          >{{ $t('line_connect.register_new_child') }}</ENJIBtn
        >
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.member-list .member-item {
  background: #ffffff;
  margin: 16px;
  border-radius: 6px;
  box-shadow: 0px 0px 6px #00000029;
}
</style>

<script>
import accountIcon from '@/assets/account-circle-outline.svg'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import SwitchBtn from '@/components/connect/SwitchBtn.vue'
export default {
  props: {
    memberList: {
      type: Array,
      required: true,
    },
    user: Object,
  },
  components: {
    ENJIBtn,
    SwitchBtn,
  },
  data() {
    return {
      accountIcon: accountIcon,
      headers: [
        {
          text: this.$t('line_connect.child_name'),
          value: 'name',
          align: 'left',
        },
        {
          text: this.$t('line_connect.line_connect'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  async created() {
    const profile = await liff.getProfile()
    this.user.userId = profile.userId
  },
  methods: {
    indexOfSupervisor(supervisors) {
      return supervisors.findIndex(
        (supervisor) => supervisor.userId === this.user.userId
      )
    },
    handleSwitchClick(memberIndex, supervisorIndex) {
      this.showLoading({ show: true, text: this.$t('alert.processing') })
      this.memberList[memberIndex].supervisors[supervisorIndex].connected =
        !this.memberList[memberIndex].supervisors[supervisorIndex].connected

      this.$axios
        .post('/member/update', {
          token: this.user.token,
          ...this.memberList[memberIndex],
        })
        .then(() => {
          this.$emit('onRefreshPage')
        })
        .catch((error) => {
          console.log(error)
          this.showLoading({ show: false })
        })
    },
  },
}
</script>
