<template>
  <v-card height="100vh" class="d-flex flex-column">
    <v-card-title class="pt-8 px-10 custom-title-head"
      >{{ $t('master.staff_information') }}
    </v-card-title>
    <v-card-text class="px-10 bt-0">
      <v-form ref="staffData">
        <label
          ><span class="span-color-line-card">*</span
          >{{ $t('master.full_name') }}</label
        >
        <v-text-field
          v-model="staffData.name"
          :rules="[required, textRules.regex, nameLengthRule]"
          outlined
          dense
          background-color="#EEEEEE"
          style="font-size: 16px"
          height="44px"
          class="custom-input"
        ></v-text-field>
        <div>
          <v-checkbox
            @change="clickCheckboxPw"
            dense
            v-if="staffData.id"
            v-model="isAllowChangePassword"
            class="d-inline-block checkbox"
          />
          <label class="d-inline-block"
            ><span class="span-color-line-card">*</span
            >{{
              staffData.id ? $t('login.change_password') : $t('login.password')
            }}</label
          >
        </div>
        <v-text-field
          ref="inputPw"
          v-model="password"
          :rules="[
            staffData.id !== '' && !isAllowChangePassword ? true : required,
            staffData.id !== '' && !isAllowChangePassword ? true : passwordRule,
            staffData.id !== '' && !isAllowChangePassword ? true : pwLengthRule,
          ]"
          type="password"
          outlined
          dense
          background-color="#EEEEEE"
          style="font-size: 16px"
          height="44px"
          class="custom-input"
          :disabled="staffData.id !== '' && !isAllowChangePassword"
        ></v-text-field>
        <div>
          <v-checkbox
            dense
            v-if="staffData.id"
            v-model="isAllowChangeEmail"
            class="d-inline-block checkbox"
          />
          <label class="d-inline-block"
            ><span class="span-color-line-card">*</span
            >{{
              staffData.id ? $t('login.change_email') : $t('login.mail')
            }}</label
          >
        </div>
        <v-text-field
          v-model="staffData.email"
          outlined
          :rules="[
            staffData.id !== '' && !isAllowChangeEmail ? true : required,
            staffData.id !== '' && !isAllowChangeEmail
              ? true
              : emailRules.regex,
            staffData.id !== '' && !isAllowChangeEmail ? true : emailLengthRule,
            staffData.id !== '' && !isAllowChangeEmail ? true : checkDuplicate,
          ]"
          dense
          background-color="#EEEEEE"
          style="font-size: 16px"
          height="44px"
          class="custom-input"
          :disabled="staffData.id !== '' && !isAllowChangeEmail"
        ></v-text-field>
        <v-card-actions class="text-center d-block">
          <ENJIBtn
            @click="close"
            color="#333333"
            outlined
            class="px-9 mr-3 btn-font-size-line-card"
          >
            {{ $t('button.close') }}
          </ENJIBtn>
          <ENJIBtn
            @click="submit"
            color="#6170E8"
            class="px-9 ml-3 btn-font-size-line-card"
          >
            {{ $t('button.save') }}
          </ENJIBtn>
        </v-card-actions>
      </v-form>
    </v-card-text>
    <v-card-text class="mt-auto" v-if="!!staffData.id">
      <v-row align="center" class="mt-4">
        <v-col class="d-flex justify-end pr-0">
          <v-checkbox
            class="text-end checkbox-delete"
            v-model="checkedDelete"
            :label="$t('checkbox.delete')"
          ></v-checkbox>
        </v-col>
        <v-col>
          <ENJIIconBtn
            icon="mdi-trash-can-outline"
            :color="deleteButtonColor"
            @click="deleteStaff"
          >
            {{ $t('member_face.delete') }}</ENJIIconBtn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import ENJIBtn from './buttons/ENJIBtn.vue'
import ENJIIconBtn from './buttons/ENJIIconBtn.vue'
export default {
  components: { ENJIBtn, ENJIIconBtn },
  props: {
    staffData: Object,
    staffList: Array,
  },
  computed: {
    deleteButtonColor() {
      return this.checkedDelete ? '#DC313B' : '#F1ADB1'
    },
  },
  data() {
    return {
      checkedDelete: false,
      isAllowChangePassword: false,
      isAllowChangeEmail: false,
      password: '',
      email: '',
      required: (value) => !!value || this.$t('alert.required_text'),
      textRules: {
        regex: (v) => /\S/.test(v) || this.$t('alert.required_text'),
      },
      passwordRule: (value) =>
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s).{8,}$/.test(value) ||
        this.$t('alert.invalid_password'),
      emailRules: {
        regex: (v) =>
          /^(?!.*\s)(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || this.$t('alert.invalid_mail'),
      },
      nameLengthRule: (value) =>
        value.length <= 10 || this.$t('alert.max_length', { number: 10 }),
      emailLengthRule: (value) =>
        value.length <= 319 || this.$t('alert.max_length', { number: 319 }),
      pwLengthRule: (value) =>
        value.length <= 255 || this.$t('alert.max_length', { number: 255 }),
    }
  },
  methods: {
    clickCheckboxPw() {
      this.$nextTick(() => {
        this.$refs.inputPw.focus()
      })
    },
    checkDuplicate(value) {
      const emailExist = this.staffList.some((item) => item.email === value)
      return !emailExist || this.$t('alert.email_exists')
    },
    close() {
      this.$emit('closeDialog')
    },
    submit() {
      if (this.$refs.staffData.validate()) {
        this.staffData.isChangePassword = this.isAllowChangePassword
        this.staffData.password = this.password
        this.staffData.isChangeEmail = this.isAllowChangeEmail
        this.staffData.name = this.staffData.name.trim()
        this.staffData.created_by = 'admin'
        this.$emit('createStaff', this.staffData)
      } else {
        this.$vuetify.goTo(0)
      }
    },
    deleteStaff() {
      this.$cognito.isAuthenticated().then(async (auth) => {
        this.$axiosM
          .post(
            '/user/delete',
            {
              id: this.staffData.id,
            },
            {
              headers: {
                Authorization: auth.idToken,
              },
            }
          )
          .then((_response) => {
            this.loading = false
            this.showLoading({ show: false })
            this.$emit('delete')
          })
          .catch((_error) => {
            this.loading = false
            this.showLoading({ show: false })
            this.showSnack({
              text: this.$t('alert.delete_failed'),
              color: 'error',
              timeout: 6000,
            })
          })
      })
    },
  },
}
</script>

<style>
.text-line-card {
  font-size: 16px;
  color: #161a1a !important;
}

.span-color-line-card {
  color: red;
}
.label-line-card {
  font-size: 14px;
  color: #9e9e9e;
}
.btn-font-size-line-card {
  font-size: 16px !important;
  height: 44px !important;
}
.custom-textarea .v-input__control fieldset {
  border: none !important;
}
.checkbox .v-input__control {
  height: 20px;
}
.checkbox ::v-deep(.v-input__slot) {
  margin: 0px !important;
}
</style>
