<template>
  <v-card
    :height="isMobile ? '' : '100vh'"
    style="position: relative"
    :class="[isMobile ? '' : 'zero-border-radius-right-side']"
  >
    <v-card-title
      class="px-10 custom-title-head"
      :class="[{ 'justify-center': isMobile }, isMobile ? 'py-4' : 'pt-8']"
      >{{
        isMobile
          ? $t('member_contact.sending_confirmation')
          : $t('navbar.menu_group_bulk_message')
      }}
    </v-card-title>
    <v-card-text class="px-10 pb-0 text-line-card">{{
      $t('line_connect.sure_want_to_send')
    }}</v-card-text>
    <v-card-text
      class="px-10 py-5 text-line-card confirm-textarea"
      :style="{ 'border-bottom': isMobile ? '2px solid #c9c9c9' : 'none' }"
      style="min-height: 144px"
      >{{ messageConfirm }}</v-card-text
    >
    <v-card-text>
      <v-item-group
        multiple
        class="d-flex flex-wrap px-6 justify-start preview-imgs"
      >
        <v-item v-for="(file, _) in fileList" :key="`${file.id}`">
          <v-img class="mt-2 img-preview" :src="file.url"></v-img>
        </v-item>
      </v-item-group>
    </v-card-text>
    <v-form>
      <v-card-actions class="text-center d-block py-4">
        <ENJIBtn
          @click="close"
          color="#333333"
          outlined
          class="mr-3 btn-font-size-line-card"
          :class="[isMobile ? 'px-7' : 'px-4']"
        >
          {{ $t('button.return_to_input') }}
        </ENJIBtn>
        <ENJIBtn
          @click="submit"
          color="#6170E8"
          class="ml-3 btn-font-size-line-card"
          :class="[
            isMobile ? 'px-9' : 'px-6',
            {
              'disable-events':
                this.messageConfirm.trim() === '' && this.fileList.length === 0,
            },
          ]"
        >
          {{ $t('member_contact.send') }}
        </ENJIBtn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import ENJIBtn from './buttons/ENJIBtn.vue'
export default {
  components: { ENJIBtn },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    messageConfirm: String,
    fileList: [],
  },
  methods: {
    close() {
      this.$emit('clickClose')
    },
    submit() {
      this.$emit('clickSubmit')
    },
  },
}
</script>
<style scope>
.confirm-textarea {
  white-space: pre-wrap;
}
.img-preview {
  flex: 0 0 auto !important;
  width: 23%;
  aspect-ratio: 78/70;
  border: 1px solid #707070;
  margin-left: 1%;
  margin-right: 1%;
}
</style>
