<template>
  <div>
    <v-card flat tile>
      <v-card-text class="py-3" style="border-bottom: 2px solid #c9c9c9">
        <v-row>
          <v-col cols="2" class="justify-center" height="100%">
            <v-btn icon height="44" class="pt-3" @click="back">
              <v-icon large color="black">mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="7" class="py-0">
            <v-card-text class="font-weight-bold custom-text">{{
              $t('member_info.child_info_fill_form')
            }}</v-card-text>
          </v-col>
          <v-col cols="3" class="py-0">
            <v-btn
              @click="onClickScan"
              plain
              class="justify-center"
              height="100%"
            >
              <v-icon x-large>mdi-qrcode-scan</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="py-6">
        <v-form ref="userData">
          <v-row justify="center" align="baseline">
            <v-col>
              <label class="custom-label"
                ><span>*</span
                >{{ $t('kindergarten_list.form_child_first_name') }}</label
              >
              <v-text-field
                v-model="user.firstname"
                outlined
                dense
                height="44px"
                background-color="#EEEEEE"
                :rules="[required, textRules.regex]"
                class="custom-input"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <label class="custom-label"
                ><span>*</span
                >{{ $t('kindergarten_list.form_child_last_name') }}</label
              >
              <v-text-field
                v-model="user.lastname"
                outlined
                dense
                height="44px"
                background-color="#EEEEEE"
                :rules="[required, textRules.regex]"
                class="custom-input"
              ></v-text-field>
            </v-col>
          </v-row>

          <label class="custom-label"
            ><span>*</span
            >{{ $t('kindergarten_list.form_child_furigana') }}</label
          >
          <v-text-field
            v-model="user.kana"
            outlined
            dense
            height="44px"
            background-color="#EEEEEE"
            :rules="[required, textRules.regex]"
            class="custom-input"
          >
          </v-text-field>

          <label class="custom-label"
            ><span>*</span
            >{{ $t('kindergarten_list.form_child_birthday') }}</label
          >
          <v-menu
            v-model="menu"
            ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="user.birthday"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                :rules="[required]"
                dense
                background-color="#EEEEEE"
                style="font-size: 16px"
                height="44px"
                class="custom-input calendar-icon"
                placeholder="yyyy/mm/dd"
                append-icon="mdi-calendar"
                @click:append="onIconClick"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="user.birthday"
              ref="picker"
              :active-picker.sync="activePicker"
              :max="new Date().toISOString().substr(0, 10)"
              :picker-date="pickerDate"
              locale="jp-ja"
              :day-format="(date) => new Date(date).getDate()"
              :min="pickerMinDate"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>

          <label class="custom-label"
            ><span>*</span
            >{{ $t('kindergarten_list.form_mobile_number-sp') }}</label
          >
          <v-text-field
            type="tel"
            v-model="user.phone"
            height="44px"
            outlined
            :rules="[required, phoneRules.regex]"
            dense
            background-color="#EEEEEE"
            class="custom-input"
          >
          </v-text-field>

          <label class="custom-label"
            ><span>*</span
            >{{ $t('kindergarten_list.form_supervisor_name') }}</label
          >
          <v-text-field
            v-model="supervisor.name"
            outlined
            dense
            height="44px"
            background-color="#EEEEEE"
            :rules="[required, textRules.regex]"
            class="custom-input"
          >
          </v-text-field>
          <v-card-actions class="justify-center py-0">
            <ENJIBtn
              @click="next"
              color="#6170E8"
              height="44"
              elevation="0"
              class="btn-reg"
              >{{ $t('button.regist_2') }}</ENJIBtn
            >
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<style scoped>
.custom-input .v-input__control fieldset {
  border: none;
}
.custom-btn-plus {
  bottom: -23px;
  right: 13px;
  border: 3px solid #fff;
  background-color: #6170e8;
}
.calendar-icon .v-icon {
  color: blue !important;
}
.btn-reg {
  padding: 0 42px !important;
}
</style>

<script>
import accountIcon from '@/assets/account-circle-outline.svg'
import ENJIBtn from '../buttons/ENJIBtn.vue'
import ENJIIconBtn from '../buttons/ENJIIconBtn.vue'
import dayjs from 'dayjs'

export default {
  components: {
    ENJIBtn,
    ENJIIconBtn,
  },
  props: {
    user: Object,
    imgSrc: String,
  },
  data() {
    return {
      supervisor: { userId: null, name: '', connected: true },
      accountIcon: accountIcon,
      avatar: this.imgSrc == '' ? accountIcon : this.imgSrc,
      activePicker: null,
      pickerDate: '2010-01-01',
      pickerMinDate: '2000-01-01',
      menu: false,
      required: (value) => !!value || this.$t('alert.required_text'),
      textRules: {
        regex: (v) => /\S/.test(v) || this.$t('alert.required_text'),
      },
      phoneRules: {
        regex: (v) => /^[0-9]*$/.test(v) || this.$t('alert.digit_only'),
      },
      zipRules: {
        regex: (v) => /^[0-9]{7}$/.test(v) || this.$t('alert.7digit_only'),
      },
      emailRules: {
        regex: (v) =>
          /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || this.$t('alert.invalid_mail'),
      },
    }
  },
  async mounted() {
    const profile = await liff.getProfile()
    this.supervisor.userId = profile.userId
    this.initPickerDate()
  },
  watch: {
    menu(val) {
      val &&
        setTimeout(
          () => ((this.activePicker = 'YEAR'), (this.pickerDate = null))
        )
    },
  },
  methods: {
    onIconClick() {
      this.$refs.menu.isActive = !this.$refs.menu.isActive
    },
    onClickScan() {
      this.$emit('onClickScan')
    },
    initPickerDate() {
      this.pickerDate = dayjs()
        .subtract(10, 'y')
        .startOf('y')
        .format(this.$constants.dateFormat)
      this.pickerMinDate = dayjs()
        .subtract(20, 'y')
        .startOf('y')
        .format(this.$constants.dateFormat)
    },
    back() {
      this.$emit('back')
    },
    next() {
      if (this.$refs.userData.validate()) {
        this.showLoading({ show: true, text: this.$t('alert.processing') })
        this.user.supervisors.push(this.supervisor)
        this.$axios
          .post('/member/update', {
            ...this.user,
            company_id: this.user.companyId,
            created_at: dayjs().valueOf(),
          })
          .then((_response) => {
            if (this.user.id === '') {
              const startOfDate = dayjs().startOf('date').valueOf()
              this.$axios
                .post('/history/update/member-count', {
                  startOfDate,
                  company_id: this.user.companyId,
                  count: 1,
                })
                .then(() => {
                  this.showLoading({ show: false })
                  this.back()
                })
                .catch((error) => {
                  this.showLoading({ show: false })
                  console.log(error)
                })
            } else {
              this.showLoading({ show: false })
              this.back()
            }
          })
          .catch((error) => {
            this.sendFlg = false
            this.showLoading({ show: false })

            if (error.response && error.response.data?.message) {
              this.showSnack({
                text: this.$t('api_error.' + error.response.data.errorCode),
                isMobile: true,
                color: 'error',
                timeout: 6000,
              })
            } else {
              this.showSnack({
                text: this.$t('alert.regist_failed'),
                isMobile: true,
                color: 'error',
                timeout: 6000,
              })
            }
          })
      } else {
        this.$vuetify.goTo(0)
      }
    },
  },
}
</script>
