<template>
  <v-layout row justify-center>
    <v-dialog v-model="show" persistent content content-class="centered-dialog">
      <v-container fill-height>
        <v-layout column justify-center align-center>
          <v-progress-circular
            indeterminate
            :size="70"
            :width="7"
          ></v-progress-circular>
          <h1 v-if="text != null">{{ text }}</h1>
        </v-layout>
      </v-container>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'loading/SHOW_LOADING') {
        this.text = state.loading.text
        this.show = state.loading.show
      }
    })
  },
  data() {
    return {
      text: null,
      show: false,
    }
  },
}
</script>

<style>
.dialog.centered-dialog,
.v-dialog.centered-dialog {
  background: #282c2dad;
  box-shadow: none;
  border-radius: 6px;
  width: auto;
  color: whitesmoke;
}
</style>
