<template>
  <div class="v-input-search">
    <v-text-field
      v-on:input="onInput"
      v-model="searchInput"
      single-line
      clearable
      hide-details
      outlined
      dense
      :label="$t('kindergarten_list.search')"
      prepend-inner-icon="mdi-magnify"
      background-color="#EEEEEE"
      class="input-search"
      style="font-size: 16px"
      v-bind="$attrs"
    />
    <div v-if="showSuggestions" class="show-suggestion">
      <v-list>
        <v-list-item v-for="(suggestion, index) in filteredSuggestions" :key="index" @click="onSuggestionClick(suggestion)">
          <v-list-item-title>{{ suggestion }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>
<style>
/* Custom Input Search */
.input-search.v-text-field fieldset,
.input-search.v-text-field .v-input__control,
.input-search.v-text-field .v-input__slot {
  border: none;
}
.v-card__title {
  padding-left: 32px;
}
.v-input-search {
  position: relative;
  width: -webkit-fill-available;
}
.show-suggestion {
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  z-index: 999;
  width: 100%;
  border: 1px solid #d0d0d0;
}
</style>
<script>
export default {
  props: {
    user: Object,
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tagList: [],
      filteredSuggestions: [],
      showSuggestions: false,
      searchInput: '',
      isAllowShowSuggestionPage: false
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'tagSearchResult/SHOW_TAG_SEARCH_RESULT') {
        this.showSuggestions = state.tagSearchResult.show
      }
    })

    this.isAllowShowSuggestionPage = ['ManagerMemberList', 'ManagerHistory', 'MemberHistory'].includes(this.$route.name)
    if (this.isAllowShowSuggestionPage) {
      if (this.isMobile) {
        this.getTagsByMember()
      } else {
        this.getTagsByManager()
      }
    }
  },
  watch: {
    searchInput(value) {
      if (!value) {
        this.showSuggestions = false;
        this.$emit('input', '')
      }
    }
  },
  methods: {
    getTagsByMember() {
      this.$axios.post('/tag/list', {
        token: this.user.token,
        company_id: this.user.companyId,
      })
      .then((response) => {
        this.tagList = response.data
      })
      .catch((error) => {
        console.log(error)       
      })
    },
    getTagsByManager() {
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          this.$axiosM
            .post(
              '/tag/list',
              { company_id: auth.companyId },
              {
                headers: {
                  Authorization: auth.idToken,
                },
              }
            )
            .then((response) => {
              this.tagList = response.data
            })
            .catch((error) => {
              console.log(error)
              this.loading = false
              this.showLoading({ show: false })
              this.showSnack({
                text: this.$t('alert.error_get_data_failed'),
                color: 'error',
                timeout: 6000,
              })
            })
        })
        .catch((error) => {
          console.error(error)
          this.loading = false
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.login_again'),
            color: 'error',
            timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })
    },
    onInput(value) {
      if (!value) {
        return false;
      }

      this.$emit('input', value)

      const tagNames = this.tagList.map(item => item.name);
      this.filteredSuggestions = tagNames.filter(suggestion =>
        suggestion.toLowerCase().includes(value.toLowerCase())
      );

      this.showSuggestions = this.isAllowShowSuggestionPage && this.filteredSuggestions.length > 0
      this.showTagSearchResult({ show: this.showSuggestions })
    },
    onSuggestionClick(suggestion) {
      this.searchInput = suggestion
      this.showSuggestions = false
      this.$emit('input', suggestion)
    }
  }
}
</script>
