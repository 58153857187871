<template>
  <v-container v-if="show" class="container-upload pa-0">
    <v-row class="ma-0 pb-6" style="height: 100%">
      <v-col cols="12" class="pb-0">
        <v-card-text class="text-album pb-0">{{
          $t('album.img_upload')
        }}</v-card-text>
        <v-card-text class="text-album">{{
          $t('album.please_select_album')
        }}</v-card-text>
        <div class="d-flex">
          <v-select
            ref="albumSelected"
            v-model="albumSelected"
            @change="handleAlbumChange"
            :items="albums"
            item-text="name"
            item-value="id"
            outlined
            dense
            style="height: 40px; width: 50px; line-height: 24px"
            :rules="[required]"
          ></v-select>
          <ENJIBtn
            color="#333333"
            outlined
            class="ml-3 px-2 py-1"
            :class="{
              'disable-events': albumSelected === '' || albumSelected == null,
            }"
            @click="checkImage"
          >
            {{ $t('album.check_img') }}
          </ENJIBtn>
        </div>
        <v-card-text class="text-album py-2 pl-0">
          <v-btn class="link-action pl-0" @click="createNewAlbum" text>
            {{ $t('album.add_new_album') }}
          </v-btn>
        </v-card-text>
        <v-card-text class="text-album pl-0">
          {{ $t('album.please_select_img') }}
        </v-card-text>
        <file-pond
          :disabled="albumSelected === ''"
          ref="pond"
          name="filepond"
          v-model="files"
          :label-idle="$t('album.tap_to_upload_img')"
          :allow-multiple="true"
          accepted-file-types="image/*,video/*"
          :server="createServerConfig()"
          @processfiles="handleUploadedFile"
        />
        <div v-if="msgErr !== ''" class="red--text">{{ msgErr }}</div>
        <div v-if="uploadedFiles.length > 0">
          <v-card-text>{{ $t('album.uploaded_file') }}</v-card-text>
          <v-item-group multiple class="d-flex flex-wrap px-6">
            <v-item
              v-for="(file, index) in uploadedFiles"
              :key="`${file.id}-${file.errorCount}`"
            >
              <v-img
                class="mt-2 img-preview"
                @error="onImgError(index)"
                :src="file.url"
                @click="showMedia(file)"
              >
                <v-btn icon class="float-right" @click.stop="deleteFile(file)">
                  <v-icon size="30">mdi-close-circle-outline </v-icon>
                </v-btn>
                <v-icon
                  v-if="file.type === 'video'"
                  size="50"
                  color="black"
                  class="icon-play-button"
                  >mdi-play-circle-outline</v-icon
                >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-linear
                      color="#6170e8"
                      indeterminate
                      rounded
                      height="6"
                      class="progress-media"
                      v-if="
                        file.isShowLoading || file.isShowLoading === undefined
                      "
                    ></v-progress-linear>
                  </v-row>
                </template>
              </v-img>
            </v-item>
          </v-item-group>
        </div>
      </v-col>
    </v-row>
    <div>
      <v-dialog v-if="dialogShowMedia" v-model="dialogShowMedia" persistent
        ><DisplayMedia
          :mediaItem="mediaItem"
          @clickCloseDisplayMedia="closeDisplayMedia"
        />
      </v-dialog>
    </div>
    <v-footer app color="#fff" class="py-0">
      <v-col class="text-center" cols="12">
        <v-card-actions class="text-center d-block py-0 mb-3">
          <ENJIBtn
            @click="closeWindow"
            color="#333333"
            outlined
            class="px-9 mr-3 btn-font-size"
          >
            {{ $t('button.close') }}
          </ENJIBtn>
        </v-card-actions>
      </v-col>
    </v-footer>
  </v-container>
  <v-container v-else class="container-upload">
    <AlbumForm
      :albums="albums"
      :companyId="user.companyId"
      @closeAlbumForm="close"
      @createdAlbum="createdAlbum"
    >
    </AlbumForm>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import AlbumForm from '@/components/album/AlbumForm.vue'
import DisplayMedia from '@/components/album/DisplayMedia.vue'

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType)

export default {
  components: {
    ENJIBtn,
    FilePond,
    AlbumForm,
    DisplayMedia,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      show: true,
      albumSelected: '',
      albums: [],
      files: [],
      uploadedFiles: [],
      required: (value) => !!value || this.$t('alert.required_text'),
      maxImgSize: 100 * 1024 * 1024, // 100MB
      maxVideoSize: 500 * 1024 * 1024, // 500MB
      msgErr: '',
      dialogShowMedia: false,
      mediaItem: {},
    }
  },
  created() {
    this.getAlbumList()
  },
  mounted() {
    this.showLoading({ show: true })
    this.$refs.albumSelected.focus()
  },
  methods: {
    onImgError(index) {
      const tmpIndex = index
      this.uploadedFiles[tmpIndex].errorCount =
        this.uploadedFiles[tmpIndex].errorCount || 0
      this.uploadedFiles[tmpIndex].errorCount++

      if (this.uploadedFiles[tmpIndex].errorCount > 4) {
        this.uploadedFiles[tmpIndex].isShowLoading = false
        return
      }

      setTimeout(() => {
        const src = this.uploadedFiles[tmpIndex].url
        this.uploadedFiles[tmpIndex].url = ''
        this.uploadedFiles[tmpIndex].url = src
      }, 4000)
    },
    createServerConfig() {
      return {
        process: (_fieldName, file, _metadata, load) => {
          this.showLoading({ show: true, text: this.$t('alert.processing') })

          let maxSize
          let fileType
          let contentType
          if (file.type.startsWith('image/')) {
            fileType = 'image'
            maxSize = this.maxImgSize
            contentType = 'image/jpeg'
          } else if (file.type.startsWith('video/')) {
            fileType = 'video'
            maxSize = this.maxVideoSize
            contentType = 'video/mp4'
          } else {
            this.msgErr = 'This file type is not supported'
            this.showLoading({ show: false })
            return
          }

          if (file.size > maxSize) {
            this.msgErr = this.$t('album.over_500MB')
            this.showLoading({ show: false })
            return
          }
          setTimeout(() => {
            load('timeout')
          }, 30000)
          //Get SignedURL
          this.$axios
            .post('/media/upload/presignedurl', {
              company_id: this.user.companyId,
              albumId: this.albumSelected,
              type: fileType,
            })
            .then(async ({ data }) => {
              //Upload file by SignedURL
              const uploadConfig = {
                headers: {
                  'Content-Type': contentType,
                },
                timeout: 600000, // Timeout set to 10 minutes
              }
              this.$axios
                .put(data.signedUrl, file, uploadConfig)
                .then(async (_response) => {
                  //Save media information
                  this.$axios
                    .post('/media/create ', {
                      company_id: this.user.companyId,
                      albumId: this.albumSelected,
                      mediaId: data.objectId,
                      timestamp: dayjs().valueOf(),
                      type: fileType,
                    })
                    .then(async (response) => {
                      load(response.data)
                      let result = {
                        ...response.data[0],
                        isShowLoading: true,
                      }

                      this.uploadedFiles.unshift(result)
                    })
                    .catch((error) => {
                      console.log('Save media information: ', error)
                      this.showLoading({ show: false })
                    })
                })
                .catch((error) => {
                  console.log(error)
                  this.showLoading({ show: false })
                })
            })
            .catch((error) => {
              console.log(error)
              this.showLoading({ show: false })
            })
        },
      }
    },
    showMedia(media) {
      switch (media.type) {
        case 'image':
          this.mediaItem = media
          this.dialogShowMedia = true
          break
        case 'video':
          this.$axios
            .post('/media/presignedurl', {
              company_id: media.company_id,
              albumId: media.album_id,
              mediaId: media.id,
              type: media.type,
            })
            .then(async (response) => {
              this.mediaItem = response.data
              this.dialogShowMedia = true
            })
            .catch((error) => {
              console.log(error)
            })
          break
      }
    },
    closeDisplayMedia() {
      this.dialogShowMedia = false
    },
    deleteFile(media) {
      this.$axios
        .post('/media/delete', {
          mediaId: media.id,
          company_id: media.company_id,
          albumId: media.album_id,
        })
        .then(async (_response) => {
          const indexToRemove = this.uploadedFiles.findIndex(
            (item) => item.id === media.id
          )
          if (indexToRemove !== -1) {
            this.uploadedFiles.splice(indexToRemove, 1)
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 500) {
            //redirect to page error 500
            this.$router.replace('/500')
            return
          }
        })
    },
    getAlbumList() {
      this.$axios
        .post('/album/list', {
          token: this.user.token,
          company_id: this.user.companyId,
        })
        .then(async (response) => {
          this.showLoading({ show: false })
          if (response.data.length > 0) {
            this.albums = response.data
            this.albums.sort((a, b) => b.timestamp - a.timestamp)
          }
        })
        .catch((error) => {
          this.showLoading({ show: false })
          console.log(error)
          if (error.response.status === 500) {
            //redirect to page error 500
            this.$router.replace('/500')
            return
          }
        })
    },
    closeWindow() {
      liff.closeWindow()
    },
    close() {
      this.show = true
    },
    createNewAlbum() {
      this.newNameAlbum = ''
      this.uploadedFiles = []
      this.show = false
    },
    createdAlbum(value) {
      this.albumSelected = value.id
      this.show = true
      this.getAlbumList()
    },
    checkImage() {
      this.msgErr = ''
      this.showLoading({ show: true, text: this.$t('alert.processing') })
      this.uploadedFiles = []
      this.$axios
        .post('/media/list', {
          albumId: this.albumSelected,
          company_id: this.user.companyId,
        })
        .then(async (response) => {
          if (response.data.length > 0) {
            this.uploadedFiles = response.data
            this.uploadedFiles.sort((a, b) => b.timestamp - a.timestamp)
          }
          this.showLoading({ show: false })
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 500) {
            //redirect to page error 500
            this.$router.replace('/500')
            return
          }
          this.showLoading({ show: false })
        })
    },
    handleAlbumChange() {
      this.uploadedFiles = []
    },
    handleUploadedFile() {
      this.showLoading({ show: false })
    },
  },
}
</script>

<style scoped>
.progress-media {
  width: 70%;
  margin-top: 1rem;
  margin-left: 0.2rem;
}
.img-preview {
  flex: 0 0 auto !important;
  width: 47%;
  aspect-ratio: 78/70;
  border: 1px solid #707070;
  margin-left: 1%;
  margin-right: 1%;
}
.icon-play-button {
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
::v-deep(.filepond--root .filepond--credits) {
  display: none;
}

::v-deep(.filepond--file-info) {
  display: none;
}

::v-deep(.filepond--list) {
  display: none;
}

.text-album {
  white-space: pre-wrap;
  font-size: 16px;
  text-align: left;
  padding-left: 0;
}
.link-action {
  color: #6170e8;
  font-size: 16px;
  cursor: pointer;
}
.btn-font-size {
  font-size: 16px !important;
  height: 44px !important;
}
.container-upload {
  background-color: #ffffff !important;
  height: 100%;
}
</style>
