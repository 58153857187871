import Vue from 'vue'
import VueRouter from 'vue-router'

// 保護者画面
import MemberIndex from '../views/member/Index.vue'
import MemberInfo from '../views/member/create/Info.vue'
import MemberFace from '../views/member/Face.vue'
import MemberAlbumList from '../views/member/album/Index.vue'
import MemberConnect from '../views/member/line/Connect.vue'
import MemberContact from '../views/member/contact/Contact.vue'
import MemberCheckin from '../views/member/checkin/Checkin.vue'
import MemberRichmenu from '../views/member/richmenu/Change.vue'
import MemberMessage from '../views/member/message/Message.vue'
import MemberBulkMessage from '../views/member/message/Bulk.vue'
import MemberAdminConnect from '../views/member/line/UserConnect.vue'
import MemberAlbumUpload from '../views/member/album/Upload.vue'
import MemberHistory from '../views/member/history/History.vue'

// 管理画面
import ManagerIndex from '../views/manager/Index.vue'
import ManagerSignIn from '../views/manager/SignIn.vue'
import ManagerMain from '../views/manager/ManagerMain.vue'
import ManagerMemberList from '../views/manager/member/list.vue'
import ManagerHistory from '../views/manager/history/history.vue'
import ManagerCheckin from '../views/manager/checkin/Checkin.vue'
import ManagerMessage from '../views/manager/message/message.vue'
import ManagerBulkMessage from '../views/manager/message/Bulk.vue'
import ManagerMaster from '../views/manager/master/Index.vue'
import CompanyMaster from '../views/manager/company/Index.vue'
import ManagerAlbumUpload from '../views/manager/album/Upload.vue'
import DownloadHistory from '../views/manager/album/DownloadHistory.vue'

// License
import Terms from '../views/license/Terms.vue'
import Policy from '../views/license/Policy.vue'
import ErrorPage from '../views/Error.vue'
import LineSetting from '../views/manager/setting/Setting.vue'

import i18n from '../plugins/i18n'

// メンテナンス中画面
import Maintenance from '../views/Maintenance.vue'

// Dummy
import Button from '@/dummy/Button'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/manager/signin',
  },
  {
    path: '*',
    name: 'error404',
    component: ErrorPage,
    props: {
      errorCode: '404',
      errorMessage: i18n.t('error_page.404_error'),
    },
  },
  {
    path: '/Maintenance',
    name: 'Maintenance',
    component: Maintenance,
  },

  {
    path: '/manager',
    name: 'ManagerIndex',
    component: ManagerIndex,
    children: [
      {
        path: '/manager/main',
        name: 'ManagerMain',
        component: ManagerMain,
      },
      {
        path: '/manager/member/list',
        name: 'ManagerMemberList',
        component: ManagerMemberList,
      },
      {
        path: '/manager/history',
        name: 'ManagerHistory',
        component: ManagerHistory,
      },
      {
        path: '/manager/check/:type',
        name: 'ManagerCheckin',
        component: ManagerCheckin,
      },
      {
        path: '/manager/message/history',
        name: 'ManagerMessage',
        component: ManagerMessage,
      },
      {
        path: '/manager/message/bulk',
        name: 'ManagerBulkMessage',
        component: ManagerBulkMessage,
      },
      {
        path: '/manager/master',
        name: 'ManagerMaster',
        component: ManagerMaster,
      },
      {
        path: '/manager/album/download/history',
        name: 'DownloadHistory',
        component: DownloadHistory,
      },
      {
        path: '/manager/album/upload',
        name: 'ManagerAlbumUpload',
        component: ManagerAlbumUpload,
      },
      {
        path: '/line/setting',
        name: 'LineSetting',
        component: LineSetting,
      },
      {
        path: '/manager/company/master',
        name: 'CompanyMaster',
        component: CompanyMaster,
      },
    ],
  },
  {
    path: '/member',
    name: 'MemberIndex',
    component: MemberIndex,
    children: [
      {
        path: '/member/info',
        component: MemberInfo,
        meta: { title: i18n.t('member_info.child_info') },
      },
      {
        path: '/member/face',
        component: MemberFace,
        meta: { title: i18n.t('member_face.reg_face') },
      },
      {
        path: '/member/line/connect',
        component: MemberConnect,
        meta: { title: i18n.t('line_connect.line_connect') },
      },
      {
        path: '/member/contact',
        component: MemberContact,
        meta: { title: i18n.t('member_contact.contact') },
      },
      {
        path: '/member/check/:type',
        name: 'MemberCheckin',
        component: MemberCheckin,
        beforeEnter: (to, from, next) => {
          switch (to.params.type) {
            case 'in':
              to.meta.title = i18n.t('attendance.enter_mode')
              break
            case 'out':
              to.meta.title = i18n.t('attendance.leave_mode')
              break
            default:
          }
          next()
        },
      },
      {
        path: '/member/richmenu/change',
        name: 'MemberRichmenu',
        component: MemberRichmenu,
      },
      {
        path: '/member/message/history',
        name: 'MemberMessageHistory',
        component: MemberMessage,
        meta: { title: i18n.t('navbar.menu_send_history') },
      },
      {
        path: '/member/message/bulk',
        name: 'MemberBulkMessage',
        component: MemberBulkMessage,
        meta: { title: i18n.t('send_bulk_message.window_name') },
      },
      {
        path: '/member/line/user/connect/:qr',
        name: 'UserLineConnect',
        component: MemberAdminConnect,
      },
      {
        path: '/member/album/list',
        name: 'MemberAlbumList',
        component: MemberAlbumList,
        meta: { title: i18n.t('album.album_list') },
      },
      {
        path: '/member/album/upload',
        name: 'MemberAlbumUpload',
        component: MemberAlbumUpload,
        meta: { title: i18n.t('album.img_upload') },
      },
      {
        path: '/member/history',
        name: 'MemberHistory',
        component: MemberHistory,
        meta: { title: i18n.t('navbar.menu_attendance_history') },
      },
    ],
  },
  {
    path: '/manager/signin',
    name: 'ManagerSignIn',
    component: ManagerSignIn,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: { title: i18n.t('license.terms_window_name') },
  },
  {
    path: '/policy',
    name: 'Policy',
    component: Policy,
    meta: { title: i18n.t('license.policy_window_name') },
  },
  {
    path: '/500',
    component: ErrorPage,
    props: {
      errorCode: '500',
      errorMessage: i18n.t('error_page.500_error'),
    },
  },
  {
    path: '/dummy/button',
    name: 'Dummy',
    component: Button,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(_to, _from, _savedPosition) {
    return { x: 0, y: 0 }
  },
  routes,
})

/**
 * 画面遷移後にタイトルを更新する
 */
router.afterEach((to) => {
  let str = process.env.VUE_APP_TITLE
  if (to.meta.title) {
    str = to.meta.title
  }
  document.title = str
})

export default router
