import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMain,[_c(VContainer,{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"100%"}},[_c(VCard,{staticClass:"mx-auto",staticStyle:{"border-radius":"12px"},attrs:{"width":"660","height":"387"}},[_c(VContainer,{staticStyle:{"padding":"40px 80px"}},[_c(VCardTitle,{staticClass:"d-block pt-0 pb-5 d-flex justify-center"},[_c('h1',{staticClass:"text-center d-flex align-center",staticStyle:{"font-size":"20px","color":"#333333"}},[_c('img',{staticClass:"mr-1",attrs:{"src":_vm.logo,"alt":"Logo"}}),_vm._v(" "+_vm._s(_vm.$t('navbar.title_top'))+" ")])]),_c(VForm,{ref:"send_data"},[_c(VRow,[_c(VCol,[_c('div',[_c('label',{staticClass:"pl-2",staticStyle:{"font-size":"14px","color":"#9e9e9e"}},[_vm._v(_vm._s(_vm.$t('login.mail')))]),_c(VTextField,{staticClass:"input-text",attrs:{"height":"44","label":_vm.$t('login.mail'),"dense":"","single-line":"","outlined":"","background-color":"#EEEEEE","rules":[_vm.required]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('div',[_c('label',{staticClass:"pl-2",staticStyle:{"font-size":"14px","color":"#9e9e9e"}},[_vm._v(_vm._s(_vm.$t('login.password')))]),_c(VTextField,{staticClass:"input-text",attrs:{"height":"44","type":_vm.show ? 'text' : 'password',"append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"label":_vm.$t('login.password'),"single-line":"","dense":"","outlined":"","background-color":"#EEEEEE","rules":[_vm.required]},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)])],1),_c(VRow,[_c(VCol,{staticStyle:{"display":"flex","justify-content":"center"}},[_c('ENJIBtn',{staticClass:"px-6",attrs:{"height":"44","color":"#6170E8"},on:{"click":_vm.signIn}},[_vm._v(" "+_vm._s(_vm.$t('navbar.login'))+" ")])],1)],1)],1)],1)],1)],1),_c('snackbar-vue'),_c('loading-message-vue')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }