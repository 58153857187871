<template>
  <v-container fluid>
    <v-row class="mt-5">
      <v-col>
        <p class="text-start">
          {{ $t('member_info.term_of_use_instruction') }}
        </p>
      </v-col>
    </v-row>
    <v-row class="align-center">
      <v-col class="custom-col-term">
        <v-checkbox
          v-model="checkbox1"
          :label="$t('member_info.term_of_service_agree')"
          class="pl-2"
        ></v-checkbox>
        <v-btn icon width="24" height="24" @click.prevent="openTermsWindow">
          <img :src="redirect" />
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="align-center">
      <v-col class="custom-col-term">
        <v-checkbox
          v-model="checkbox2"
          :label="$t('member_info.privacy_policy_agree')"
          class="pl-2"
        ></v-checkbox>
        <v-btn icon width="24" height="24" @click.prevent="openPolicyWindow">
          <img :src="redirect" />
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="d-inline-flex">
      <v-col cols="6">
        <ENJIBtn
          color="#333333"
          outlined
          class="custom-btn-cancel"
          height="44"
          width="120"
          @click="back"
        >
          {{ $t('button.cancel') }}
        </ENJIBtn>
      </v-col>
      <v-col cols="6">
        <ENJIBtn
          color="#6170E8"
          :disabled="isDisabled"
          class="custom-btn-agree"
          height="44"
          width="120"
          @click="form"
        >
          {{ $t('member_info.agree') }}
        </ENJIBtn>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.custom-col-term {
  display: contents;
}
.custom-col-term .theme--light.v-label {
  color: #161a1a !important;
  margin-right: 12px;
}
.custom-btn-cancel {
  background-color: #fff;
  padding: 10px 20px !important;
}
.custom-btn-agree {
  padding: 14px 24px !important;
}
.custom-btn-agree.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #c0c6f6 !important;
  color: #fff !important;
}
</style>

<script>
import redirect from '@/assets/redirect.svg'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
export default {
  components: {
    ENJIBtn,
  },
  data() {
    return {
      checkbox1: false,
      checkbox2: false,
      redirect: redirect,
    }
  },
  created () {
    document.title = this.$t('member_info.term_of_use');
  },
  computed: {
    isDisabled() {
      return !(this.checkbox1 && this.checkbox2)
    },
  },
  methods: {
    openTermsWindow() {
      const windowName = this.$t('license.terms_window_name')
      window.open('/terms', windowName, 'width=1280,height=720,noopener')
      return false
    },
    openPolicyWindow() {
      const windowName = this.$t('license.policy_window_name')
      window.open('/policy', windowName, 'width=1280,height=720,noopener')
      return false
    },
    form() {
      this.$emit('form')
    },
    back() {
      this.$emit('back')
    },
  },
}
</script>
