<template>
  <v-card
    class="container-upload pa-0 main mx-auto elevation-0"
    variant="tonal"
  >
    <div>
      <v-card-title class="border-bottom mb-2 custom-font-title">
        {{ $t('navbar.menu_media') }}
      </v-card-title>
      <hr size="2" style="border-top: 1px solid #d0d0d0" />

      <v-row class="ma-0 pb-6 px-6" style="height: 100%">
        <v-col cols="12" class="pb-0 bg-white">
          <v-card-text class="text-album">{{
            $t('album.please_select_album')
          }}</v-card-text>
          <div class="d-flex">
            <v-select
              ref="albumSelected"
              v-model="albumSelected"
              @change="handleAlbumChange"
              :items="albums"
              item-text="name"
              item-value="id"
              outlined
              dense
              style="height: 40px; width: 50px; line-height: 24px"
              :error="!!requiredSelectAlbumErrorMessages"
              :error-messages="requiredSelectAlbumErrorMessages"
            ></v-select>

            <ENJIBtn
              color="#333333"
              outlined
              class="ml-3 px-2 py-1"
              :class="{
                'disable-button': albumSelected === '' || albumSelected == null,
              }"
              @click="checkImage"
            >
              {{ $t('album.check_img') }}
            </ENJIBtn>
          </div>

          <v-card-text class="text-album py-2 pl-0">
            <v-btn class="link-action pl-0" @click="createNewAlbum" text>
              {{ $t('album.add_new_album') }}
            </v-btn>
          </v-card-text>

          <v-card-text class="text-album pl-0">
            {{ $t('album.please_select_img') }}
          </v-card-text>
          <div @click="checkSelectedAlbum()">
            <file-pond
              :disabled="albumSelected === ''"
              ref="pond"
              name="filepond"
              v-model="files"
              :label-idle="$t('album.tap_to_upload_img')"
              :allow-multiple="true"
              accepted-file-types="image/*,video/*"
              :server="createServerConfig()"
              @processfiles="handleUploadedFile"
            />
          </div>

          <div v-if="msgErr !== ''" class="red--text">{{ msgErr }}</div>
          <div v-if="uploadedFiles.length > 0">
            <v-card-text>{{ $t('album.uploaded_file') }}</v-card-text>
            <v-container class="px-6">
              <v-row>
                <v-col
                  v-for="(file, index) in uploadedFiles"
                  :key="`${file.id}-${file.errorCount}`"
                  cols="6"
                  md="3"
                  class="pb-3"
                >
                  <div>
                    <v-img
                      class="mt-2 img-preview"
                      @error="onImgError(index)"
                      :src="file.url"
                      @click="showMedia(file)"
                    >
                      <v-btn
                        icon
                        class="float-right"
                        @click.stop="deleteFile(file)"
                      >
                        <v-icon size="30">mdi-close-circle-outline </v-icon>
                      </v-btn>
                      <v-icon
                        v-if="file.type === 'video'"
                        size="50"
                        color="black"
                        class="icon-play-button"
                        >mdi-play-circle-outline</v-icon
                      >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-linear
                            color="#6170e8"
                            indeterminate
                            rounded
                            height="6"
                            class="progress-media"
                            v-if="
                              file.isShowLoading ||
                              file.isShowLoading === undefined
                            "
                          ></v-progress-linear>
                        </v-row>
                      </template>
                    </v-img>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </div>

    <div>
      <v-dialog
        v-if="dialogShowMedia"
        v-model="dialogShowMedia"
        persistent
        width="880"
        scrollable
      >
        <DisplayMedia
          :mediaItem="mediaItem"
          :isPc="true"
          width="auto"
          @clickCloseDisplayMedia="closeDisplayMedia"
        />
      </v-dialog>
    </div>

    <div v-if="showAblbumForm">
      <v-dialog
        persistent
        v-model="showAblbumForm"
        width="600px"
        height="100vh"
        :transition="dialogTransition"
        content-class="custom-dialog-content"
      >
        <AlbumFormM
          :albums="albums"
          :companyId="user.companyId"
          :newAlbumData="newAlbumData"
          @closeAlbumForm="close"
          @createdAlbum="createdAlbum"
          class="bg-white"
          ref="btnCreateAlbum"
        ></AlbumFormM>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import dayjs from 'dayjs'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import AlbumFormM from '@/components/album/AlbumFormM.vue'
import DisplayMedia from '@/components/album/DisplayMedia.vue'

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType)

export default {
  components: {
    ENJIBtn,
    FilePond,
    AlbumFormM,
    DisplayMedia,
  },
  data() {
    return {
      showAblbumForm: false,
      requiredSelectAlbumErrorMessages: '',
      dialogTransition: 'fade-transition-right',
      albumSelected: '',
      newAlbumData: {
        name: '',
      },
      albums: [],
      files: [],
      uploadedFiles: [],
      maxImgSize: 100 * 1024 * 1024, // 100MB
      maxVideoSize: 500 * 1024 * 1024, // 500MB
      msgErr: '',
      dialogShowMedia: false,
      mediaItem: {},
      user: {},
    }
  },
  created() {
    this.getAlbumList()
  },

  methods: {
    isAuthenticated() {
      this.$cognito
        .isAuthenticated()
        .then((__auth) => {
          this.initialize()
        })
        .catch((_session) => {
          this.$router.replace('/manager/signin')
        })
    },
    onImgError(index) {
      const tmpIndex = index
      this.uploadedFiles[tmpIndex].errorCount =
        this.uploadedFiles[tmpIndex].errorCount || 0
      this.uploadedFiles[tmpIndex].errorCount++

      if (this.uploadedFiles[tmpIndex].errorCount > 4) {
        this.uploadedFiles[tmpIndex].isShowLoading = false
        return
      }

      setTimeout(() => {
        const src = this.uploadedFiles[tmpIndex].url
        this.uploadedFiles[tmpIndex].url = ''
        this.uploadedFiles[tmpIndex].url = src
      }, 4000)
    },
    createServerConfig() {
      return {
        process: (_fieldName, file, _metadata, load) => {
          this.showLoading({ show: true, text: this.$t('alert.processing') })

          let maxSize
          let fileType
          let contentType
          if (file.type.startsWith('image/')) {
            fileType = 'image'
            maxSize = this.maxImgSize
            contentType = 'image/jpeg'
          } else if (file.type.startsWith('video/')) {
            fileType = 'video'
            maxSize = this.maxVideoSize
            contentType = 'video/mp4'
          } else {
            this.msgErr = 'This file type is not supported'
            this.showLoading({ show: false })
            return
          }

          if (file.size > maxSize) {
            this.msgErr = this.$t('album.over_500MB')
            this.showLoading({ show: false })
            return
          }
          setTimeout(() => {
            load('timeout')
          }, 30000)
          this.$cognito.isAuthenticated().then(async (auth) => {
            //Get SignedURL
            this.$axiosM
              .post(
                '/media/upload/presignedurl',
                {
                  company_id: auth.companyId,
                  albumId: this.albumSelected,
                  type: fileType,
                },
                {
                  headers: {
                    Authorization: auth.idToken,
                  },
                }
              )
              .then(async ({ data }) => {
                //Upload file by SignedURL
                const uploadConfig = {
                  headers: {
                    'Content-Type': contentType,
                  },
                  timeout: 600000, // Timeout set to 10 minutes
                }
                this.$axiosM
                  .put(data.signedUrl, file, uploadConfig)
                  .then(async (_response) => {
                    //Save media information
                    this.$axiosM
                      .post(
                        '/media/create',
                        {
                          company_id: auth.companyId,
                          albumId: this.albumSelected,
                          mediaId: data.objectId,
                          timestamp: dayjs().valueOf(),
                          type: fileType,
                        },
                        {
                          headers: {
                            Authorization: auth.idToken,
                          },
                        }
                      )
                      .then(async (response) => {
                        load(response.data)
                        let result = {
                          ...response.data[0],
                          isShowLoading: true,
                        }

                        this.uploadedFiles.unshift(result)
                      })
                      .catch((error) => {
                        console.log('Save media information: ', error)
                        this.showLoading({ show: false })
                      })
                  })
                  .catch((error) => {
                    console.log(error)
                    this.showLoading({ show: false })
                  })
              })
              .catch((error) => {
                console.log(error)
                this.showLoading({ show: false })
              })
          })
        },
      }
    },
    showMedia(media) {
      this.$cognito.isAuthenticated().then(async (auth) => {
        switch (media.type) {
          case 'image':
            this.mediaItem = media
            this.dialogShowMedia = true
            break
          case 'video':
            this.$axiosM
              .post(
                '/media/presignedurl',
                {
                  company_id: media.company_id,
                  albumId: media.album_id,
                  mediaId: media.id,
                  type: media.type,
                },
                {
                  headers: {
                    Authorization: auth.idToken,
                  },
                }
              )
              .then(async (response) => {
                this.mediaItem = response.data
                this.dialogShowMedia = true
              })
              .catch((error) => {
                console.log(error)
              })
            break
        }
      })
    },
    closeDisplayMedia() {
      this.dialogShowMedia = false
    },
    deleteFile(media) {
      this.$cognito.isAuthenticated().then(async (auth) => {
        this.$axiosM
          .post(
            '/media/delete',
            {
              mediaId: media.id,
              company_id: media.company_id,
              albumId: media.album_id,
            },
            {
              headers: {
                Authorization: auth.idToken,
              },
            }
          )
          .then(async (_response) => {
            const indexToRemove = this.uploadedFiles.findIndex(
              (item) => item.id === media.id
            )
            if (indexToRemove !== -1) {
              this.uploadedFiles.splice(indexToRemove, 1)
            }
          })
          .catch((error) => {
            console.log(error)
            if (error.response.status === 500) {
              //redirect to page error 500
              this.$router.replace('/500')
              return
            }
          })
      })
    },
    getAlbumList() {
      this.showLoading({ show: true })
      this.$cognito.isAuthenticated().then(async (auth) => {
        this.user = auth
        this.$axiosM
          .post(
            '/album/list',
            {
              token: auth.token,
              company_id: auth.companyId,
            },
            {
              headers: {
                Authorization: auth.idToken,
              },
            }
          )
          .then(async (response) => {
            this.showLoading({ show: false })
            if (response.data.length > 0) {
              this.albums = response.data
              this.albums.sort((a, b) => b.timestamp - a.timestamp)
            }
          })
          .catch((error) => {
            this.showLoading({ show: false })
            console.log(error)
            if (error.response.status === 500) {
              //redirect to page error 500
              this.$router.replace('/500')
              return
            }
          })
      })
    },
    close() {
      this.showAblbumForm = false
    },
    createNewAlbum() {
      this.newAlbumData = Object.assign(
        {},
        {
          name: '',
        }
      )

      this.showAblbumForm = true
    },
    createdAlbum(value) {
      this.albumSelected = value.id
      this.showAblbumForm = false
      this.uploadedFiles = []
      this.getAlbumList()
    },
    checkImage() {
      this.$refs.albumSelected.focus()
      if (this.albumSelected === '' || this.albumSelected == null) {
        this.requiredSelectAlbumErrorMessages = this.$t('alert.required_text')
        return
      }

      this.msgErr = ''
      this.showLoading({ show: true, text: this.$t('alert.processing') })
      this.uploadedFiles = []
      this.$cognito.isAuthenticated().then(async (auth) => {
        this.$axiosM
          .post(
            '/media/list',
            {
              albumId: this.albumSelected,
              company_id: auth.companyId,
            },
            {
              headers: {
                Authorization: auth.idToken,
              },
            }
          )
          .then(async (response) => {
            if (response.data.length > 0) {
              this.uploadedFiles = response.data
              this.uploadedFiles.sort((a, b) => b.timestamp - a.timestamp)
            }
            this.showLoading({ show: false })
          })
          .catch((error) => {
            console.log(error)
            if (error.response.status === 500) {
              //redirect to page error 500
              this.$router.replace('/500')
              return
            }
            this.showLoading({ show: false })
          })
      })
    },
    handleAlbumChange() {
      this.requiredSelectAlbumErrorMessages = ''
      this.uploadedFiles = []
    },
    handleUploadedFile() {
      this.showLoading({ show: false })
    },
    checkSelectedAlbum() {
      this.$refs.albumSelected.focus()
      if (this.albumSelected === '' || this.albumSelected == null) {
        this.requiredSelectAlbumErrorMessages = this.$t('alert.required_text')
      }
    },
  },
}
</script>

<style scoped>
.progress-media {
  width: 70%;
  margin-top: 1rem;
  margin-left: 0.2rem;
}
.img-preview {
  width: 100%;
  aspect-ratio: 78/70;
  border: 1px solid #707070;
  margin-left: 1%;
  margin-right: 1%;
}
.icon-play-button {
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
::v-deep(.filepond--root .filepond--credits) {
  display: none;
}

::v-deep(.filepond--file-info) {
  display: none;
}

::v-deep(.filepond--list) {
  display: none;
}

.text-album {
  white-space: pre-wrap;
  font-size: 16px;
  text-align: left;
  padding-left: 0;
}
.link-action {
  color: #6170e8;
  font-size: 16px;
  cursor: pointer;
}
.btn-font-size {
  font-size: 16px !important;
  height: 44px !important;
}
.container-upload {
  background-color: #ffffff !important;
  height: 100%;
}
.bg-white {
  background-color: #ffffff !important;
}

.disable-button {
  opacity: 0.3;
  cursor: default;
}
</style>
