<template>
  <div class="py-2 ml-auto div-pagination">
    <v-pagination
      v-on:input="$emit('input', $event)"
      v-bind="$attrs"
      color="#6170E8"
      rounded
      variant="outlined"
    ></v-pagination>
  </div>
</template>
<style>
.div-pagination {
  width: fit-content;
}

.v-pagination > li > button {
  border: 1px solid #6170e8;
}

.v-pagination > li.active > button,
.v-pagination > li > button:hover {
  color: white !important;
  background-color: #6170e8 !important;
}

.v-pagination__navigation .v-icon {
  color: #6170e8;
}

.theme--light.v-pagination .v-pagination__item {
  border: 1px solid #6170e8;
  color: #6170e8;
}

.theme--light.v-pagination .v-pagination__item--active,
.v-pagination__navigation .v-icon:hover {
  color: white;
}
.v-pagination__item,
.v-pagination__navigation {
  font-size: 20px;
  box-shadow: unset !important;
}
</style>
<script>
export default {}
</script>
