<template>
  <div>
    <v-main v-if="show" align="center">
      <v-container fill-height fluid>
        <v-row justify="center">
          <v-col v-if="status == 0" class="custom-col-form">
            <!-- 何でも相談 -->
            <Form :user="user" v-on:done="onDone" />
          </v-col>
          <v-col v-else-if="status == 1">
            <v-card-text class="text-left custom-text">
              {{ $t('member_contact.sent_and_contact') }}
            </v-card-text>
            <v-card-actions class="justify-center">
              <ENJIBtn
                @click="closeWindow"
                depressed
                outlined
                height="44"
                class="btn-outline"
              >
                {{ $t('button.close') }}
              </ENJIBtn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-row v-else style="margin-top: 28px" justify="center" class="mx-0">
      <v-col cols="12" class="text-center">
        <h2
          style="
            font-size: 16px;
            color: #333333;
            margin-bottom: 16px;
            text-align: center;
          "
        >
          {{ $t('line_connect.register_new_child_label') }}
        </h2>
        <ENJIBtn height="44" color="#6170E8" @click="backToRegisterChild">{{
          $t('line_connect.register_new_child')
        }}</ENJIBtn>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped>
.custom-col-form {
  height: 100vh !important;
  background-color: #fff;
  padding: 0 !important;
}
.btn-outline {
  margin-right: 24px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  background-color: #ffffff;
  color: #333333 !important;
}
</style>

<script>
import Form from '@/components/contact/Form.vue'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'

export default {
  components: {
    Form,
    ENJIBtn,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      show: true,
      status: -1,
    }
  },
  mounted() {
    this.statusCheck()
  },
  methods: {
    statusCheck() {
      this.showLoading({ show: true })
      this.$axios
        .post('/member/connect/list', {
          token: this.user.token,
          company_id: this.user.companyId,
        })
        .then(async (response) => {
          if (response.status == 200 && response.data.length > 0) {
            this.show = true
            this.status = 0
            this.showLoading({ show: false })
          } else {
            this.show = false
            this.showLoading({ show: false })
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 500) {
            //redirect to page error 500
            this.$router.replace('/500')
            return
          }
          this.show = false
          this.showLoading({ show: false })
        })
    },
    onDone() {
      this.status = 1
    },
    backToRegisterChild() {
      this.$router.push('/member/info')
    },
    closeWindow() {
      liff.closeWindow()
    },
  },
}
</script>
