import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto"},[_c(VContainer,{staticStyle:{"border-bottom":"2px solid #c9c9c9"}},[_c('div',[_c(VCardTitle,{staticClass:"pt-4 px-10 justify-center custom-title-head"},[_vm._v(_vm._s(_vm.$t('member_info.avatar_confirm'))+" ")])],1),_c('div',{staticStyle:{"display":"block","text-align":"center"}},[_c(VAvatar,{attrs:{"size":"240"}},[_c(VImg,{staticClass:"mx-auto",attrs:{"src":_vm.imgSrc,"alt":"Avatar"}})],1)],1)]),_c(VCardActions,{staticClass:"text-center d-block py-4"},[_c('ENJIBtn',{staticClass:"p-btn-retake font-size-16",attrs:{"color":"#333333","outlined":"","height":"44"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('button.retake'))+" ")]),_c('ENJIBtn',{staticClass:"ml-3 font-size-16 p-btn-reg",attrs:{"color":"#6170E8","height":"44"},on:{"click":_vm.register}},[_vm._v(" "+_vm._s(_vm.$t('button.regist_2'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }