<template>
  <Scanner
    ref="FaceCamera"
    :leave="leave"
    @interval="onInterval"
    :user="user"
  />
</template>

<script>
import Scanner from '@/components/checkin/Scanner.vue'

export default {
  beforeRouteLeave(_to, _from, next) {
    this.leave = true
    // カメラ停止
    if (this.$refs.FaceCamera) {
      if (this.intervalId) clearInterval(this.intervalId)
      const videoTracks = this.$refs.FaceCamera.video.srcObject
      if (videoTracks) {
        videoTracks.getVideoTracks().forEach((track) => {
          track.stop()
        })
      }
    }
    next()
  },
  components: {
    Scanner,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      leave: false,
      intervalId: null,
    }
  },
  mounted() {
    this.showLoading({ show: true })
  },
  methods: {
    onInterval(val) {
      this.intervalId = val
    },
  },
}
</script>
