<template>
  <div>
    <header>
      <v-container class="content-error">
        <div class="d-flex align-center justify-center error-code">
          {{ errorCode }}
        </div>
        <p
          v-html="formattedText"
          class="d-flex align-center justify-center error-text"
        ></p>
        <v-card-title class="update-btn-container">
          <v-row justify="center">
            <v-col cols="12" class="d-flex justify-center" v-if="isPC">
              <ENJIBtn
                height="44"
                color="#6170E8"
                class="update-btn"
                @click="backToTopPage"
              >
                {{ $t('button.back_to_top_page') }}
              </ENJIBtn>
            </v-col>
            <v-col cols="12" class="d-flex justify-center" v-else>
              <ENJIBtn
                height="44"
                color="#6170E8"
                class="update-btn"
                @click="closeWindow"
              >
                {{ $t('button.return_to_line') }}
              </ENJIBtn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-container>
    </header>
  </div>
</template>

<style scoped>
.content-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.error-code {
  font-size: 200px;
  font-weight: 700;
}
.error-text {
  font-size: 32px;
  font-weight: 700;
  padding-top: 16px;
  text-align: center;
}
.update-btn-container {
  padding: 40px;
}
.update-btn {
  padding: 0px 48px !important;
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 600px) {
  .error-code {
    font-size: 100px;
  }
  .error-text {
    font-size: 16px;
    padding-top: 8px;
  }
  .update-btn-container {
    padding: 16px;
  }
  .update-btn {
    padding: 0px 23px !important;
    font-size: 16px;
  }
}
</style>

<script>
import ENJIBtn from '@/components/buttons/ENJIBtn'
export default {
  components: {
    ENJIBtn,
  },
  props: {
    errorCode: String,
    errorMessage: String,
  },
  data() {
    return {
      isPC: this.checkIfPC(),
    }
  },
  computed: {
    formattedText() {
      return this.errorMessage.replace(/\n/g, '<br>')
    },
  },
  methods: {
    checkIfPC() {
      const userAgent = navigator.userAgent
      const isPC =
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        )
      return isPC
    },
    closeWindow() {
      if (liff.isInClient()) {
        liff.closeWindow()
      } else {
        window.location.href = 'https://line.me/R/nv/chat'
      }
    },
    backToTopPage() {
      this.$router.replace('/manager/member/list')
    },
  },
}
</script>
