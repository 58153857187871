import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{staticClass:"pt-4 ma-0",attrs:{"justify":"center"}},[_c(VCol,{staticClass:"text-center px-0",attrs:{"cols":"12"}},[_c(VCardText,{staticClass:"text-album pt-0"},[_vm._v(_vm._s(_vm.$t('album.new_album')))]),_c('label',{staticClass:"custom-label"},[_c('span',[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('album.album_name')))]),_c(VTextField,{ref:"newNameAlbum",attrs:{"outlined":"","dense":"","height":"44px","background-color":"#EEEEEE","rules":[_vm.required, _vm.textRules.regex, _vm.lengthRules, _vm.checkDuplicateAlbum]},model:{value:(_vm.newNameAlbum),callback:function ($$v) {_vm.newNameAlbum=$$v},expression:"newNameAlbum"}}),_c(VCardActions,{staticClass:"text-center d-block mt-3"},[_c('ENJIBtn',{staticClass:"px-9 mr-3 btn-font-size",attrs:{"color":"#333333","outlined":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")]),_c('ENJIBtn',{staticClass:"px-9 ml-3 btn-font-size",attrs:{"color":"#6170E8"},on:{"click":_vm.createAlbum}},[_vm._v(" "+_vm._s(_vm.$t('button.save'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }