import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.show)?_c(VMain,{attrs:{"align":"center"}},[_c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VRow,{attrs:{"justify":"center"}},[(_vm.status == 0)?_c(VCol,{staticClass:"custom-col-form"},[_c('Form',{attrs:{"user":_vm.user},on:{"done":_vm.onDone}})],1):(_vm.status == 1)?_c(VCol,[_c(VCardText,{staticClass:"text-left custom-text"},[_vm._v(" "+_vm._s(_vm.$t('member_contact.sent_and_contact'))+" ")]),_c(VCardActions,{staticClass:"justify-center"},[_c('ENJIBtn',{staticClass:"btn-outline",attrs:{"depressed":"","outlined":"","height":"44"},on:{"click":_vm.closeWindow}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")])],1)],1):_vm._e()],1)],1)],1):_c(VRow,{staticClass:"mx-0",staticStyle:{"margin-top":"28px"},attrs:{"justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h2',{staticStyle:{"font-size":"16px","color":"#333333","margin-bottom":"16px","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t('line_connect.register_new_child_label'))+" ")]),_c('ENJIBtn',{attrs:{"height":"44","color":"#6170E8"},on:{"click":_vm.backToRegisterChild}},[_vm._v(_vm._s(_vm.$t('line_connect.register_new_child')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }