<template>
  <v-card class="mx-auto">
    <v-container>
      <div v-if="isMobile === false">
        <v-card-title class="pt-6 px-10 justify-center custom-title-head"
          >{{ $t('kindergarten_list.qrcode') }}
        </v-card-title>
        <v-card-text class="text-center">
          {{ $t('member_info.qr_code', { name }) }}
        </v-card-text>
      </div>
      <v-card-text
        v-else-if="isMobile"
        class="text-center"
        style="
          font-size: 16px;
          font-weight: 700;
          color: #333333;
          padding-bottom: 8px;
        "
      >
        {{ $t('member_info.qr_codeM', { name }) }}
      </v-card-text>
      <v-card-title class="justify-center">
        <vue-qrcode
          class="justify-center"
          :value="qrData"
          :options="option"
          tag="img"
          :width="isMobile ? 200 : 240"
          :height="isMobile ? 200 : 240"
        ></vue-qrcode
      ></v-card-title>
    </v-container>
    <v-card-actions
      :class="{
        'text-center': true,
        'd-block': true,
        'pb-10': !isMobile,
        'box-mobile': isMobile,
      }"
    >
      <ENJIBtn
        @click="close"
        color="#333333"
        outlined
        class="px-9 mr-3 btn-font-size-line-card"
      >
        {{ $t('button.close') }}
      </ENJIBtn>
      <ENJIBtn
        @click="update"
        color="#6170E8"
        class="px-9 ml-3 btn-font-size-line-card"
      >
        {{ $t('member_info.update') }}
      </ENJIBtn>
    </v-card-actions>
  </v-card>
</template>
<script>
import VueQrcode from '@chenfengyuan/vue-qrcode'
import ENJIBtn from '@/components/buttons/ENJIBtn'

export default {
  components: {
    VueQrcode,
    ENJIBtn,
  },
  props: {
    qrData: String,
    name: String,
    id: String,
    isMember: {
      default: false,
      type: Boolean,
    },
    isMobile: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      option: {
        errorCorrectionLevel: 'M',
        maskPattern: 0,
        margin: 1,
        scale: 7,
        color: {
          dark: '#000000FF',
          light: '#FFFFFFFF',
        },
      },
    }
  },
  methods: {
    close() {
      this.$emit('clickClose')
    },
    update() {
      this.showLoading({ show: true })
      this.$axios
        .post('/qr/update', {
          id: this.id,
          isMember: this.isMember,
        })
        .then((response) => {
          this.showLoading({ show: false })
          this.$emit('update', response.data)
        })
        .catch(() => {
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.update_failed'),
            isMobile: true,
            color: 'error',
            timeout: 6000,
          })
        })
    },
  },
}
</script>
<style scoped>
.box-mobile {
  padding-bottom: 24px !important;
}
</style>
