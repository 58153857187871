<template>
  <v-main align="center" height="100vh">
    <v-container fill-height fluid>
      <v-row justify="center">
        <v-col v-if="status == 0" class="pt-1 px-0">
          <!-- 園児リスト -->
          <MemberList
            :member-list="memberList"
            :user="user"
            @form="onForm"
            @back="onList"
            @qr="onDispQR"
            @edit="onEdit"
          />
        </v-col>
        <v-col v-else-if="status == 1" class="member-info-screen">
          <!-- 連携フォーム -->
          <CreateForm :user="user" @back="onList" @onClickScan="openQrScan" />
        </v-col>
        <v-col v-else-if="status == 3" class="member-info-screen">
          <!-- Term of service -->
          <TermOfUse @form="openCreateForm" @back="onList" />
        </v-col>
        <v-col v-else-if="status == 5">
          <QrScan @onScanned="handleQr" @back="openCreateForm" />
        </v-col>
        <v-col v-else-if="status == 6" class="pt-1 px-0">
          <!-- Edit form -->
          <EditList :member-list="memberList" :user="user" @back="onList" />
        </v-col>
      </v-row>
      <div>
        <v-dialog persistent v-model="qrDialog" width="358px">
          <!-- QR画面 -->
          <DispQR
            :qrData="qrData"
            :name="name"
            :id="id"
            isMobile
            isMember
            @clickClose="qrDialog = false"
            @update="updateQR"
          />
        </v-dialog>
      </div>
    </v-container>
  </v-main>
</template>

<style scoped>
.member-info-screen {
  height: 100vh !important;
  background-color: #fff;
  padding: 0 !important;
}
</style>

<script>
import MemberList from '@/components/create/ConnectList.vue'
import CreateForm from '@/components/create/Form.vue'
import DispQR from '@/components/create/DispQR.vue'
import TermOfUse from '@/components/TermOfUse.vue'
import QrScan from '@/components/QrScan.vue'
import EditList from '@/components/create/EditList.vue'
export default {
  components: {
    MemberList,
    CreateForm,
    DispQR,
    TermOfUse,
    QrScan,
    EditList,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      member: {},
      status: -1,
      memberList: [],
      qrData: '',
      name: '',
      id: '',
      qrDialog: false,
    }
  },
  mounted() {
    if (!this.user.isClient) {
      this.$router.replace('/404')
      return
    }
    const qr = this.$route.query.qr
    qr !== undefined ? this.handleQr(qr) : this.statusCheck()
  },
  methods: {
    statusCheck() {
      this.showLoading({ show: true })
      this.$axios
        .post('/member/connect/list', {
          token: this.user.token,
          company_id: this.user.companyId,
        })
        .then(async (response) => {
          if (response.status == 200) {
            this.showLoading({ show: false })
            this.memberList = response.data
          } else {
            this.showLoading({ show: false })
          }
          this.status = 0
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 500) {
            //redirect to page error 500
            this.$router.replace('/500')
            return
          }
          this.showLoading({ show: false })
          this.status = 0
        })
    },
    onForm() {
      this.status = this.memberList.length == 0 ? 3 : 1
    },
    openCreateForm() {
      document.title = this.$t('member_info.child_info')
      this.status = 1
    },
    onList() {
      this.status = 0
      this.statusCheck()
    },
    onEdit() {
      this.status = 6
    },
    onDispQR(item) {
      this.qrData = `${this.user.liffURL}/member/info?qr=${item.qr}&companyId=${this.user.companyId}`
      this.name = `${item.firstname} ${item.lastname}`
      this.id = item.id
      this.qrDialog = true
    },
    openQrScan() {
      this.status = 5
    },
    updateQR(value) {
      this.qrData = `${this.user.liffURL}/member/info?qr=${value}&companyId=${this.user.companyId}`
    },
    closeWindow() {
      liff.closeWindow()
    },
    async handleQr(qr) {
      this.showLoading({ show: true, text: this.$t('alert.processing') })

      this.$axios
        .post('/member/qr/info', {
          token: this.user.token,
          qr: qr,
        })
        .then(async (response) => {
          if (response.status == 200) {
            const data = response.data
            // data is empty
            if (data == false) {
              this.showSnack({
                text: this.$t('member_info.cant_not_read_qr'),
                isMobile: true,
                color: 'error',
                timeout: 6000,
              })
              this.openCreateForm()
              this.showLoading({ show: false })
              return
            }

            const profile = await liff.getProfile()
            if (
              data.supervisors.some(
                (supervisor) => supervisor.userId === profile.userId
              )
            ) {
              this.showSnack({
                text: this.$t('member_info.already_registered'),
                isMobile: true,
                color: 'error',
                timeout: 6000,
              })
              this.openCreateForm()
              this.showLoading({ show: false })
              return
            }

            this.user = {
              ...this.user,
              ...data,
              connected: true,
            }
            this.openCreateForm()
          } else {
            this.showSnack({
              text: this.$t('member_info.cant_not_read_qr'),
              isMobile: true,
              color: 'error',
              timeout: 6000,
            })
            this.openCreateForm()
          }
          this.showLoading({ show: false })
        })
        .catch((error) => {
          console.log(error)
          this.openCreateForm()
          this.showLoading({ show: false })
        })
    },
  },
}
</script>
