<template>
  <v-container fill-height fluid class="d-block pa-0">
    <div style="height: 100%;">
      <v-card style="height: 100%;">
        <v-card-title class="border-bottom mb-2 custom-title-head">
          {{ $t('album.download_history') }}
        </v-card-title>
        <hr size="2" style="border-top: 1px solid #d0d0d0" />
        <v-data-table
          width="100%"
          height="calc(100vh - 220px)"
          :page.sync="page"
          :items="downloadHistory"
          :headers="headers"
          fixed-header
          class="elevation-1"
          :items-per-page="itemsPerPage"
          hide-default-footer
        >
          <template v-slot:[`item.datetime`]="{ item }">
            {{ formatDate(item.datetime) }}
          </template>
          <template v-slot:[`item.count`]="{ item }">
            {{ $t('album.count_file', { count: item.count }) }}
          </template>
        </v-data-table>
        <Pagination v-model="page" :length="pageCount" />
      </v-card>
    </div>

  </v-container>
</template>
<style scoped>
.album-screen,
::v-deep(.album-screen) {
  background-color: #fff;
}
</style>
<script>
import dayjs from 'dayjs'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import ENJIIconBtn from '@/components/buttons/ENJIIconBtn.vue'
import Pagination from '@/components/Pagination.vue'

export default {
  components: {
    ENJIBtn,
    ENJIIconBtn,
    Pagination
  },
  async created() {
    this.initialize()
  },

  props: {
  },
  data() {
    return {
      page: 1,
      itemsPerPage: 100,
      headers: [
        {
          text: this.$t('album.name'),
          sortable: false,
          value: 'name',
          width: '25%'
        },
        {
          text: this.$t('album.downloaded_month'),
          value: 'datetime',
          sortable: false,
          width: '25%'
        },
        {
          text: this.$t('album.number_of_downloads'),
          sortable: false,
          value: 'count',
          width: '25%'
        }
      ],
      downloadHistory: [],
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.downloadHistory.length / this.itemsPerPage)
    },
  },
  methods: {
    initialize() {
      this.showLoading({ show: true })
      this.$cognito.isAuthenticated().then(async (auth) => {
        this.user = auth
        this.$axiosM
          .post('/download/datelist', {
            company_id: this.user.companyId,
          },
          {
            headers: {
              Authorization: auth.idToken,
            },
          })
          .then(async (response) => {
            if (response.status === 200) {
              this.downloadHistory = this.makeData(response.data)
            } else {
              this.showSnack({
                text: this.$t('alert.error_get_data_failed'),
                isMobile: true,
                color: 'error',
                timeout: 6000,
              })
            }

            this.showLoading({ show: false })
          })
          .catch((error) => {
            if (error.response.status === 500) {
              //redirect to page error 500
              this.$router.replace('/500')
              return
            }
            this.showLoading({ show: false })
          })
      })
    },
    formatDate(date) {
      return dayjs(date).format(this.$t('datetime.YYYY-MM'));
    },

    makeData(data) {
      data.sort((a, b) => b.timestamp - a.timestamp)
      const groupedData = data.reduce((acc, item) => {
        const userId = item.userId
        const monthYear = dayjs(item.timestamp).format(this.$constants.monthFormat)
        const key = `${userId}-${monthYear}`
        const parentName = item.supervisors && item.supervisors.userId ===  userId ? item.supervisors.name : ''

        if (!acc[key]) {
          acc[key] = {
            userID: userId,
            datetime: monthYear,
            name: parentName,
            count: 0,
          }
        }

        acc[key].name = acc[key].name || parentName
        acc[key].count += 1
        return acc
      }, {})

      return Object.values(groupedData)
    },
  },
}
</script>
