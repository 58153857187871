<template>
  <v-btn
    v-bind="$attrs"
    @click="click"
    dark
    rounded
    :style="{ fontSize: textFontSize }"
    depressed
  >
    <slot />
  </v-btn>
</template>
<style scoped></style>
<script>
export default {
  props: {
    textFontSize: {
      default: '16px'
    }
  },
  methods: {
    click(e) {
      this.$emit('click', e)
    },
  },
}
</script>
