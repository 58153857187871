<template>
  <div class="member-list">
    <v-row justify="center" class="mx-0 navigation-fixed">
      <v-col class="px-0">
        <v-card
          flat
          class="rounded-0"
          style="height: 68px; border-bottom: 2px solid #d0d0d0"
        >
          <v-card-title class="d-flex align-center justify-space-between">
            <v-btn
              :class="{ 'disable-events': memberList.length === 0 }"
              color="#6170E8"
              text
              @click="edit"
              style="font-size: 16px; padding-left: 14px"
            >
              {{ $t('member_info.edit') }}
            </v-btn>
            <h2
              class="d-block"
              style="font-size: 16px; font-weight: 700; color: #333333"
            >
              {{ $t('line_connect.list_child_line_connect') }}
            </h2>
            <v-btn
              class="link-action"
              color="#6170E8"
              text
              @click="create"
              style="font-size: 16px; padding-right: 0px"
            >
              {{ $t('member_info.regist_new') }}
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="memberList.length > 0" style="margin-top: 78px">
      <v-row
        v-for="member in memberList"
        :key="member.id"
        align="center"
        class="member-item"
      >
        <v-col cols="8" class="d-flex justify-start align-center">
          <v-avatar size="48px">
            <img
              alt="Avatar"
              :src="
                member.image
                  ? `data:image/jpeg;base64,${member.image}`
                  : accountIcon
              "
            />
          </v-avatar>
          <span
            style="
              font-size: 16px;
              font-weight: 700;
              color: #333333;
              margin-left: 12px;
            "
          >
            {{ member.firstname }} {{ member.lastname }}
          </span>
        </v-col>

        <v-col
          cols="4"
          class="d-flex justify-end align-center"
          style="padding-right: 12px"
        >
          <v-divider class="mr-3" vertical></v-divider>
          <img
            alt="QRcode"
            :src="qrcodeIcon"
            @click="dispQR(member)"
            width="44"
            height="44"
          />
        </v-col>
      </v-row>
    </div>
    <v-row v-else style="margin-top: 98px" justify="center">
      <v-col cols="12">
        <h2 style="font-size: 16px; color: #333333; margin-bottom: 16px">
          {{ $t('line_connect.register_new_child_label') }}
        </h2>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import accountIcon from '@/assets/account-circle-outline.svg'
import qrcodeIcon from '@/assets/qrcode2.svg'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'

export default {
  components: {
    ENJIBtn,
  },
  props: {
    memberList: {
      type: Array,
      required: true,
    },
    user: Object,
  },
  data() {
    return {
      accountIcon: accountIcon,
      qrcodeIcon: qrcodeIcon,
      headers: [
        {
          text: this.$t('member_info.child_name'),
          value: 'name',
          align: 'left',
        },
        { text: 'QR', value: 'qr', align: 'left' },
        {
          text: this.$t('member_info.edit'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    form() {
      this.$emit('form')
    },
    create() {
      this.user.id = ''
      this.user.parent = ''
      this.user.phone = ''
      this.user.lastname = ''
      this.user.firstname = ''
      this.user.kana = ''
      this.user.birthday = ''
      this.user.tags = []
      this.user.supervisors = []
      this.form()
    },
    dispQR(item) {
      this.$emit('qr', item)
    },
    edit() {
      this.$emit('edit')
    },
    done() {
      liff.closeWindow()
    },
  },
}
</script>
<style scoped>
.link-action {
  color: #6170e8;
  font-size: 16px;
  cursor: pointer;
}
.member-list .member-item {
  background: #ffffff;
  margin: 16px;
  border-radius: 6px;
  box-shadow: 0px 0px 6px #00000029;
}
.disable-events {
  pointer-events: none;
  opacity: 0.3;
}
.navigation-fixed {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}
</style>
