<template>
  <div class=".pwr-tog-container">
    <input type="checkbox" :checked="value" @change="change" class="toggle" />
  </div>
</template>
<script>
export default {
  props: ['value'],
  methods: {
    change(e) {
      this.$emit('change', e.target.checked)
    },
  },
}
</script>
<style scoped>
.pwr-tog-container {
  position: relative;
  width: 56px;
  height: 36px;
  box-sizing: border-box;
}

.pwr-tog-icon {
  position: relative;
  top: 0;
  left: 0;
}

/* Toggle switch body */
.toggle {
  display: block;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 56px;
  height: 32px;
  background: #ffffff;
  border-radius: 18px;
  position: relative;
  transform: scale(1);
  transition-property: background;
  transition-duration: 0.15s;
  border: 2px solid #9e9e9e;
  box-sizing: border-box;
}

/* Toggle switch button */
.toggle:before {
  /* BEGIN NEW STUFF */
  display: flex;
  align-items: center;
  justify-content: center;
  /* END NEW STUFF */
  content: '';
  font-size: 1rem;
  background: #9e9e9e;
  height: 24px;
  width: 24px;
  color: '#9E9E9E';
  background: '#9E9E9E';
  position: absolute;
  border-radius: 50%;
  top: calc((28px - 24px) / 2);
  left: calc((32px - 24px) / 2);
  transition: all 0.15s;
  z-index: 2;
}

/* Toggle cwitch checked state */
.toggle:checked {
  background: #8bc34a;
  border: none !important;
}

/* Toggle Switch offset */
.toggle:checked::before {
  top: calc((32px - 24px) / 2);
  left: calc((32px - 24px) / 2 + 56px - 32px);
  font-family: 'Material Icons';
  content: '\e5ca';
  font-size: 1rem;
  font-weight: 700;
  background: #ffffff;
  color: #8bc34a;
  transition: color 0.35s ease;
}
</style>
