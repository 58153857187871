import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"d-flex flex-column",attrs:{"height":"100vh"}},[_c(VCardTitle,{staticClass:"pt-8 px-10 custom-title-head"},[_vm._v(_vm._s(_vm.$t('company.company_tab'))+" ")]),_c(VCardText,{staticClass:"px-10 bt-0"},[_c(VForm,{ref:"companyData",on:{"submit":_vm.onSubmit}},[_c('label',[_c('span',{staticClass:"span-color-line-card"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('master.company')))]),_c(VTextField,{staticClass:"custom-input",staticStyle:{"font-size":"16px"},attrs:{"outlined":"","rules":[
          _vm.required,
          _vm.textRules.regex,
          _vm.companyLengthRule,
          _vm.checkDuplicate,
        ],"dense":"","background-color":"#EEEEEE","height":"44px"},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}}),_c(VCardActions,{staticClass:"text-center d-block"},[_c('ENJIBtn',{staticClass:"px-9 mr-3 btn-font-size-line-card",attrs:{"color":"#333333","outlined":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")]),_c('ENJIBtn',{staticClass:"px-9 ml-3 btn-font-size-line-card",attrs:{"color":"#6170E8"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('button.save'))+" ")])],1)],1)],1),(_vm.company.id)?_c(VCardText,{staticClass:"mt-auto"},[_c(VRow,{staticClass:"mt-4",attrs:{"align":"center"}},[_c(VCol,{staticClass:"d-flex justify-end pr-0"},[_c(VCheckbox,{staticClass:"text-end checkbox-delete",attrs:{"label":_vm.$t('checkbox.delete')},model:{value:(_vm.checkedDelete),callback:function ($$v) {_vm.checkedDelete=$$v},expression:"checkedDelete"}})],1),_c(VCol,[_c('ENJIIconBtn',{class:{ 'disable-events': !_vm.checkedDelete },attrs:{"icon":"mdi-trash-can-outline","color":"#DC313B"},on:{"click":_vm.deleteCompany}},[_vm._v(" "+_vm._s(_vm.$t('member_face.delete')))])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }