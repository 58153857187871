<template>
  <v-card height="100vh" class="d-flex flex-column">
    <v-card-title class="pt-8 px-10 custom-title-head"
      >{{ $t('company.company_tab') }}
    </v-card-title>
    <v-card-text class="px-10 bt-0">
      <v-form ref="companyData" @submit="onSubmit">
        <label
          ><span class="span-color-line-card">*</span
          >{{ $t('master.company') }}</label
        >
        <v-text-field
          v-model="company.name"
          outlined
          :rules="[
            required,
            textRules.regex,
            companyLengthRule,
            checkDuplicate,
          ]"
          dense
          background-color="#EEEEEE"
          style="font-size: 16px"
          height="44px"
          class="custom-input"
        ></v-text-field>
        <v-card-actions class="text-center d-block">
          <ENJIBtn
            @click="close"
            color="#333333"
            outlined
            class="px-9 mr-3 btn-font-size-line-card"
          >
            {{ $t('button.close') }}
          </ENJIBtn>
          <ENJIBtn
            @click="submit"
            color="#6170E8"
            class="px-9 ml-3 btn-font-size-line-card"
          >
            {{ $t('button.save') }}
          </ENJIBtn>
        </v-card-actions>
      </v-form>
    </v-card-text>
    <v-card-text class="mt-auto" v-if="company.id">
      <v-row align="center" class="mt-4">
        <v-col class="d-flex justify-end pr-0">
          <v-checkbox
            class="text-end checkbox-delete"
            v-model="checkedDelete"
            :label="$t('checkbox.delete')"
          ></v-checkbox>
        </v-col>
        <v-col>
          <ENJIIconBtn
            icon="mdi-trash-can-outline"
            color="#DC313B"
            :class="{ 'disable-events': !checkedDelete }"
            @click="deleteCompany"
          >
            {{ $t('member_face.delete') }}</ENJIIconBtn
          >
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import ENJIBtn from './buttons/ENJIBtn.vue'
import ENJIIconBtn from './buttons/ENJIIconBtn.vue'
export default {
  components: { ENJIBtn, ENJIIconBtn },
  props: {
    company: Object,
    companyList: Array,
  },
  data() {
    return {
      checkedDelete: false,
      required: (value) => !!value || this.$t('alert.required_text'),
      companyLengthRule: (value) =>
        value.length <= 25 || this.$t('alert.max_length', { number: 25 }),
      textRules: {
        regex: (v) => /\S/.test(v) || this.$t('alert.required_text'),
      },
    }
  },
  methods: {
    checkDuplicate(value) {
      const companyExists = this.companyList.some(
        (item) => item.name === value.trim()
      )
      return !companyExists || this.$t('company.company_exists')
    },
    close() {
      this.$emit('closeDialog')
    },
    onSubmit(e) {
      e.preventDefault()
    },
    submit() {
      if (this.$refs.companyData.validate()) {
        this.$emit('createCompany', this.company)
      } else {
        this.$vuetify.goTo(0)
      }
    },
    deleteCompany() {
      this.$cognito.isAuthenticated().then(async (auth) => {
        this.$axiosM
          .post(
            '/company/admin/delete',
            {
              id: this.company.id,
            },
            {
              headers: {
                Authorization: auth.idToken,
              },
            }
          )
          .then((_response) => {
            this.loading = false
            this.showLoading({ show: false })
            this.$emit('delete')
          })
          .catch((_error) => {
            this.loading = false
            this.showLoading({ show: false })
            this.showSnack({
              text: this.$t('alert.delete_failed'),
              color: 'error',
              timeout: 6000,
            })
          })
      })
    },
  },
}
</script>

<style>
.text-line-card {
  font-size: 16px;
  color: #161a1a !important;
}

.span-color-line-card {
  color: red;
}
.label-line-card {
  font-size: 14px;
  color: #9e9e9e;
}
.btn-font-size-line-card {
  font-size: 16px !important;
  height: 44px !important;
}
.custom-textarea .v-input__control fieldset {
  border: none !important;
}
</style>
