import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"height":"100vh"}},[_c(VCardTitle,{staticClass:"pt-8 px-10 custom-title-head"},[_vm._v(_vm._s(_vm.$t('line_connect.line_sending'))+" ")]),_c(VCardText,{staticClass:"px-10 pb-0 text-line-card"},[_vm._v(_vm._s(_vm.$t('line_connect.sure_want_to_send')))]),_c(VCardText,{staticClass:"px-10 py-4 text-line-card"},[_vm._v(_vm._s(_vm.$t('line_connect.mr', { name: _vm.formatSupervisorNames(_vm.lineData.supervisors), })))]),_c(VCardText,{staticClass:"px-10 text-line-card confirm-textarea"},[_vm._v(_vm._s(_vm.messageConfirm))]),_c(VCardText,{staticClass:"px-10 bt-0"},[_c(VForm,[_c(VCardActions,{staticClass:"text-center d-block"},[_c('ENJIBtn',{staticClass:"px-9 mr-3 btn-font-size-line-card",attrs:{"color":"#333333","outlined":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('button.return_to_input'))+" ")]),_c('ENJIBtn',{staticClass:"px-9 ml-3 btn-font-size-line-card",attrs:{"color":"#6170E8"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('member_contact.send'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }