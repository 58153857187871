<template>
  <div style="height: 100%">
    <v-card style="min-height: 100%; height: 100%">
      <div style="height: 100%">
        <v-card-title class="pb-10" style="padding-left: 20px;">
          {{ $t('line_setting.title') }}
        </v-card-title>
        <v-form ref="companyData" style="padding-left: 16px;">
          <v-row>
            <v-col cols="3" class="py-0"
              ><v-subheader class="custom-text pt-3">{{
                $t('line_setting.label_notify')
              }}</v-subheader>
            </v-col>
            <v-col cols="6" class="py-0">
              <div class="d-flex align-center pb-2">
                OFF<v-switch
                  v-model="companyData.notify_flg"
                  inset
                  class="ml-2"
                ></v-switch
                >ON
              </div></v-col
            >
          </v-row>
          <v-row class="m-0">
            <v-col cols="3" class="py-0">
              <v-subheader class="custom-text"
                >{{ $t('line_setting.school_name') }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                outlined
                :rules="[required]"
                v-model="companyData.name"
                class="company-name"
              >
                <template v-slot:append-outer>
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        icon
                        v-on="on"
                        @click.stop="copyCompanyId(companyData.id)"
                      >
                        <v-icon color="grey lighten-1">
                          mdi-content-copy
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('line_setting.copy') }}</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <v-row class="m-0">
            <v-col cols="3" class="py-0">
              <v-subheader class="custom-text"
                >{{ $t('line_setting.liff_id') }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                outlined
                :rules="[required]"
                v-model="companyData.liff_id"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="3" class="py-0">
              <v-subheader class="custom-text"
                >{{ $t('line_setting.setting_richmenu') }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="py-0 d-flex">
              <v-select
                v-model="selectedRichMenuType"
                :items="richMenuList"
                item-text="name"
                item-value="type"
                label="RichMenu"
                dense
                outlined
              ></v-select>
              <ENJIBtn
                height="40"
                @click="openRichMenuDialog"
                color="#6170E8"
                style="border-radius: 0; padding: 0 18px"
                :class="{'disable-events': !companyData.liff_id}"
              >
                +
              </ENJIBtn>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="3" class="py-0"> </v-col>
            <v-col cols="6" class="py-0">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    dense
                    outlined
                    disabled
                    :label="$t('line_setting.supervisor_richmenu')"
                    v-model="companyData.default_richmenu"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    dense
                    outlined
                    disabled
                    :label="$t('line_setting.staff_richmenu')"
                    v-model="companyData.admin_richmenu"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="3" class="py-0">
              <v-subheader class="custom-text"
                >{{ $t('line_setting.select_admin') }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-select
                outlined
                :items="usersList"
                :label="$t('line_setting.select_admin')"
                v-model="companyData.line_user_id"
                dense
                item-value="userId"
                item-text="name"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="3" class="py-0">
              <v-subheader class="custom-text"
                >{{ $t('line_setting.channel_secret') }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-text-field
                outlined
                :rules="[required]"
                v-model="companyData.channel_secret"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="3" class="py-0">
              <v-subheader class="custom-text">{{
                $t('line_setting.channel_access_token')
              }}</v-subheader>
            </v-col>
            <v-col cols="6" class="py-0">
              <v-textarea
                v-model="companyData.channel_access_token"
                outlined
                :rules="[required]"
                :rows="4"
                clearable
              />
            </v-col>
          </v-row>

          <!-- action -->
          <v-card-actions class="text-center d-block my-3 pb-5">
            <ENJIBtn
              @click="$router.push('/manager/member/list')"
              color="#333333"
              outlined
              class="px-9 mr-3 btn-font-size-line-card"
            >
              {{ $t('member_info.back') }}
            </ENJIBtn>
            <ENJIBtn
              @click="submit"
              color="#6170E8"
              class="px-9 ml-3 btn-font-size-line-card"
            >
              {{ $t('button.save') }}
            </ENJIBtn>
          </v-card-actions>
        </v-form>
      </div>
    </v-card>
    <div v-if="supervisorRichMenuDialog">
      <v-dialog
        persistent
        v-model="supervisorRichMenuDialog"
        width="600px"
        height="100%"
        :transition="dialogTransition"
        content-class="custom-dialog-content"
      >
        <SupervisorRichMenuForm
          :companyData="companyData"
          @setRichMenu="setRichMenu"
          @clickClose="closeSupervisorRichMenuDialog"
        ></SupervisorRichMenuForm>
      </v-dialog>
    </div>
    <div v-if="staffRichMenuDialog">
      <StaffRichMenuForm
        :companyData="companyData"
        @setRichMenu="setRichMenu"
        @clickClose="closeStaffRichMenuDialog"
      ></StaffRichMenuForm>
    </div>
  </div>
</template>

<script>
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import SupervisorRichMenuForm from '@/components/richmenu/SupervisorForm.vue'
import StaffRichMenuForm from '@/components/richmenu/StaffForm.vue'

export default {
  components: {
    ENJIBtn,
    SupervisorRichMenuForm,
    StaffRichMenuForm,
  },
  data() {
    return {
      selectedRichMenuType: 0,
      richMenuList: [
        {
          type: this.$constants.richMenuType.supervisor,
          name: this.$t('line_setting.supervisor_richmenu'),
        },
        {
          type: this.$constants.richMenuType.staff,
          name: this.$t('line_setting.staff_richmenu'),
        },
      ],
      supervisorRichMenuDialog: false,
      staffRichMenuDialog: false,
      companyData: {},
      required: (value) => !!value || this.$t('alert.required_text'),
      recordId: '',
      dialogTransition: 'fade-transition-right',
      usersList: [],
    }
  },
  created() {
    this.getCompanyInfo()
    this.getUsersList()
  },
  methods: {
    getCompanyInfo() {
      this.showLoading({ show: true })
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          this.$axiosM
            .post(
              '/company/info',
              {
                id: auth.companyId,
              },
              {
                headers: {
                  Authorization: auth.idToken,
                },
              }
            )
            .then((response) => {
              this.showLoading({ show: false })
              if (response.data !== null) {
                this.companyData = response.data
                if (this.companyData.notify_flg === undefined) {
                  this.companyData.notify_flg = false
                }
              }
            })
            .catch((error) => {
              this.showLoading({ show: false })
              if (error.response.status === 500) {
                //redirect to page error 500
                this.$router.replace('/500')
                return
              }
              this.showSnack({
                text: this.$t('alert.error_get_data_failed'),
                color: 'error',
                timeout: 6000,
              })
            })
        })
        .catch((error) => {
          console.error(error)
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.login_again'),
            color: 'error',
            timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })
    },
    submit() {
      if (this.$refs.companyData.validate()) {
        this.showLoading({ show: true })
        this.$cognito.isAuthenticated().then(async (auth) => {
          this.recordId = auth.companyId ? auth.companyId : ''
          const liffID = this.companyData.liff_id.trim()
          this.$axiosM
            .post(
              '/company/update',
              {
                id: this.recordId,
                name: this.companyData.name.trim(),
                liff_channel_id: liffID.includes('-')
                  ? liffID.split('-')[0]
                  : '',
                liff_id: liffID,
                liff_url: `${this.$constants.lineLiffPreUrl}${liffID}`,
                default_richmenu: this.companyData.default_richmenu? this.companyData.default_richmenu.trim() : '',
                admin_richmenu: this.companyData.admin_richmenu? this.companyData.admin_richmenu.trim() : '',
                line_user_id: this.companyData.line_user_id
                  ? this.companyData.line_user_id.trim()
                  : '',
                channel_secret: this.companyData.channel_secret.trim(),
                channel_access_token:
                  this.companyData.channel_access_token.trim(),
                notify_flg: this.companyData.notify_flg,
              },
              {
                headers: {
                  Authorization: auth.idToken,
                },
              }
            )
            .then((response) => {
              this.showLoading({ show: false })
              this.companyData = response.data
              this.recordId = response.data.id
              this.showSnack({
                text: this.$t('alert.update_success'),
                timeout: 6000,
              })
            })
            .catch((_error) => {
              this.loading = false
              this.showLoading({ show: false })
              this.showSnack({
                text: this.$t('alert.update_failed'),
                timeout: 6000,
              })
            })
        })
      } else {
        this.$vuetify.goTo(0)
      }
    },
    getUsersList() {
      this.showLoading({ show: true })
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          this.$axiosM
            .post(
              '/user/list',
              { company_id: auth.companyId },
              {
                headers: {
                  Authorization: auth.idToken,
                },
              }
            )
            .then((response) => {
              this.showLoading({ show: false })
              this.usersList = response.data.filter(item => {
                return !!item.userId
              })
            })
            .catch((error) => {
              console.log(error)
              this.showLoading({ show: false })
              if (error.response.status === 500) {
                //redirect to page error 500
                this.$router.replace('/500')
                return
              }
              this.showSnack({
                text: this.$t('alert.error_get_data_failed'),
                color: 'error',
                timeout: 6000,
              })
            })
        })
        .catch((error) => {
          console.error(error)
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.login_again'),
            color: 'error',
            timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })

    },
    openRichMenuDialog() {
      switch (this.selectedRichMenuType) {
        case this.$constants.richMenuType.supervisor:
          this.supervisorRichMenuDialog = true
          break
        case this.$constants.richMenuType.staff:
          this.staffRichMenuDialog = true
          break
      }
      this.richMenuDialog = true
    },
    closeSupervisorRichMenuDialog() {
      this.supervisorRichMenuDialog = false
    },
    closeStaffRichMenuDialog() {
      this.staffRichMenuDialog = false
    },
    setRichMenu(type, richMenuId) {
      switch (type) {
        case this.$constants.richMenuType.supervisor:
          this.companyData.default_richmenu = richMenuId
          break
        case this.$constants.richMenuType.staff:
          this.companyData.admin_richmenu = richMenuId
          break
      }
    },
    copyCompanyId(id) {
      navigator.clipboard
        .writeText(id)
        .then(() => {})
        .catch((err) => {
          console.error('Failed to copy text: ', err)
        })
    },
  },
}
</script>
<style scoped>
::v-deep(.v-text-field--outlined > .v-input__control > .v-input__slot) {
  min-height: 40px;
}
::v-deep(.v-input--switch .v-input--selection-controls__input) {
  margin-right: 0;
}
::v-deep(.v-input.v-select > .v-input__control) {
  border-radius: 5px 0 0 5px;
}

::v-deep(.v-messages__wrapper > .v-messages__message) {
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
}
</style>

<style>
/* custom style input outer */
.company-name .v-input__append-outer {
  margin-top: 0 !important;
}
</style>
