import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"container-upload pa-0 main mx-auto elevation-0",attrs:{"variant":"tonal"}},[_c('div',[_c(VCardTitle,{staticClass:"border-bottom mb-2 custom-font-title"},[_vm._v(" "+_vm._s(_vm.$t('navbar.menu_media'))+" ")]),_c('hr',{staticStyle:{"border-top":"1px solid #d0d0d0"},attrs:{"size":"2"}}),_c(VRow,{staticClass:"ma-0 pb-6 px-6",staticStyle:{"height":"100%"}},[_c(VCol,{staticClass:"pb-0 bg-white",attrs:{"cols":"12"}},[_c(VCardText,{staticClass:"text-album"},[_vm._v(_vm._s(_vm.$t('album.please_select_album')))]),_c('div',{staticClass:"d-flex"},[_c(VSelect,{ref:"albumSelected",staticStyle:{"height":"40px","width":"50px","line-height":"24px"},attrs:{"items":_vm.albums,"item-text":"name","item-value":"id","outlined":"","dense":"","error":!!_vm.requiredSelectAlbumErrorMessages,"error-messages":_vm.requiredSelectAlbumErrorMessages},on:{"change":_vm.handleAlbumChange},model:{value:(_vm.albumSelected),callback:function ($$v) {_vm.albumSelected=$$v},expression:"albumSelected"}}),_c('ENJIBtn',{staticClass:"ml-3 px-2 py-1",class:{
              'disable-button': _vm.albumSelected === '' || _vm.albumSelected == null,
            },attrs:{"color":"#333333","outlined":""},on:{"click":_vm.checkImage}},[_vm._v(" "+_vm._s(_vm.$t('album.check_img'))+" ")])],1),_c(VCardText,{staticClass:"text-album py-2 pl-0"},[_c(VBtn,{staticClass:"link-action pl-0",attrs:{"text":""},on:{"click":_vm.createNewAlbum}},[_vm._v(" "+_vm._s(_vm.$t('album.add_new_album'))+" ")])],1),_c(VCardText,{staticClass:"text-album pl-0"},[_vm._v(" "+_vm._s(_vm.$t('album.please_select_img'))+" ")]),_c('div',{on:{"click":function($event){return _vm.checkSelectedAlbum()}}},[_c('file-pond',{ref:"pond",attrs:{"disabled":_vm.albumSelected === '',"name":"filepond","label-idle":_vm.$t('album.tap_to_upload_img'),"allow-multiple":true,"accepted-file-types":"image/*,video/*","server":_vm.createServerConfig()},on:{"processfiles":_vm.handleUploadedFile},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1),(_vm.msgErr !== '')?_c('div',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.msgErr))]):_vm._e(),(_vm.uploadedFiles.length > 0)?_c('div',[_c(VCardText,[_vm._v(_vm._s(_vm.$t('album.uploaded_file')))]),_c(VContainer,{staticClass:"px-6"},[_c(VRow,_vm._l((_vm.uploadedFiles),function(file,index){return _c(VCol,{key:`${file.id}-${file.errorCount}`,staticClass:"pb-3",attrs:{"cols":"6","md":"3"}},[_c('div',[_c(VImg,{staticClass:"mt-2 img-preview",attrs:{"src":file.url},on:{"error":function($event){return _vm.onImgError(index)},"click":function($event){return _vm.showMedia(file)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[(
                            file.isShowLoading ||
                            file.isShowLoading === undefined
                          )?_c(VProgressLinear,{staticClass:"progress-media",attrs:{"color":"#6170e8","indeterminate":"","rounded":"","height":"6"}}):_vm._e()],1)]},proxy:true}],null,true)},[_c(VBtn,{staticClass:"float-right",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteFile(file)}}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v("mdi-close-circle-outline ")])],1),(file.type === 'video')?_c(VIcon,{staticClass:"icon-play-button",attrs:{"size":"50","color":"black"}},[_vm._v("mdi-play-circle-outline")]):_vm._e()],1)],1)])}),1)],1)],1):_vm._e()],1)],1)],1),_c('div',[(_vm.dialogShowMedia)?_c(VDialog,{attrs:{"persistent":"","width":"880","scrollable":""},model:{value:(_vm.dialogShowMedia),callback:function ($$v) {_vm.dialogShowMedia=$$v},expression:"dialogShowMedia"}},[_c('DisplayMedia',{attrs:{"mediaItem":_vm.mediaItem,"isPc":true,"width":"auto"},on:{"clickCloseDisplayMedia":_vm.closeDisplayMedia}})],1):_vm._e()],1),(_vm.showAblbumForm)?_c('div',[_c(VDialog,{attrs:{"persistent":"","width":"600px","height":"100vh","transition":_vm.dialogTransition,"content-class":"custom-dialog-content"},model:{value:(_vm.showAblbumForm),callback:function ($$v) {_vm.showAblbumForm=$$v},expression:"showAblbumForm"}},[_c('AlbumFormM',{ref:"btnCreateAlbum",staticClass:"bg-white",attrs:{"albums":_vm.albums,"companyId":_vm.user.companyId,"newAlbumData":_vm.newAlbumData},on:{"closeAlbumForm":_vm.close,"createdAlbum":_vm.createdAlbum}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }