import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"member-list"},[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,[_c(VCard,{staticClass:"rounded-0",staticStyle:{"height":"68px"},attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"d-block text-center"},[_c('h2',{staticClass:"d-block",staticStyle:{"font-size":"16px","color":"#333333"}},[_vm._v(" "+_vm._s(_vm.$t('line_connect.child_list'))+" ")])])],1)],1)],1),(_vm.memberList.length > 0)?_c('div',_vm._l((_vm.memberList),function(member,memberIndex){return _c(VRow,{key:member.id,staticClass:"member-item",attrs:{"align":"center"}},[_c(VCol,{staticClass:"d-flex justify-start align-center",attrs:{"cols":"8"}},[_c(VAvatar,{attrs:{"size":"48px"}},[_c('img',{attrs:{"alt":"Avatar","src":member.image
                ? `data:image/jpeg;base64,${member.image}`
                : _vm.accountIcon}})]),_c('span',{staticStyle:{"font-size":"16px","font-weight":"700","color":"#333333","margin-left":"12px"}},[_vm._v(" "+_vm._s(member.firstname)+" "+_vm._s(member.lastname)+" ")])],1),_c(VCol,{staticClass:"d-flex justify-end",staticStyle:{"padding-right":"20px"},attrs:{"cols":"4"}},[(_vm.indexOfSupervisor(member.supervisors) !== -1)?_c('SwitchBtn',{attrs:{"value":member.supervisors[_vm.indexOfSupervisor(member.supervisors)]
              .connected},on:{"change":function($event){_vm.handleSwitchClick(
              memberIndex,
              _vm.indexOfSupervisor(member.supervisors)
            )}}}):_vm._e()],1)],1)}),1):_c(VRow,{staticStyle:{"margin-top":"28px"},attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[_c('h2',{staticStyle:{"font-size":"16px","color":"#333333","margin-bottom":"16px"}},[_vm._v(" "+_vm._s(_vm.$t('line_connect.register_new_child_label'))+" ")]),_c('ENJIBtn',{attrs:{"height":"44","color":"#6170E8"},on:{"click":function($event){return _vm.$router.push('/member/info')}}},[_vm._v(_vm._s(_vm.$t('line_connect.register_new_child')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }