<template>
  <v-card>
    <v-navigation-drawer app permanent v-if="!isPc" class="bg-white" width="50">
      <div class="d-flex justify-center mt-2">
        <v-app-bar-nav-icon @click="toggleDrawer"></v-app-bar-nav-icon>
      </div>
    </v-navigation-drawer>

    <v-navigation-drawer v-model="drawer" width="300" app>
      <v-list-item>
        <v-list-item-content class="title-top-container">
          <v-list-item-title class="main-color title-h d-flex align-center">
            <img :src="logo" alt="Logo" class="mr-1" />
            {{ $t('navbar.title_top') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list>
        <v-list-item
          class="selected-menu"
          v-for="item in menuTops"
          :key="item.title"
          link
          @click="openNewWindow(item)"
        >
          <v-list-item-content class="item-text-container">
            <v-list-item-title class="item-text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="item.btnOpen">
            <v-btn icon width="24" height="24">
              <img :src="redirect" />
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list class="bottom-menu-container">
          <v-list-item link>
            <v-list-item-icon class="mr-2">
              <img width="24" height="24" :src="accountIcon" />
            </v-list-item-icon>
            <v-list-item-content class="item-text-container">
              <v-list-item-title class="item-text">{{
                authName
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="signOut">
            <v-list-item-icon class="mr-2">
              <img width="24" height="24" :src="logout" />
            </v-list-item-icon>
            <v-list-item-content class="item-text-container">
              <v-list-item-title class="item-text">{{
                $t('navbar.logout')
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import logo from '@/assets/logo-24x24.png'
import accountIcon from '@/assets/account-circle-outline.svg'
import redirect from '@/assets/redirect.svg'
import logout from '@/assets/logout.svg'
export default {
  props: ['menuTops', 'menuBottoms'],
  data() {
    return {
      logo,
      accountIcon: accountIcon,
      redirect: redirect,
      logout: logout,
      authName: '',
      screenWidth: window.innerWidth,
      drawer: this.isPc,
    }
  },
  created() {
    this.isAuthenticated()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize) // Listen for resize events
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize) // Remove the event listener when the component is destroyed
  },
  computed: {
    isPc() {
      return this.screenWidth >= 1265 // px for screen pc
    },
  },
  methods: {
    isAuthenticated() {
      this.$cognito
        .isAuthenticated()
        .then((auth) => {
          this.authName = auth.name
        })
        .catch((_session) => {
          this.$router.replace('/manager/signin')
        })
    },
    signOut() {
      this.$cognito.logout()
      this.$router.replace('/manager/signin')
    },
    openNewWindow(item) {
      if (item.btnOpen) {
        let params = `width=${screen.width}, height=${screen.height}, top=0, left=0, fullscreen=yes, directories=no, location=no, menubar=no, resizable=no, scrollbars=no, status=no, toolbar=no`
        window.open(item.path, item.path, params)
        return
      }
      if (this.$route.path != item.path) {
        this.$router.push(item.path)
      }
    },
    toggleDrawer() {
      this.showTagSearchResult({ show: false })
      this.drawer = !this.drawer
    },
    handleResize() {
      this.screenWidth = window.innerWidth // Update screenWidth when the window is resized
    },
  },
}
</script>

<style scoped>
.title-top-container {
  padding: 24px 14px;
}
.title-h {
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  font-weight: 700;
  font-size: 24px;
  padding-bottom: 3px;
}
.item-text-container {
  min-height: 56px;
}
.item-text {
  font-size: 16px;
  font-weight: 500;
}
.selected-menu {
  padding-left: 30px;
}
.selected-menu:hover {
  background: #ecedfa;
  color: #6170e8;
}
.v-list .v-list-item--active {
  color: #6170e8;
  font-weight: 500;
}
.v-list .v-list-item--active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 5px;
  background-color: #6170e8;
}
.theme--light.v-navigation-drawer {
  border-radius: 0 0 60px 0 !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 3px 0px 6px #0000001a;
  opacity: 1;
}
.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #161a1a;
}
.bottom-menu-container {
  padding: 0px;
  margin-bottom: 24px;
}

.bg-white {
  background-color: white !important;
}
</style>
