<template>
  <div id="scannerM">
    <div class="d-flex justify-center">
      <v-card class="pa-10">
        <v-card-text class="text-center py-0 title font-weight-bold">{{
          TITLE
        }}</v-card-text>
        <v-card-text v-if="showSwitchCameraBtn" class="text-center py-6">
          <ENJIIconBtn color="#6170E8" @click="flipCamera" height="40">
            <img width="20" height="20" :src="cameraSwitchIcon" />
            {{ $t('attendance.switch_camera') }}
          </ENJIIconBtn>
        </v-card-text>
        <div v-else class="text-center py-5"></div>

        <v-card-text class="d-flex justify-center pa-0">
          <div
            style="position: relative; height: 540px; border-radius: 12px"
            class="overflow-hidden"
          >
            <video
              ref="video"
              width="1000"
              height="540"
              autoplay
              playsInline
              muted
              class="video-object-cover"
              :style="videoStyle"
            ></video>
            <canvas ref="canvas" v-show="false"></canvas>
            <div class="camera-eye"></div>
          </div>
        </v-card-text>
        <div class="d-flex justify-center mt-4">
          <ENJIBtn
            height="80"
            @click="toggle"
            :color="isOn ? 'red' : '#6170E8'"
            :text-font-size="'36px'"
            style="padding: 10px 44px 10px 44px"
          >
            {{
              isOn ? $t('attendance.pc_stop_verifying') : $t('attendance.pc_start')
            }}
          </ENJIBtn>
        </div>
      </v-card>
      <div>
        <v-dialog v-model="typeDialog" width="400px" persistent>
          <template>
            <div>
              <v-card>
                <v-card-text class="text-center">{{
                  $t('member_check.choose_type')
                }}</v-card-text>
                <v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="info"
                      style="width: 110px"
                      @click="selQR"
                      x-large
                    >
                      <v-icon>mdi-qrcode</v-icon
                      >{{ $t('member_check.qr_auth') }}
                    </v-btn>
                    <v-spacer style="max-width: 30px"></v-spacer>
                    <v-btn
                      color="info"
                      style="width: 110px"
                      x-large
                      @click="selFace"
                    >
                      <v-icon>mdi-face-recognition</v-icon
                      >{{ $t('member_check.face_reg') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </div>
          </template>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<style scoped>
::v-deep(.v-dialog) {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  position: absolute !important;
}
.v-dialog__content {
  position: static !important;
}
#scannerM .theme--light.v-card > .v-card__text.title {
  color: #333333;
  font: normal normal bold 20px/26px Noto Sans JP;
  letter-spacing: 0px;
}
.camera-eye {
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 270px;
  height: 320px;
  box-shadow: 0 0 0 50pc #00000066;
  border-radius: 50%;
}
.video-object-cover {
  object-fit: cover;
}
</style>
<script>
import jsQR from 'jsqr'
import cameraSwitchIcon from '@/assets/camera-switch.svg'
import ENJIIconBtn from '@/components/buttons/ENJIIconBtn'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'

export default {
  components: {
    ENJIIconBtn,
    ENJIBtn,
  },
  data() {
    return {
      intervalId: null,
      showSwitchCameraBtn: false,
      cameraSwitchIcon: cameraSwitchIcon,
      MODEL_URI: '/js/face-api.js/weights',
      video: {},
      canvas: {},
      hitFace: 0,
      qrCode: '',
      cameraWait: true,
      dialog: false,
      regist: false,
      loading: false,
      typeDialog: false,
      selType: '0',
      cameraMode: '',
      pauseFlg: false,
      checkMsg: '',
      videoStyle: {
        transform: 'scaleX(-1)',
      },
      isOn: false,
    }
  },
  props: {
    leave: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    TITLE: {
      get() {
        let title = this.$t('attendance.enter_mode')
        this.checkMsg = this.$t('attendance.checked_in')
        if (this.$route.params.type === this.$constants.checkInMode.out) {
          title = this.$t('attendance.leave_mode')
          this.checkMsg = this.$t('attendance.checked_out')
        }
        return title
      },
    },
  },
  mounted() {
    this.cameraMode = this.$constants.cameraMode.front
    Promise.all([
      this.checkCamera(),
      faceapi.nets.tinyFaceDetector.loadFromUri(this.MODEL_URI),
    ]).then(() => {
      // ビデオのリアルタイムキャプチャを canvas に送る
      this.video = this.$refs.video
      this.canvas = this.$refs.canvas

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({
            video: {
              facingMode: this.cameraMode,
            },
          })
          .then((stream) => {
            if (this.leave) {
              stream.getVideoTracks().forEach((track) => {
                track.stop()
              })
            } else {
              this.cameraWait = false
              this.video.srcObject = stream
              this.video.play()
            }
          })
          .catch((_error) => {
            this.cameraWait = true
          })
      }
    })
  },
  methods: {
    async checkCamera() {
      try {
        var hasBackCamera = false
        var hasFrontCamera = false
        await navigator.mediaDevices.getUserMedia({
          video: true,
        })
        const devices = await navigator.mediaDevices.enumerateDevices()
        devices.forEach((device) => {
          if (device.getCapabilities) {
            if (device.getCapabilities()?.facingMode?.includes('environment')) {
              hasBackCamera = true
            }
            if (device.getCapabilities()?.facingMode?.includes('user')) {
              hasFrontCamera = true
            }
          }
        })

        if (hasBackCamera && hasFrontCamera) {
          this.showSwitchCameraBtn = true
        }
      } catch (error) {
        console.log(error)
      }
    },
    flipCamera() {
      // フロント・バックカメラ切り替え
      if (this.cameraMode === this.$constants.cameraMode.front) {
        this.cameraMode = this.$constants.cameraMode.back
        this.videoStyle.transform = 'scaleX(1)'
      } else {
        this.cameraMode = this.$constants.cameraMode.front
        this.videoStyle.transform = 'scaleX(-1)'
      }
      // カメラ停止
      this.pauseCamera()
      // カメラ再設定
      this.resumeCamera()
    },
    onClose() {
      this.dialog = false
      this.video.play()
    },
    pauseCamera() {
      // カメラ停止
      this.pauseFlg = true
      this.video.srcObject.getVideoTracks().forEach((track) => {
        track.stop()
      })
    },
    resumeCamera() {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: this.cameraMode,
          },
        })
        .then((stream) => {
          this.video.srcObject = stream
          this.pauseFlg = false
        })
    },
    onRegist() {
      // カメラ停止
      this.video.srcObject.getVideoTracks().forEach((track) => {
        track.stop()
      })
      this.dialog = false
      this.regist = true
    },
    selQR() {
      this.selType = this.$constants.checkInType.qr
      this.typeDialog = false
    },
    selFace() {
      this.selType = this.$constants.checkInType.face
      this.typeDialog = false
    },
    toggle() {
      if (!this.isOn) {
        this.checkImage()
      } else {
        clearInterval(this.intervalId)
      }
      this.isOn = !this.isOn
    },
    async checkImage() {
      // const barcodeDetector = new BarcodeDetector()
      // const faceDetector = new FaceDetector()
      const video = this.video
      const ctx = this.canvas.getContext('2d', { willReadFrequently: true })

      this.canvas.width = 320
      this.canvas.height = 240
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
      const me = this

      try {
        this.intervalId = setInterval(async () => {
          if (this.pauseFlg || this.loading) {
            return
          }
          this.loading = true
          const detections = await faceapi.detectAllFaces(
            video,
            new faceapi.TinyFaceDetectorOptions()
          )
          if (detections.length > 0) {
            const { score } = detections[0]
            if (score >= 0.5) {
              const faceImages = await faceapi.extractFaces(video, detections)
              me.hitFace = faceImages.length
              this.$cognito
                .isAuthenticated()
                .then((auth) => {
                  this.checkinFace(faceImages, auth)
                })
                .catch((_session) => {
                  this.loading = false
                  this.showSnack({
                    text: this.$t('alert.login_again'),
                    color: 'error',
                    timeout: 2000,
                  })
                  this.$router.replace('/manager/signin')
                })

            }
          } else {
            me.hitFace = 0
          }
          ctx.drawImage(video, 0, 0, 320, 240)
          const imageData = ctx.getImageData(
            0,
            0,
            me.canvas.width,
            me.canvas.height
          )
          const code = jsQR(imageData.data, imageData.width, imageData.height)
          if (code) {
            me.qrCode = code.data
            me.checkinQR(code.data)
          }
          if (!code && me.hitFace === 0) {
            this.loading = false
          }
        }, 1000)
        this.$emit('interval', this.intervalId)
        // setInterval(function () {
        //   if (me.selType != me.$constants.checkInType.qr) return
        //   if (me.resultDialog) return
        //   if (me.typeDialog) return
        //   if (me.loading) return
        //   barcodeDetector
        //     .detect(video)
        //     .then(function (barcodes) {
        //       let barcode = null
        //       for (barcode of barcodes) {
        //         console.log(barcode.rawValue)
        //         me.loading = true
        //         me.showLoading({ show: true })

        //         me.$cognito
        //           .isAuthenticated()
        //           .then((session) => {
        //             const idToken = session.idToken.jwtToken

        //             me.$axiosM
        //               .post(
        //                 '/check/in/qr',
        //                 {
        //                   qr: barcode.rawValue,
        //                 },
        //                 {
        //                   headers: {
        //                     Authorization: idToken,
        //                   },
        //                 }
        //               )
        //               .then((response) => {
        //                 me.showLoading({ show: false })
        //                 if (response.data?.message) {
        //                   me.resultDialog = true
        //                   me.resultMessage = response.data.message
        //                 }
        //               })
        //               .catch(() => {
        //                 me.loading = false
        //                 me.showLoading({ show: false })
        //                 me.showSnack({
        //                   text: 'QRコードが不正です',
        //                   color: 'error',
        //                   timeout: 6000,
        //                 })
        //               })
        //           })
        //           .catch((_session) => {
        //             me.loading = false
        //             me.showLoading({ show: false })
        //             me.showSnack({
        //               text: '再度ログインし直してください',
        //               color: 'error',
        //               timeout: 6000,
        //             })
        //             me.$router.replace('/manager/signin')
        //           })
        //       }
        //     })
        //     .catch(function (err) {
        //       console.log(err)
        //     })
        // }, 1000)

        // setInterval(function () {
        //   if (me.selType != me.$constants.checkInType.face) return
        //   if (me.resultDialog) return
        //   if (me.typeDialog) return
        //   if (me.loading) return
        //   faceDetector
        //     .detect(video)
        //     .then(function (barcodes) {
        //       let barcode = null
        //       for (barcode of barcodes) {
        //         const box = barcode.boundingBox
        //         ctx.drawImage(
        //           video,
        //           box.x,
        //           box.y,
        //           box.width,
        //           box.height,
        //           0,
        //           0,
        //           320,
        //           240
        //         )
        //         me.loading = true
        //         const data = me.canvas.toDataURL('image/jpeg').split(',')[1]
        //         me.showLoading({ show: true })

        //         me.$cognito
        //           .isAuthenticated()
        //           .then((session) => {
        //             const idToken = session.idToken.jwtToken

        //             me.$axiosM
        //               .post(
        //                 '/check/in/face',
        //                 {
        //                   inputImage: data,
        //                 },
        //                 {
        //                   headers: {
        //                     Authorization: idToken,
        //                   },
        //                 }
        //               )
        //               .then((response) => {
        //                 me.showLoading({ show: false })
        //                 if (response.data?.message) {
        //                   me.resultDialog = true
        //                   me.resultMessage = response.data.message
        //                 }
        //               })
        //               .catch(() => {
        //                 me.loading = false
        //                 me.showLoading({ show: false })
        //                 me.showSnack({
        //                   text: '顔認証に失敗しました',
        //                   color: 'error',
        //                   timeout: 6000,
        //                 })
        //               })
        //           })
        //           .catch((_session) => {
        //             me.loading = false
        //             me.showLoading({ show: false })
        //             me.showSnack({
        //               text: '再度ログインし直してください',
        //               color: 'error',
        //               timeout: 6000,
        //             })
        //             me.$router.replace('/manager/signin')
        //           })
        //       }
        //     })
        //     .catch(function (err) {
        //       console.log(err)
        //     })
        // }, 5000)
      } catch (e) {
        console.error('Face detection failed:', e)
      }
    },
    checkinQR(val) {
      this.$cognito
        .isAuthenticated()
        .then((auth) => {
          this.$axiosM
            .post(
              `/check/${this.$route.params.type}/qr`,
              {
                qr: val,
                company_id: auth.companyId,
              },
              {
                headers: {
                  Authorization: auth.idToken,
                },
              }
            )
            .then((response) => {
              this.loading = false
              if (response.data?.message) {
                this.showSnack({
                  text: this.checkMsg,
                  timeout: 2000,
                })
              }
            })
            .catch(() => {
              this.loading = false
              this.showSnack({
                text: this.$t('alert.error_processing_failed'),
                timeout: 2000,
              })
            })
        })
        .catch((_session) => {
          this.loading = false
          this.showSnack({
            text: this.$t('alert.login_again'),
            color: 'error',
            timeout: 2000,
          })
          this.$router.replace('/manager/signin')
        })
    },
    checkinFace(faceImages, auth) {
      let apiPromises = []
      faceImages.forEach((cnv) => {
        const data = cnv.toDataURL('image/jpeg').split(',')[1]
        apiPromises.push(this.checkinFaceReq(data, auth))
      })
      Promise.allSettled(apiPromises).then((results) => {
        const isSuccess = results.some(
          (result) =>
            result.status === 'fulfilled' && result.value.data?.message
        )
        if (isSuccess) {
          this.showSnack({
            text: this.checkMsg,
            timeout: 2000,
          })
        } else {
          this.showSnack({
            text: this.$t('alert.error_processing_failed'),
            timeout: 2000,
          })
        }
        this.loading = false
      })
    },
    checkinFaceReq(val, auth) {
      return this.$axiosM.post(
        `/check/${this.$route.params.type}/face`,
        {
          inputImage: val,
          company_id: auth.companyId,
        },
        {
          headers: {
            Authorization: auth.idToken,
          },
        }
      )
    },
  },
}
</script>
