<template>
  <v-btn v-bind="$attrs" @click="click" dark rounded depressed>
    <v-icon class="mr-1" size="20">
      {{ icon }}
    </v-icon>
    <slot />
  </v-btn>
</template>
<style scoped>
.v-btn {
  font-size: 16px;
  letter-spacing: 0px;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 12px;
}
</style>
<script>
export default {
  props: {
    icon: String,
  },
  methods: {
    click(e) {
      this.$emit('click', e)
    },
  },
}
</script>
