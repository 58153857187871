<template>
  <div class="d-flex flex-column overflow-auto">
    <v-row class="ma-0"
      ><v-col cols="5" class="text-left">{{ title }}</v-col>
      <v-col cols="7" class="text-right">
        <ENJIBtn v-if="batchDownloadText" @click="batchDownload" color="#6170E8"
          >{{ batchDownloadText }}
        </ENJIBtn></v-col
      >
    </v-row>

    <v-item-group
      v-model="selectedMediaList"
      multiple
      class="d-flex flex-wrap px-6 overflow-y-auto"
    >
      <v-item
        v-for="(media, index) in mediaList"
        :key="index"
        :value="index"
        v-slot="{ active, toggle }"
      >
        <v-img
          class="mt-4 no-responsive text-right"
          style="
            width: 31.3%;
            aspect-ratio: 78/70;
            border: 1px solid black;
            margin-left: 1%;
            margin-right: 1%;
          "
          :src="media.url"
          @click="openMedia(media)"
        >
          <v-btn
            icon
            @click.stop="toggle"
            width="35"
            height="35"
            style="position: relative"
          >
            <v-icon size="35">
              {{ 'mdi-checkbox-blank-circle-outline' }}
            </v-icon>
            <v-icon
              v-if="active"
              size="35"
              color="green"
              style="position: absolute; bottom: 2px; left: 6px"
            >
              mdi-check-bold
            </v-icon>
          </v-btn>
          <v-icon
            v-if="active === 'video'"
            size="50"
            color="black"
            class="icon-play-button"
            >mdi-play-circle-outline</v-icon
          >
        </v-img></v-item
      >
    </v-item-group>
    <div v-if="isShowDownloadSingleMedia">
      <v-dialog
        v-model="isShowDownloadSingleMedia"
        fullscreen
        persistent
        no-click-animation
        content-class="album-screen d-flex flex-column justify-center align-center"
      >
        <div class="overflow-y-auto">
          <img
            v-if="mediaForDownload.type === 'image'"
            :src="mediaForDownload.url"
            width="100%"
          />
          <video
            v-else-if="mediaForDownload.type === 'video'"
            controls
            :src="mediaForDownload.url"
            width="100%"
            height="270px"
          ></video>
        </div>
        <div class="d-flex justify-center mt-4" style="height: 50px">
          <ENJIBtn
            width="140px"
            @click="closeMedia"
            class="mr-3"
            outlined
            color="#333333"
          >
            {{ $t('member_info.back') }}
          </ENJIBtn>
          <ENJIBtn width="140px" @click="downloadSingleMedia" color="#6170E8"
            >{{ $t('album.download') }}
          </ENJIBtn>
        </div>
      </v-dialog>
    </div>
  </div>
</template>
<style scoped>
::v-deep(.album-screen) {
  background-color: #fff;
}
.mb-40 {
  margin-bottom: 160px;
}
.no-responsive {
  flex: 0 0 auto !important;
}

.icon-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
<script>
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import axios from 'axios'

export default {
  components: { ENJIBtn },

  props: {
    user: Object,
    title: String,
    batchDownloadText: String,
    mediaList: [],
  },
  data() {
    return {
      isShowDownloadSingleMedia: false,
      mediaForDownload: {},
      selectedMediaList: [],
    }
  },
  methods: {
    batchDownload() {
      this.showLoading({ show: true })
      let getPresignedUrlPromises = []
      let downloadPromises = []
      this.selectedMediaList.forEach((index) => {
        let media = this.mediaList[index]
        getPresignedUrlPromises.push(this.getPresignedUrlReq(media))
      })
      Promise.allSettled(getPresignedUrlPromises).then((results) => {
        results.forEach((result, index) => {
          if (result.status === 'fulfilled') {
            let media = this.mediaList[this.selectedMediaList[index]]
            downloadPromises.push(
              this.downloadMediaFromS3Req(result.value.data.url)
            )
            this.$axios
              .post('/download/log', {
                mediaId: media.id,
                company_id: this.user.companyId,
                albumId: media.album_id,
                token: this.user.token,
                type: media.type,
              })
              .then((__response) => {})
              .catch((error) => {
                console.log(error)
              })
          }
        })
        Promise.allSettled(downloadPromises).then((results) => {
          this.showLoading({ show: false })
          results.forEach((result, index) => {
            if (result.status === 'fulfilled') {
              let media = this.mediaList[this.selectedMediaList[index]]
              setTimeout(() => {
                this.downloadMediaFromBlob(result.value.data, media)
              }, index * 100)
            }
          })
        })
      })
    },
    openMedia(media) {
      switch (media.type) {
        case 'image':
          this.mediaForDownload = media
          this.isShowDownloadSingleMedia = true
          break
        case 'video':
          this.showLoading({ show: true })
          this.$axios
            .post('/media/presignedurl', {
              mediaId: media.id,
              company_id: this.user.companyId,
              albumId: media.album_id,
              type: media.type,
            })
            .then((response) => {
              this.mediaForDownload = response.data
              this.isShowDownloadSingleMedia = true
              this.showLoading({ show: false })
            })
            .catch((error) => {
              console.log(error)
              this.showLoading({ show: false })
            })
          break
      }
    },
    closeMedia() {
      this.isShowDownloadSingleMedia = false
    },
    downloadSingleMedia() {
      this.showLoading({ show: true })
      this.getPresignedUrlReq(this.mediaForDownload)
        .then((response) => {
          const media = this.mediaForDownload
          this.$axios
            .post('/download/log', {
              mediaId: media.id,
              company_id: this.user.companyId,
              albumId: media.album_id,
              token: this.user.token,
              type: media.type,
            })
            .then((__response) => {
              this.downloadMediaFromS3Req(response.data.url).then(
                (response) => {
                  this.showLoading({ show: false })
                  this.downloadMediaFromBlob(
                    response.data,
                    this.mediaForDownload
                  )
                }
              )
            })
            .catch((error) => {
              this.showLoading({ show: false })
              console.log(error)
            })
        })
        .catch((error) => {
          this.showLoading({ show: false })
          console.log(error)
          this.showSnack({
            text: this.$t('alert.download_process_failed'),
            isMobile: true,
            color: 'error',
            timeout: 6000,
          })
        })
    },

    getPresignedUrlReq({ id, album_id, type }) {
      return this.$axios.post('/media/presignedurl', {
        mediaId: id,
        company_id: this.user.companyId,
        albumId: album_id,
        type,
      })
    },
    downloadMediaFromS3Req(url) {
      return axios.get(url, {
        responseType: 'blob',
      })
    },
    downloadMediaFromBlob(data, media) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      let fileExtension
      switch (media.type) {
        case 'image':
          fileExtension = 'jpg'
          break
        case 'video':
          fileExtension = 'mp4'
          break
      }
      link.setAttribute('download', `${media.id}.${fileExtension}`)
      document.body.appendChild(link)
      link.addEventListener('click', () => {})
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    },
  },
}
</script>
