import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"mx-auto"},[_c(VContainer,[(_vm.isMobile === false)?_c('div',[_c(VCardTitle,{staticClass:"pt-6 px-10 justify-center custom-title-head"},[_vm._v(_vm._s(_vm.$t('kindergarten_list.qrcode'))+" ")]),_c(VCardText,{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t('member_info.qr_code', { name: _vm.name }))+" ")])],1):(_vm.isMobile)?_c(VCardText,{staticClass:"text-center",staticStyle:{"font-size":"16px","font-weight":"700","color":"#333333","padding-bottom":"8px"}},[_vm._v(" "+_vm._s(_vm.$t('member_info.qr_codeM', { name: _vm.name }))+" ")]):_vm._e(),_c(VCardTitle,{staticClass:"justify-center"},[_c('vue-qrcode',{staticClass:"justify-center",attrs:{"value":_vm.qrData,"options":_vm.option,"tag":"img","width":_vm.isMobile ? 200 : 240,"height":_vm.isMobile ? 200 : 240}})],1)],1),_c(VCardActions,{class:{
      'text-center': true,
      'd-block': true,
      'pb-10': !_vm.isMobile,
      'box-mobile': _vm.isMobile,
    }},[_c('ENJIBtn',{staticClass:"px-9 mr-3 btn-font-size-line-card",attrs:{"color":"#333333","outlined":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")]),_c('ENJIBtn',{staticClass:"px-9 ml-3 btn-font-size-line-card",attrs:{"color":"#6170E8"},on:{"click":_vm.update}},[_vm._v(" "+_vm._s(_vm.$t('member_info.update'))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }