import { render, staticRenderFns } from "./SupervisorForm.vue?vue&type=template&id=319eccf8&scoped=true&"
import script from "./SupervisorForm.vue?vue&type=script&lang=js&"
export * from "./SupervisorForm.vue?vue&type=script&lang=js&"
import style0 from "./SupervisorForm.vue?vue&type=style&index=0&id=319eccf8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "319eccf8",
  null
  
)

export default component.exports