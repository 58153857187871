const getters = {}

const state = {
  show: false,
}

const mutations = {
  SHOW_TAG_SEARCH_RESULT(state, payload) {
    state.show = payload.show
  },
}

const actions = {
  showTagSearchResult({ commit }, payload) {
    commit('SHOW_TAG_SEARCH_RESULT', payload)
  },
}

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
}
