import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VImg } from 'vuetify/lib/components/VImg';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{class:[_vm.isMobile ? '' : 'zero-border-radius-right-side'],staticStyle:{"position":"relative"},attrs:{"height":_vm.isMobile ? '' : '100vh'}},[_c(VCardTitle,{staticClass:"px-10 custom-title-head",class:[{ 'justify-center': _vm.isMobile }, _vm.isMobile ? 'py-4' : 'pt-8']},[_vm._v(_vm._s(_vm.isMobile ? _vm.$t('member_contact.sending_confirmation') : _vm.$t('navbar.menu_group_bulk_message'))+" ")]),_c(VCardText,{staticClass:"px-10 pb-0 text-line-card"},[_vm._v(_vm._s(_vm.$t('line_connect.sure_want_to_send')))]),_c(VCardText,{staticClass:"px-10 py-5 text-line-card confirm-textarea",staticStyle:{"min-height":"144px"},style:({ 'border-bottom': _vm.isMobile ? '2px solid #c9c9c9' : 'none' })},[_vm._v(_vm._s(_vm.messageConfirm))]),_c(VCardText,[_c(VItemGroup,{staticClass:"d-flex flex-wrap px-6 justify-start preview-imgs",attrs:{"multiple":""}},_vm._l((_vm.fileList),function(file,_){return _c(VItem,{key:`${file.id}`},[_c(VImg,{staticClass:"mt-2 img-preview",attrs:{"src":file.url}})],1)}),1)],1),_c(VForm,[_c(VCardActions,{staticClass:"text-center d-block py-4"},[_c('ENJIBtn',{staticClass:"mr-3 btn-font-size-line-card",class:[_vm.isMobile ? 'px-7' : 'px-4'],attrs:{"color":"#333333","outlined":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('button.return_to_input'))+" ")]),_c('ENJIBtn',{staticClass:"ml-3 btn-font-size-line-card",class:[
          _vm.isMobile ? 'px-9' : 'px-6',
          {
            'disable-events':
              this.messageConfirm.trim() === '' && this.fileList.length === 0,
          },
        ],attrs:{"color":"#6170E8"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('member_contact.send'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }