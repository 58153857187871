<template>
  <v-card height="100vh">
    <v-card-title class="pt-8 px-10 custom-title-head"
      >{{ $t('line_connect.line_sending') }}
    </v-card-title>
    <v-card-text class="px-10 pb-0 text-line-card">{{
      $t('line_connect.sure_want_to_send')
    }}</v-card-text>
    <v-card-text class="px-10 py-4 text-line-card">{{
      $t('line_connect.mr', {
        name: formatSupervisorNames(lineData.supervisors),
      })
    }}</v-card-text>
    <v-card-text class="px-10 text-line-card confirm-textarea">{{
      messageConfirm
    }}</v-card-text>
    <v-card-text class="px-10 bt-0">
      <v-form>
        <v-card-actions class="text-center d-block">
          <ENJIBtn
            @click="close"
            color="#333333"
            outlined
            class="px-9 mr-3 btn-font-size-line-card"
          >
            {{ $t('button.return_to_input') }}
          </ENJIBtn>
          <ENJIBtn
            @click="submit"
            color="#6170E8"
            class="px-9 ml-3 btn-font-size-line-card"
          >
            {{ $t('member_contact.send') }}
          </ENJIBtn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import ENJIBtn from './buttons/ENJIBtn.vue'
export default {
  components: { ENJIBtn },
  props: {
    lineData: Object,
    messageConfirm: String,
  },
  methods: {
    formatSupervisorNames(supervisors) {
      const supervisorNames = supervisors.reduce((names, supervisor) => {
        if (supervisor.connected === true) {
          names.push(supervisor.name)
        }
        return names
      }, [])
      const combinedNames = supervisorNames.join(
        this.$t('kindergarten_list.comma')
      )
      return combinedNames
    },
    close() {
      this.$emit('clickClose')
    },
    sendSingleMsg(auth, supervisor) {
      return this.$axiosM.post(
        '/line/message',
        {
          receiver: supervisor.name,
          sender: auth.name,
          method: 1,
          userId: supervisor.userId,
          message: this.messageConfirm,
          company_id: auth.companyId,
          attachFile: [],
        },
        {
          headers: {
            Authorization: auth.idToken,
          },
        }
      )
    },
    submit() {
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          this.showLoading({ show: true, text: this.$t('alert.processing') })

          let apiPromises = []

          this.lineData.supervisors.forEach((supervisor) => {
            if (supervisor.connected === false) {
              return
            }
            apiPromises.push(this.sendSingleMsg(auth, supervisor))
          })
          Promise.allSettled(apiPromises).then((results) => {
            const isSuccess = results.every(
              (result) =>
                result.status === 'fulfilled' && result.value.status === 200
            )

            if (isSuccess) {
              this.loading = false
              this.showLoading({ show: false })
              this.showSnack({
                text: this.$t('alert.send_success'),
                timeout: 6000,
              })
              this.$emit('closeAllDialogSendMsg')
            } else {
              this.loading = false
              this.showLoading({ show: false })
              this.showSnack({
                text: this.$t('alert.send_failed'),
                color: 'error',
                timeout: 6000,
              })
            }
          })
        })
        .catch((_session) => {
          this.loading = false
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.login_again'),
            color: 'error',
            timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })
    },
  },
}
</script>

<style scoped>
.confirm-textarea {
  white-space: pre-wrap;
}
</style>
