<template>
  <div class="custom-text">
    <div style="height: 100vh">
      <div class="pa-0" style="height: 100%">
        <v-card class="main mx-auto overflow-hidden rounded-0" style="height: 100%">
          <Detail style="height: 80vh" :user="user" />
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from '@/components/history/Calendar.vue'
import Detail from '@/components/history/DetailM.vue'

export default {
  components: {
    Calendar,
    Detail,
  },
  props: {
    user: Object,
  },
  mounted() {
    this.showLoading({ show: true })
  },
  computed: {
    APP_TITLE: {
      get: function () {
        return process.env.VUE_APP_TITLE
      },
    },
  },
}
</script>
