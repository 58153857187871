<template>
  <v-container
    fill-height
    fluid
    class="d-block"
    :class="[status === 3 ? 'redirect-page' : 'album-screen']"
  >
    <div v-if="status === 0">
      <v-row>
        <v-col cols="1" class="py-0 d-flex align-center">
          <v-btn icon class="text-left d-block" @click="returnLine">
            <v-icon large color="black">mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="6" class="py-0 d-flex align-center"
          ><v-card-text class="font-weight-bold custom-text">{{
            $t('album.album_selection')
          }}</v-card-text>
        </v-col>

        <v-col cols="5" class="pl-1 d-flex justify-end">
          <v-btn
            color="#6170E8"
            text
            style="font-size: 16px; white-space: normal; letter-spacing: 0"
            @click="openDownloadHistory"
            class="px-0 d-block"
            height="30"
          >
            {{ $t('album.download_history') }}
          </v-btn>
        </v-col>
      </v-row>
      <div class="d-flex flex-wrap">
        <div
          v-for="album in albumList"
          :key="album.id"
          class="mt-4 px-1"
          style="
            width: 31.3%;
            aspect-ratio: 78/70;
            border: 1px solid black;
            margin-left: 1%;
            margin-right: 1%;
          "
          @click="openMediaList(album)"
        >
          {{ album.name }}
        </div>
      </div>
      <div v-if="isShowMediaList">
        <v-bottom-sheet
          v-model="isShowMediaList"
          transition="dialog-bottom-transition"
          content-class="album-screen-dialog d-flex flex-column height-80"
        >
          <v-row class="ma-0 flex-grow-0"
            ><v-col cols="5" class="pl-12 font-weight-bold">{{
              $t('album.image_list')
            }}</v-col>
            <v-col></v-col>
            <v-col cols="5" class="text-right">
              <v-btn icon color="black" @click="closeMediaListDialog">
                <v-icon> mdi-chevron-down </v-icon>
              </v-btn></v-col
            >
          </v-row>

          <BatchDownloadList
            :user="user"
            :mediaList="mediaList"
            :title="selectedAlbum.name"
            :batchDownloadText="$t('album.batch_download')"
          ></BatchDownloadList>
        </v-bottom-sheet>
      </div>
    </div>
    <div v-if="status === 1">
      <v-row>
        <v-col cols="2" class="py-0">
          <v-btn
            icon
            height="44"
            class="pt-3 text-left d-block"
            @click="returnAlbumList"
          >
            <v-icon large color="black">mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8" class="py-0 text-center">
          <v-card-text class="font-weight-bold custom-text">{{
            $t('album.download_history')
          }}</v-card-text>
        </v-col>
      </v-row>
      <div class="mt-8">
        <v-row
          v-for="(downloadCount, downloadDate) in downloadHistory"
          :key="downloadDate"
        >
          <v-col cols="9"
            ><v-btn
              color="#6170E8"
              text
              style="font-size: 16px; white-space: normal"
              @click="openHistoryDetail(downloadDate)"
              class="px-0 d-block"
              height="30"
            >
              {{ downloadDate }}
            </v-btn></v-col
          >
          <v-col cols="3">{{ downloadCount }} {{ $t('album.photo') }}</v-col>
        </v-row>
      </div>
    </div>
    <div v-if="status === 2" class="d-flex flex-column">
      <v-row>
        <v-col cols="2" class="py-0">
          <v-btn
            icon
            height="44"
            class="pt-3 text-left d-block"
            @click="returnDownloadHistory"
          >
            <v-icon large color="black">mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8" class="py-0 text-center">
          <v-card-text class="font-weight-bold custom-text">{{
            $t('album.check_downloaded_images')
          }}</v-card-text>
        </v-col>
      </v-row>
      <BatchDownloadList
        :user="user"
        :mediaList="mediaList"
        :title="selectedDate"
        :isShowDownloadHistory="true"
        :batchDownloadText="$t('album.re_download')"
      ></BatchDownloadList>
    </div>
    <div v-if="status === 3">
      <v-row style="margin-top: 40px" justify="center">
        <v-col cols="12" class="text-center">
          <h2
            style="font-size: 16px; font-weight: 700, color: #333333; margin-bottom: 24px"
          >
            {{ $t('line_connect.register_new_child_label') }}
          </h2>
          <ENJIBtn height="44" color="#6170E8" @click="openMemberInfoPage">{{
            $t('line_connect.register_new_child')
          }}</ENJIBtn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<style scoped>
::v-deep(.height-80) {
  height: 80vh;
}
.album-screen,
::v-deep(.album-screen-dialog) {
  background-color: #fff;
}
.redirect-page {
  background: #ecedfa;
}
</style>
<script>
import dayjs from 'dayjs'

import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import BatchDownloadList from '@/components/album/BatchDownloadList.vue'

export default {
  components: { ENJIBtn, BatchDownloadList },
  async created() {
    if (this.user.isClient) {
      const profile = await liff.getProfile()
      let url = `${window.location.href}&userId=${profile.userId}`
      liff.openWindow({
        url: url,
        external: true,
      })
      return
    }

    if (!liff.isLoggedIn()) {
      liff.login({
        redirectUri: window.location.href,
      })
      return
    }

    const userIdQuery = this.$route.query.userId
    const profile = await liff.getProfile()
    if (userIdQuery === profile.userId) {
      this.showLoading({ show: true })
      this.$axios
        .post('/member/connect/list', {
          token: this.user.token,
          company_id: this.user.companyId,
        })
        .then((response) => {
          if (response.data.length !== 0) return this.initialize()
          this.status = 3
          this.showLoading({ show: false })
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 500) {
            //redirect to page error 500
            this.$router.replace('/500')
            return
          }
          this.showLoading({ show: false })
        })

      return
    }
    liff.logout()
    liff.login({
      redirectUri: window.location.href,
    })
  },

  props: {
    user: Object,
  },
  data() {
    return {
      albumList: [],
      mediaList: [],
      dialog: false,
      isShowMediaList: false,
      status: 0,
      imgSrcForDownload: '',
      isShowDownloadSingleImage: false,
      selectedAlbum: {},
      selectedDate: '',
      downloadHistory: {},
    }
  },
  methods: {
    initialize() {
      this.showLoading({ show: true })
      this.$axios
        .post('/album/list', {
          token: this.user.token,
          company_id: this.user.companyId,
        })
        .then(async (response) => {
          if (response.status === 200) {
            this.albumList = response.data
            this.albumList.sort((a, b) => b.timestamp - a.timestamp)
            this.status = 0
          } else {
            this.showSnack({
              text: this.$t('alert.error_get_data_failed'),
              isMobile: true,
              color: 'error',
              timeout: 6000,
            })
          }
          this.showLoading({ show: false })
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 500) {
            //redirect to page error 500
            this.$router.replace('/500')
            return
          }
          this.showLoading({ show: false })
        })
    },
    openMediaList(album) {
      this.selectedAlbum = album
      this.showLoading({ show: true })
      this.$axios
        .post('/media/list', {
          company_id: this.user.companyId,
          albumId: album.id,
        })
        .then(async (response) => {
          if (response.status === 200) {
            this.mediaList = response.data
            this.mediaList.sort((a, b) => b.timestamp - a.timestamp)
          } else {
            this.showSnack({
              text: this.$t('alert.error_get_data_failed'),
              isMobile: true,
              color: 'error',
              timeout: 6000,
            })
          }
          this.isShowMediaList = true
          this.showLoading({ show: false })
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 500) {
            //redirect to page error 500
            this.$router.replace('/500')
            return
          }
          this.showLoading({ show: false })
        })
    },
    openMemberInfoPage() {
      window.location.href = `${this.user.liffURL}/member/info?companyId=${this.user.companyId}`
    },
    closeMediaListDialog() {
      this.isShowMediaList = false
    },
    returnLine() {
      window.location.href = `https://line.me/R/oaMessage/${encodeURI(
        this.user.oaLineId
      )}`
    },
    returnAlbumList() {
      this.isShowMediaList = false
      this.initialize()
    },
    returnDownloadHistory() {
      this.openDownloadHistory()
    },
    openImage(url) {
      this.imgSrcForDownload = url
      this.isShowMediaList = false
      this.isShowDownloadSingleImage = true
    },
    downloadSingleImg() {
      this.isShowDownloadSingleImage = false
    },

    closeImg() {
      this.isShowDownloadSingleImage = false
    },
    openDownloadHistory() {
      this.showLoading({ show: true })
      this.$axios
        .post('/download/datelist', {
          company_id: this.user.companyId,
          token: this.user.token,
        })
        .then(async (response) => {
          if (response.status === 200) {
            const timestampList = response.data
            timestampList.sort((a, b) => b.timestamp - a.timestamp)
            this.downloadHistory = timestampList.reduce((acc, v) => {
              const date = dayjs(v.timestamp).format(this.$constants.dateFormat)
              acc[date] = (acc[date] || 0) + 1
              return acc
            }, {})
            this.status = 1
          } else {
            this.showSnack({
              text: this.$t('alert.error_get_data_failed'),
              isMobile: true,
              color: 'error',
              timeout: 6000,
            })
          }

          this.showLoading({ show: false })
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 500) {
            //redirect to page error 500
            this.$router.replace('/500')
            return
          }
          this.showLoading({ show: false })
        })
    },
    openHistoryDetail(downloadDate) {
      this.$axios
        .post('/download/list', {
          company_id: this.user.companyId,
          token: this.user.token,
          from: dayjs(downloadDate).startOf('day').valueOf(),
          to: dayjs(downloadDate).endOf('day').valueOf(),
        })
        .then(async (response) => {
          if (response.status === 200) {
            this.mediaList = response.data
            this.mediaList.sort((a, b) => b.timestamp - a.timestamp)
            this.selectedDate = downloadDate
            this.status = 2
          } else {
            this.showSnack({
              text: this.$t('alert.error_get_data_failed'),
              isMobile: true,
              color: 'error',
              timeout: 6000,
            })
          }

          this.showLoading({ show: false })
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 500) {
            //redirect to page error 500
            this.$router.replace('/500')
            return
          }
          this.showLoading({ show: false })
        })
    },
  },
}
</script>
