<template>
  <v-main align="center">
    <v-container fill-height fluid>
      <v-row>
        <v-col v-if="status == 0" cols="12" class="pt-1 px-0">
          <!-- 園児リスト -->
          <MemberList
            :member-list="memberList"
            :user="user"
            @onRefreshPage="statusCheck"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import MemberList from '@/components/connect/ConnectList.vue'

export default {
  components: {
    MemberList,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      status: -1,
      targetId: '',
      memberList: [],
    }
  },
  mounted() {
    this.statusCheck()
  },
  methods: {
    statusCheck() {
      this.showLoading({ show: true })
      this.$axios
        .post('/member/connect/list', {
          token: this.user.token,
          company_id: this.user.companyId,
        })
        .then(async (response) => {
          if (response.status == 200) {
            this.memberList = response.data
          } else {
          }
          this.status = 0
          this.showLoading({ show: false })
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 500) {
            //redirect to page error 500
            this.$router.replace('/500')
            return
          }
          this.status = 0
          this.showLoading({ show: false })
        })
    },
  },
}
</script>
