import { render, staticRenderFns } from "./Terms.vue?vue&type=template&id=eb912f98&scoped=true&"
var script = {}
import style0 from "./Terms.vue?vue&type=style&index=0&id=eb912f98&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eb912f98",
  null
  
)

export default component.exports