<template>
  <div>
    <v-card-text class="text-center text-h5">
      {{ $t('member_richmenu.change') }}
    </v-card-text>
    <v-card-text class="text-center">
      <v-btn x-large color="info" @click="close">{{
        $t('button.close')
      }}</v-btn>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
  },
  mounted() {
    this.changeRichMenu()
  },
  methods: {
    changeRichMenu() {
      this.showLoading({ show: true, text: this.$t('alert.processing') })
      this.$axios
        .post('/richmenu/change', {
          token: this.user.token,
        })
        .then(() => {
          this.showLoading({ show: false })
          liff.closeWindow()
        })
        .catch(() => {
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.error_processing_failed'),
            isMobile: true,
            color: 'error',
            timeout: 6000,
          })
        })
    },
    close() {
      liff.closeWindow()
    },
  },
}
</script>
