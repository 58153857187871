<template>
  <div style="height: 100%">
    <div class="custom-container pa-0" style="height: 100%">
      <v-tabs background-color="#ECEDFA" v-model="tab" height="60px">
        <v-tab
          v-for="item in items"
          class="text-left"
          :class="[{ 'custom-tab-active': activeTab == item.index }]"
          @click="activeTab = item.index"
          :key="item.index"
        >
          {{ item.title }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" style="height: 100%">
        <v-tab-item
          v-for="item in items"
          :key="item.index"
          style="height: 100%"
        >
          <component
            v-bind:is="item.content"
            v-bind="item.props"
            v-on="item.on"
          ></component>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<style scoped>
.theme--light.v-tabs .v-tab--active:hover::before,
.theme--light.v-tabs .v-tab--active::before {
  opacity: 0;
}
.v-tab {
  font: normal normal bold 20px/26px Noto Sans JP;
  letter-spacing: 0px;

  width: 200px;
  background: #6170e8 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-right: 16px;
  padding: 16px 40px 15px 40px;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
  white-space: nowrap;
}
.v-tab,
.v-tab::before {
  border-radius: 12px 12px 0px 0px !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: #ffffff;
}
.custom-tab-active {
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #6170e8 !important;
}
.v-tabs :deep(.v-tabs-slider) {
  background-color: #6170e8 !important;
}
.v-tabs :deep(.v-tabs-slider-wrapper) {
  top: 0;
  margin: auto !important;
  height: 40px !important;
  width: 6px !important;
}
.theme--light.v-tabs-items {
  background-color: #ecedfa;
}
</style>
<script>
export default {
  props: {
    items: [],
  },
  data() {
    return {
      tab: null,
      activeTab: 1,
    }
  },
}
</script>
