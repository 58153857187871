import Vue from 'vue'

Vue.prototype.$constants = {
  // システム用日
  dateFormat: 'YYYY-MM-DD',
  monthFormat: 'YYYY-MM',
  // システム用日時
  dateTimeFormat: 'YYYY-MM-DD HH:mm',
  // システム用日時（ユーザー側）
  dateTimeFormatJP: 'YYYY年MM月DD日 HH:mm',
  // システム用日付（日本語）
  dateFormatJP: 'YYYY年MM月DD日',
  // システム用日付（曜日あり）
  dateFormatDayJP: 'YYYY年MM月DD日(ddd)',
  // システム用月（日本語）
  dateFormatMonthJP: 'YYYY年MM月',
  // システム用時刻
  timeFormat: 'HH:mm',
  // システム用時間（日本語）
  timeFormatJP: 'H時間m分',
  // システム用分（日本語）
  timeMinuteFormatJP: 'm分',
  // 祝祭日タイトル
  holidayTitle: process.env.VUE_APP_HOLIDAY_WORD,
  // 休業日タイトル
  restTitle: process.env.VUE_APP_TEIKYU_WORD,
  // 曜日
  ddd: ['日曜', '月曜', '火曜', '水曜', '木曜', '金曜', '土曜'],
  // 曜日（祝日）
  dddHoliday: ['日曜', '月曜', '火曜', '水曜', '木曜', '金曜', '土曜', '祝日'],
  eigyoStyle: Object.freeze({
    openRest: 0, // 営業日（休憩あり）
    open: 1, // 営業日（休憩なし）
    close: 2, // 休業日
  }),
  checkInStatus: Object.freeze({
    checkIn: '0', // チェックイン
    checkOut: '1', // チェックアウト
    absence: '2', //absent
  }),
  checkInType: Object.freeze({
    qr: '0', // QR
    face: '1', // 顔認証
    manual: '2', // manual
  }),
  messageMethod: Object.freeze({
    individual: 1, // 個別連絡
    bulk: 2, // グループ一斉配信
  }),
  checkInMode: Object.freeze({
    in: 'in', // チェックイン
    out: 'out', // チェックアウト
  }),
  broadcastStatus: Object.freeze({
    now: 0, // 今すぐ送信
    setup: 1, // 送信準備
    reserve: 2, // 送信予約
    sending: 3, // 送信済み
  }),
  cameraMode: Object.freeze({
    front: 'user',
    back: 'environment',
  }),
  richMenuError: Object.freeze({
    invalidJSON: 'Invalid JSON',
    richMenuImgError: 'Richmenu image error',
  }),
  broadcastStatusStyles: ['今すぐ送信', '送信準備中', '送信予約', '送信済み'],
  eigyoStyles: ['営業日（休憩あり）', '営業日（休憩なし）', '休業日'],
  restStyles: ['未設定', '第1', '第2', '第3', '第4', '第5'],
  // 時間の刻み方
  intervalTypeList: [
    {
      val: '00:15',
      name: '15分刻み',
    },
    {
      val: '00:30',
      name: '30分刻み',
    },
    {
      val: '01:00',
      name: '1時間刻み',
    },
    {
      val: '02:00',
      name: '2時間刻み',
    },
  ],
  mainColor: '#6170E8',
  backgroundColor: '#ECEDFA',
  s3MsgAlbum: 'message',
  richMenuType: Object.freeze({
    supervisor: 0, // parent
    staff: 1, // staff
    staff_supervisor: 2 // supervisor of staff
  }),
  lineLiffPreUrl: 'https://liff.line.me/',
}

export default (context) => {
  context.$constants = Vue.prototype.$constants
}
