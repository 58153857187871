<template>
  <v-card flat tile>
    <v-card-text class="font-weight-bold custom-text">{{
      $t('member_contact.consult_fill_form')
    }}</v-card-text>
    <hr size="2" style="border-top: 1px solid #d0d0d0" />
    <v-card-text>
      <v-form ref="sendItem">
        <label class="custom-label" style="left: 0; right: 0"
          ><span>*</span>{{ $t('member_contact.consult_content') }}</label
        >
        <v-textarea
          v-model="message"
          :rows="15"
          outlined
          background-color="#EEEEEE"
          :rules="[required, textRules.regex]"
          :disabled="loading"
          class="custom-textarea"
        >
        </v-textarea>
        <v-card-actions class="justify-center">
          <ENJIBtn
            @click="submit"
            color="#6170E8"
            height="44"
            width="120"
            elevation="0"
            >{{ $t('button.send') }}</ENJIBtn
          >
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<style scoped>
.custom-label {
  display: flex;
  color: #9e9e9e;
}
.custom-label > span {
  color: red;
}
.custom-text {
  font-size: 16px !important;
  color: #333333 !important;
}
</style>

<script>
import ENJIBtn from '../buttons/ENJIBtn.vue'

export default {
  components: {
    ENJIBtn,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      message: '',
      loading: false,
      required: (value) => !!value || this.$t('alert.required_text'),
      textRules: {
        regex: (v) => /\S/.test(v) || this.$t('alert.required_text'),
      },
    }
  },
  methods: {
    submit() {
      if (this.$refs.sendItem.validate()) {
        this.showLoading({
          show: true,
          text: this.$t('member_contact.sending'),
        })
        this.$axios
          .post('/member/contact', {
            token: this.user.token,
            company_id: this.user.companyId,
            message: this.message,
          })
          .then((response) => {
            this.showLoading({ show: false })
            if (response.status == 200) {
              this.$emit('done')
            } else {
              this.showSnack({
                text: response.data.message,
                isMobile: true,
                color: 'error',
                timeout: 6000,
              })
            }
          })
          .catch((error) => {
            this.showLoading({ show: false })

            if (error.response) {
              this.showSnack({
                text: this.$t('api_error.' + error.response.data.errorCode),
                isMobile: true,
                color: 'error',
                timeout: 6000,
              })
            } else {
              this.showSnack({
                text: this.$t('alert.send_failed'),
                isMobile: true,
                color: 'error',
                timeout: 6000,
              })
            }
          })
      }
    },
  },
}
</script>
