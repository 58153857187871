const getters = {}

const state = {
  text: '',
  color: '',
  timeout: '',
  isMobile: '',
}

const mutations = {
  SHOW_MESSAGE(state, payload) {
    state.text = payload.text
    state.color = payload.color
    state.timeout = payload.timeout
    state.isMobile = payload.isMobile ? payload.isMobile : false
  },
}

const actions = {
  showSnack({ commit }, payload) {
    commit('SHOW_MESSAGE', payload)
  },
}

export default {
  namespaced: true,
  getters,
  state,
  mutations,
  actions,
}
