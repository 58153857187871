<template>
  <v-main v-if="show" align="center">
    <v-container fill-height fluid>
      <v-row justify="center">
        <v-col v-if="status == 0" class="pt-0 px-0">
          <!-- 顔登録リスト -->
          <FaceList :face-list="faceList" v-on:setting="onSetting" />
        </v-col>
        <v-col v-else-if="status == 1" class="pa-0" style="height: 100vh">
          <!-- 顔登録開始 -->
          <FaceCamera
            @back="back"
            @onImageCaptured="openFaceRegistConfirm"
            :leave="leave"
            ref="faceCamera"
          />
          <v-dialog
            persistent
            v-model="isShowFaceRegisterConfirmDialog"
            content-class="mx-4"
          >
            <FaceRegistConfirm
              @clickClose="closeFaceRegistConfirm"
              @registerFace="onReturnList"
              :imgSrc="imgSrc"
              :target-id="targetId"
              :user="user"
            ></FaceRegistConfirm>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
  <v-main v-else align="center">
    <v-row style="margin-top: 38px" justify="center">
      <v-col cols="12">
        <h2 style="font-size: 16px; color: #333333; margin-bottom: 24px">
          {{ $t('line_connect.register_new_child_label') }}
        </h2>
        <ENJIBtn
          height="44"
          color="#6170E8"
          @click="$router.push('/member/info')"
          >{{ $t('line_connect.register_new_child') }}</ENJIBtn
        >
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import FaceCamera from '@/components/FaceCamera.vue'
import FaceList from '@/components/FaceList.vue'
import FaceRegistConfirm from '@/components/FaceRegistConfirm.vue'
export default {
  beforeRouteLeave(_to, _from, next) {
    this.leave = true
    // カメラ停止
    if (this.$refs.faceCamera) {
      this.$refs.faceCame.pauseCamera()
    }
    next()
  },
  components: {
    ENJIBtn,
    FaceCamera,
    FaceList,
    FaceRegistConfirm,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      isShowFaceRegisterConfirmDialog: false,
      show: true,
      leave: false,
      status: -1,
      targetId: '-1',
      faceList: [],
      imgSrc: '',
    }
  },
  mounted() {
    this.statusCheck()
  },
  methods: {
    statusCheck() {
      this.showLoading({ show: true })
      this.$axios
        .post('/member/connect/list', {
          token: this.user.token,
          company_id: this.user.companyId,
        })
        .then(async (response) => {
          if (response.data.length !== 0) {
            const faceResult = await this.faceImageCheck()
            this.status = 0
            this.faceList = faceResult.faceList
            this.showLoading({ show: false })
            this.show = true
          } else {
            this.show = false
            this.showLoading({ show: false })
          }
        })
        .catch((error) => {
          console.log(error)
          this.show = false
          this.showLoading({ show: false })
        })
    },
    async faceImageCheck() {
      const ret = await this.$axios
        .post(`${process.env.VUE_APP_API_URL}/face/get`, {
          token: this.user.token,
          company_id: this.user.companyId,
        })
        .then((response) => {
          return {
            faceList: response.data,
          }
        })
        .catch((_error) => {
          return { none: true }
        })
      return ret
    },
    onSetting(faceInfo) {
      // 新規登録
      this.status = 1
      this.targetId = faceInfo.id
    },
    async onReturnList() {
      // カメラ停止
      if (this.$refs.faceCamera) {
        this.$refs.faceCamera.pauseCamera()
      }
      this.targetId = '-1'
      this.isShowFaceRegisterConfirmDialog = false
      this.statusCheck()
    },
    closeWindow() {
      liff.closeWindow()
    },
    openFaceRegistConfirm(imgSrc) {
      this.imgSrc = imgSrc
      this.isShowFaceRegisterConfirmDialog = true
    },
    closeFaceRegistConfirm() {
      this.$refs.faceCamera.resumeCamera()
      this.isShowFaceRegisterConfirmDialog = false
    },
    back() {
      this.status = 0
    },
  },
}
</script>
