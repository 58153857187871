<template>
  <div class="member-list">
    <v-row justify="center" class="mx-0 navigation-fixed">
      <v-col class="px-0">
        <v-card
          flat
          class="rounded-0"
          style="height: 68px; border-bottom: 2px solid #d0d0d0"
        >
          <v-card-title class="d-flex align-center justify-space-between">
            <v-btn
              :class="{ 'disable-events': childSelected.length === 0 }"
              color="#DC313B"
              text
              @click="onDelete()"
              style="font-size: 16px; padding-left: 14px"
            >
              {{ $t('member_info.delete') }}
            </v-btn>
            <h2
              class="d-block"
              style="font-size: 16px; font-weight: 700; color: #333333"
            >
              {{ $t('line_connect.list_child_line_connect') }}
            </h2>
            <v-btn
              class="link-action"
              color="#6170E8"
              text
              @click="back"
              style="font-size: 16px; padding-left: 4px"
            >
              {{ $t('member_info.cancel') }}
            </v-btn>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="memberList.length > 0" style="margin-top: 78px">
      <v-row
        v-for="member in memberList"
        :key="member.id"
        align="center"
        class="member-item"
      >
        <v-col cols="12" class="d-flex pl-6 align-center" style="height: 72px">
          <v-checkbox
            v-model="childSelected"
            color="#6170E8"
            :value="member.id"
            dense
            class="member-checkbox"
          />
          <span
            style="
              font-size: 16px;
              font-weight: 700;
              color: #333333;
              margin-left: 16px;
            "
          >
            {{ member.firstname }} {{ member.lastname }}
          </span>
        </v-col>
      </v-row>
    </div>
    <v-row v-else style="margin-top: 98px" justify="center">
      <v-col cols="12">
        <h2 style="font-size: 16px; color: #333333; margin-bottom: 16px">
          {{ $t('line_connect.register_new_child_label') }}
        </h2>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="deleteConfirm" width="358">
      <DeleteConfirmModal
        @delete="deleteChildren"
        @close="onCloseDeleteModal"
      />
    </v-dialog>
  </div>
</template>
<script>
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import DeleteConfirmModal from '@/components/create/DeleteConfirmModal.vue'

export default {
  components: {
    ENJIBtn,
    DeleteConfirmModal,
  },
  props: {
    memberList: {
      type: Array,
      required: true,
    },
    user: Object,
  },
  data() {
    return {
      deleteConfirm: false,
      headers: [
        {
          text: this.$t('member_info.child_name'),
          value: 'name',
          align: 'left',
        },
        { text: 'QR', value: 'qr', align: 'left' },
        {
          text: this.$t('member_info.edit'),
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      childSelected: [],
    }
  },
  methods: {
    back() {
      this.$emit('back')
    },
    onDelete() {
      this.deleteConfirm = true
    },
    onCloseDeleteModal() {
      this.deleteConfirm = false
    },
    async deleteChildren() {
      this.showLoading({ show: true, text: this.$t('alert.processing') })
      const profile = await liff.getProfile()
      let apiPromises = this.memberList.reduce((promises, member) => {
        if (this.childSelected.includes(member.id)) {
          member.supervisors = member.supervisors.filter(
            (supervisor) => supervisor.userId !== profile.userId
          )
          promises.push(this.deleteItem(member))
        }
        return promises
      }, [])
      Promise.allSettled(apiPromises)
        .then((results) => {
          const isSuccess = results.every(
            (result) =>
              result.status === 'fulfilled' && result.value.status === 200
          )
          if (isSuccess) {
            this.$emit('back')
          } else {
            this.showSnack({
              text: this.$t('alert.delete_failed'),
              isMobile: true,
              color: 'error',
              timeout: 6000,
            })
          }
          this.showLoading({ show: false })
        })
        .catch((_error) => {
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.delete_failed'),
            isMobile: true,
            color: 'error',
            timeout: 6000,
          })
        })
    },
    deleteItem(member) {
      return this.$axios.post('/member/update', {
        token: this.user.token,
        ...member,
      })
    },
    done() {
      liff.closeWindow()
    },
  },
}
</script>
<style scoped>
.link-action {
  color: #6170e8;
  font-size: 16px;
  cursor: pointer;
}

.member-list .member-item {
  background: #ffffff;
  margin: 16px;
  border-radius: 6px;
  box-shadow: 0px 0px 6px #00000029;
}

.disable-events {
  pointer-events: none;
  opacity: 0.3;
}

.member-checkbox.v-input--checkbox
  ::v-deep(.v-input--selection-controls__input) {
  margin-top: 12px;
}
.navigation-fixed {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}
</style>
