<template>
  <v-card width="650px" class="mx-auto mt-5">
    <v-container>
      <v-card-title>
        <h1 class="display-1">{{ $t('maintain.under_maintain') }}</h1>
      </v-card-title>
      <v-card-text>
        {{ $t('maintain.system_update') }}
      </v-card-text>
      <v-card-actions class="justify-center mt-5" color="transparent">
        <v-btn color="success" x-large width="200px" @click="close">
          {{ $t('button.close') }}
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
export default {
  methods: {
    close() {
      liff.closeWindow()
    },
  },
}
</script>
