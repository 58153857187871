<template>
  <v-chip-group active-class="active-chip" column multiple  v-model="selectedTags">
    <v-chip style="height: 40" v-for="(tag, index) in allTags" :key="index">
      {{ tag }}
    </v-chip>
  </v-chip-group>
</template>
<script>
export default {
  props: {
    allTags: [],
    tagsEdit: []
  },
  data() {
    return {
      selectedTags:[]
    }
  },
  created() {
    this.selectedTags = this.tagsEdit.map(item => this.allTags.indexOf(item))
  },
  watch: {
    selectedTags(newVal) {
      this.updateSelectedTags(newVal)
    }
  },
  methods: {
    updateSelectedTags(newIndexes) {
      const tags = newIndexes.map(index => this.allTags[index]);
      this.$emit('update:selected-tags', tags);
    }
  }
}
</script>

<style scoped>
.active-chip {
  background-color: #6170e8 !important;
  color: white !important;
  border: 1px solid #6170e8;
}
.theme--light.v-chip:not(.v-chip--active) {
  background-color: #fff;
  border: 1px solid #707070;
}
</style>
