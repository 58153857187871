<template>
  <v-card class="mx-auto">
    <v-container style="border-bottom: 2px solid #c9c9c9">
      <div>
        <v-card-title class="pt-4 px-10 justify-center custom-title-head"
          >{{ $t('member_info.avatar_confirm') }}
        </v-card-title>
      </div>
      <div style="display: block; text-align: center">
        <v-avatar size="240">
          <v-img :src="imgSrc" class="mx-auto" alt="Avatar" />
        </v-avatar>
      </div>
    </v-container>
    <v-card-actions class="text-center d-block py-4">
      <ENJIBtn
        @click="close"
        color="#333333"
        outlined
        height="44"
        class="p-btn-retake font-size-16"
      >
        {{ $t('button.retake') }}
      </ENJIBtn>
      <ENJIBtn
        @click="register"
        color="#6170E8"
        height="44"
        class="ml-3 font-size-16 p-btn-reg"
      >
        {{ $t('button.regist_2') }}
      </ENJIBtn>
    </v-card-actions>
  </v-card>
</template>
<script>
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'

export default {
  components: {
    ENJIBtn,
  },
  props: {
    imgSrc: String,
    user: Object,
    targetId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    close() {
      this.$emit('clickClose')
    },
    register() {
      this.showLoading({ show: true, text: this.$t('alert.processing') })
      const base64 = this.imgSrc
      const faceImage = base64.split(',')[1]
      this.$axios
        .post('face/regist', {
          token: this.user.token,
          inputImage: faceImage,
          targetId: this.targetId,
        })
        .then((_response) => {
          this.showLoading({ show: false })
          this.$emit('registerFace')
        })
        .catch((error) => {
          this.showLoading({ show: false })
          this.showSnack({
            text: error.response.data.message,
            isMobile: true,
            color: 'error',
            timeout: 10000,
          })
        })
    },
  },
}
</script>
<style scoped>
.p-btn-reg {
  padding: 0 42px !important;
}
.p-btn-retake {
  padding: 0 24px !important;
}
</style>
