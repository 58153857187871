<template>
  <div>
    <div style="height: 100%; padding: 0px">
      <v-card
        class="main mx-auto d-flex"
        style="height: 100%; flex-direction: column"
      >
        <v-card-title class="border-bottom mb-2 custom-title-head">
          {{ $t('kindergarten_list.title') }}
        </v-card-title>
        <hr size="2" style="border-top: 1px solid #d0d0d0" />
        <v-card-title>
          <v-row justify="space-between">
            <v-col cols="4">
              <InputSearch height="44" v-model="search" />
            </v-col>
            <v-col cols="2" class="d-flex justify-end">
              <ENJIBtn
                height="44"
                @click="
                  editItem({
                    id: '',
                    phone: '',
                    lastname: '',
                    firstname: '',
                    kana: '',
                    birthday: '',
                    tags: [],
                    supervisors: [],
                  })
                "
                color="#6170E8"
              >
                {{ $t('kindergarten_list.create_new') }}
              </ENJIBtn>
            </v-col>
          </v-row>
        </v-card-title>
        <hr size="2" style="border-top: 1px solid #d0d0d0" />
        <v-data-table
          height="calc(100vh - 310px)"
          :page.sync="page"
          :headers="headers"
          :items="memberList"
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :custom-filter="customFilter"
          :custom-sort="customSort"
          :items-per-page="itemsPerPage"
          :loading="loading"
          hide-default-footer
          fixed-header
          :loading-text="$t('alert.loading_child_info')"
          class="elevation-1"
          item-style="font-size: 16px"
        >
          <template v-slot:[`item.edit`]="{ item }">
            <v-icon class="mr-3" @click="editItem(item)"> mdi-pencil </v-icon>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <v-avatar size="40px">
              <img
                alt="Avatar"
                :src="
                  item.image
                    ? `data:image/jpeg;base64,${item.image}`
                    : accountIcon
                "
              />
            </v-avatar>
          </template>
          <template v-slot:[`item.full_name`]="{ item }">
            {{ item.full_name }}
          </template>
          <template v-slot:[`item.supervisor_names`]="{ item }">
            {{ item.supervisor_names }}
          </template>
          <template v-slot:item.tags="{ item }">
            <v-chip-group column>
              <v-chip
                color="#616161"
                text-color="#FFFFFF"
                style="height: 40"
                v-for="(obj, index) in item.tags"
                :key="obj.id"
              >
                {{ obj.name }}
              </v-chip>
            </v-chip-group>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div :class="isPc ? 'd-flex align-items-center' : ''">
              <ENJIIconBtn
                height="40"
                color="#6170E8"
                class="my-1"
                style="margin-right: 12px"
                @click="openQR(item)"
              >
                <img width="20" height="20" :src="qrcode" />
                {{ $t('kindergarten_list.qrcode') }}
              </ENJIIconBtn>
              <ENJIIconBtn
                v-show="
                  item.supervisors.length > 0 &&
                  item.supervisors.every((supervisor) =>
                    supervisor.userId.startsWith('U')
                  )
                "
                color="#06C755"
                @click="openLINE(item)"
                height="40"
                class="my-1"
                :class="{
                  'disable-events': item.supervisors.every(
                    (supervisor) => supervisor.connected === false
                  ),
                }"
              >
                <img
                  width="28"
                  height="28"
                  :src="linelogo"
                  @click="openLINE(item)"
                />
                {{ $t('kindergarten_list.line_message') }}
              </ENJIIconBtn>
            </div>
          </template>
          <template v-slot:no-results>
            <span>{{ $t('alert.child_not_found') }}</span>
          </template>
          <template v-if="memberList.length > 0" v-slot:no-data>
            <v-btn color="primary" @click="initialize">{{
              $t('alert.reload')
            }}</v-btn>
          </template>
        </v-data-table>
        <Pagination v-model="page" :length="pageCount" />
      </v-card>
    </div>
    <!-- Dialog -->
    <div>
      <v-dialog persistent v-model="qrDialog" width="464px">
        <!-- QR画面 -->
        <DispQR
          :qrData="qrData"
          :name="name"
          :id="id"
          isMember
          v-on:clickClose="onQrDialogClose"
          v-on:update="updateQR"
        />
      </v-dialog>
    </div>
    <div v-if="lineDialog || lineConfirmDialog">
      <v-dialog
        persistent
        v-model="lineDialog"
        width="600px"
        height="100vh"
        :transition="dialogTransition"
        content-class="custom-dialog-content"
      >
        <line-card
          @clickClose="onLINEClose"
          @clickSend="openLINEConfirm"
          :lineData="lineData"
        ></line-card>
      </v-dialog>
    </div>
    <div>
      <v-dialog
        persistent
        v-model="lineConfirmDialog"
        width="600px"
        height="100vh"
        :transition="dialogTransition"
        content-class="custom-dialog-content"
      >
        <LineCardConfirm
          @clickClose="onLINEConfirmClose"
          @closeAllDialogSendMsg="closeAllDialogSendMsgToLine"
          :lineData="lineData"
          :messageConfirm="messageConfirm"
        ></LineCardConfirm>
      </v-dialog>
    </div>
    <div v-if="dialog == true">
      <v-dialog
        persistent
        v-model="dialog"
        width="600px"
        height="100vh"
        :transition="dialogTransition"
        content-class="custom-dialog-content"
      >
        <member-info
          v-on:clickClose="onClose"
          v-on:submit="onSubmit"
          :memberData="memberData"
          :tagList="tagList"
          @delete="deleteMember"
        ></member-info>
      </v-dialog>
    </div>
  </div>
</template>
<style>
/*== custom container ==*/
.v-sheet.v-card {
  border-radius: 12px;
}
/*== custom Table ==*/
.elevation-1.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #5d6060;
  font: normal normal normal 16px/18px Noto Sans JP;
  font-size: 16px;
}
.elevation-1.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 16px;
  font: normal normal normal 16px/18px Noto Sans JP;
  color: #161a1a;
  min-height: 57px;
  height: 57px;
}

.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-child(1),
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:nth-child(1) {
  padding-left: 16px !important;
  padding-right: 0;
}
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-child(2),
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:nth-child(2) {
  padding-left: 4px !important;
  padding-right: 16px;
}
.v-dialog {
  margin: 0 !important;
}
.fade-transition-right-enter-active {
  transition: transform 1s ease;
}
.fade-transition-right-leave-active {
  transition: transform 0.5s ease;
}

.fade-transition-right-enter,
.fade-transition-right-leave-to {
  transform: translateX(100%);
}

.custom-title-head {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #333333;
}
.disable-events {
  pointer-events: none;
  opacity: 0.3;
}
</style>
<script>
import accountIcon from '@/assets/account-circle-outline.svg'
import linelogo from '@/assets/line.svg'
import qrcode from '@/assets/qrcode.svg'
import LineCard from '@/components/LINECard.vue'
import MemberInfo from '@/components/MemberInfo.vue'
import DispQR from '@/components/create/DispQR.vue'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import ENJIIconBtn from '@/components/buttons/ENJIIconBtn.vue'
import LineCardConfirm from '@/components/LINECardConfirm.vue'
import InputSearch from '@/components/inputs/InputSearch.vue'
import Pagination from '@/components/Pagination.vue'
import LiffService from '@/views/utils/LiffService'

export default {
  components: {
    LineCard,
    MemberInfo,
    DispQR,
    ENJIBtn,
    ENJIIconBtn,
    LineCardConfirm,
    InputSearch,
    Pagination,
  },
  computed: {
    APP_TITLE: {
      get: function () {
        return process.env.VUE_APP_TITLE
      },
    },
  },
  data() {
    return {
      screenWidth: window.innerWidth,
      accountIcon: accountIcon,
      memberList: [],
      dialog: false,
      lineDialog: false,
      qrDialog: false,
      linelogo: linelogo,
      qrcode: qrcode,
      lineData: {},
      memberData: {},
      search: '',
      sortBy: 'sortNo',
      sortDesc: false,
      headers: [
        {
          text: '',
          value: 'edit',
          align: 'center',
          width: '10',
          sortable: false,
        },
        {
          text: '',
          value: 'image',
          align: 'center',
          width: '10',
          sortable: false,
        },
        {
          text: this.$t('kindergarten_list.kindergarten_name'),
          value: 'full_name',
          align: 'left',
        },
        {
          text: this.$t('kindergarten_list.supervisor'),
          value: 'supervisor_names',
          align: 'left',
        },
        {
          text: this.$t('kindergarten_list.tag'),
          value: 'tags',
          align: 'left',
          width: '40%',
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false,
          width: '100',
        },
      ],
      editedIndex: -1,
      editedItem: {},
      loading: true,
      qrData: '',
      name: '',
      id: '',
      page: 1,
      itemsPerPage: 100,
      dialogTransition: 'fade-transition-right',
      lineConfirmDialog: false,
      messageConfirm: '',
      tagList: [],
      liffURL: '',
      companyId: '',
    }
  },
  created() {
    this.isAuthenticated()
    this.getTags()
  },
  mounted() {
    window.addEventListener('resize', this.handleResize); // Listen for resize events
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize); // Remove the event listener when the component is destroyed
  },
  computed: {
    pageCount() {
      return Math.ceil(this.memberList.length / this.itemsPerPage)
    },
    isPc() {
      return this.screenWidth >= 1265 // px for screen pc
    },
  },
  methods: {
    customSort(items, sortBy, sortDesc) {
      let aTagNames = ''
      let bTagNames = ''
      return items.sort((a, b) => {
        if (sortBy[0] === 'tags') {
          aTagNames = a.tags.map(tag => tag.name.toLowerCase()).join('');
          bTagNames = b.tags.map(tag => tag.name.toLowerCase()).join('');
        } else if (sortBy[0] === 'full_name') {
          aTagNames = a.full_name
          bTagNames = b.full_name
        } else if (sortBy[0] === 'supervisor_names') {
          aTagNames = a.supervisor_names
          bTagNames = b.supervisor_names
        }
        if (!sortDesc[0]) {
              return aTagNames.localeCompare(bTagNames);
          } else {
              return bTagNames.localeCompare(aTagNames);
          }
      });
    },
    formatSupervisorNames(supervisors) {
      const supervisorNames = supervisors.map((supervisor) => supervisor.name)
      const combinedNames = supervisorNames.join(
        this.$t('kindergarten_list.comma')
      )
      return combinedNames
    },
    isAuthenticated() {
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          this.initialize()
          await this.getCompanyByID(auth.companyId)
        })
        .catch((_session) => {
          this.$router.replace('/manager/signin')
        })
    },
    signOut() {
      this.$cognito.logout()
      this.$router.replace('/manager/signin')
    },
    initialize() {
      this.showLoading({ show: true })
      // 園児情報取得
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          this.companyId = auth.companyId
          this.$axiosM
            .post(
              '/member/list',
              {
                company_id: auth.companyId,
              },
              {
                headers: {
                  Authorization: auth.idToken,
                },
              }
            )
            .then((response) => {
              this.loading = false
              this.showLoading({ show: false })
              this.memberList = response.data
            })
            .catch((error) => {
              console.log(error)
              this.loading = false
              this.showLoading({ show: false })
              this.$router.replace('/500')
              this.showSnack({
                text: this.$t('alert.error_get_data_failed'),
                color: 'error',
                timeout: 6000,
              })
            })
        })
        .catch((error) => {
          console.error(error)
          this.loading = false
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.login_again'),
            color: 'error',
            timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })
    },
    reload() {
      this.loading = true
      this.initialize()
    },
    editItem(item) {
      this.editedIndex = this.memberList.indexOf(item)
      this.memberData = Object.assign({}, item)
      this.memberData.supervisors = JSON.parse(JSON.stringify(item.supervisors))
      this.dialog = true
    },
    deleteMember() {
      this.dialog = false
      this.initialize()
    },
    openLINE(item) {
      this.lineData = item
      this.lineDialog = true
    },
    openQR(item) {
      this.qrData = `${this.liffURL}/member/info?qr=${item.qr}&companyId=${this.companyId}`
      ;(this.name = `${item.firstname} ${item.lastname}`),
        (this.id = item.id),
        (this.qrDialog = true)
    },
    async getCompanyByID(companyId) {
      const { liffURL } = await LiffService.getLiffId(companyId)
      this.liffURL = liffURL
    },
    onList() {
      this.initialize()
    },
    onLINEClose() {
      this.lineDialog = false
    },
    updateQR(value) {
      this.qrData = `${this.liffURL}/member/info?qr=${value}&companyId=${this.companyId}`
    },
    onQrDialogClose() {
      this.qrDialog = false
    },
    onSubmit() {
      this.initialize()
      this.dialog = false
    },
    customFilter(value, search, item) {
      const name = `${item.firstname} ${item.lastname}`
      const searchLowerCase = this.search.toLowerCase()
      return (
        (!!search &&
          item.supervisors.some((supervisor) =>
            supervisor.name.toLowerCase().includes(searchLowerCase)
          )) ||
        name.toLowerCase().includes(searchLowerCase) ||
        (item.tags?.length > 0 && item.tags.some(tag => tag.name.trim().toLowerCase().includes(searchLowerCase)))
      )
    },
    onClose() {
      this.dialog = false
    },
    openLINEConfirm(data) {
      this.lineDialog = false
      this.lineConfirmDialog = true
      this.messageConfirm = data
    },
    onLINEConfirmClose() {
      this.lineConfirmDialog = false
      this.lineDialog = true
    },
    closeAllDialogSendMsgToLine() {
      this.lineConfirmDialog = false
      this.lineDialog = false
    },
    getTags() {
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          this.$axiosM
            .post(
              '/tag/list',
              { company_id: auth.companyId },
              {
                headers: {
                  Authorization: auth.idToken,
                },
              }
            )
            .then((response) => {
              this.tagList = response.data
            })
            .catch((error) => {
              console.log(error)
              this.loading = false
              this.showLoading({ show: false })
              this.showSnack({
                text: this.$t('alert.error_get_data_failed'),
                color: 'error',
                timeout: 6000,
              })
            })
        })
        .catch((error) => {
          console.error(error)
          this.loading = false
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.login_again'),
            color: 'error',
            timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })
    },
    handleResize() {
      this.screenWidth = window.innerWidth; // Update screenWidth when the window is resized
    }
  },
}
</script>
