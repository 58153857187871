<template>
  <v-card
    width="100%"
    class="messageBulk overflow-auto"
    height="100vh"
    min-height="100vh"
  >
    <v-card-title v-show="!isMobile" class="pt-8 px-10 custom-title-head"
      >{{ $t('line_setting.alias') }}
    </v-card-title>
    <v-card-text class="px-10 pb-5 text-line-card">
      {{ this.$t('line_setting.supervisor_richmenu') }}
    </v-card-text>
    <v-card-text class="bt-0" :class="[isMobile ? 'px-4' : 'px-10']">
      <v-form ref="send_data">
        <label class="font-weight-medium"
          ><span class="span-color-line-card">*</span>JSON</label
        >
        <v-textarea
          v-model="richmenuData.richmenu_json"
          outlined
          clearable
          background-color="#EEEEEE"
          class="custom-textarea"
          height="500"
        />
        <div class="pb-4 col-md-4 col-4" v-if="imageUrl">
          <label class="font-weight-medium">{{ $t('line_setting.file_richmenu_image') }}</label>
          <v-img class="mt-2 img-preview" :src="imageUrl">
          <v-btn
            icon
            class="float-right"
            @click.stop="deleteFile(imageUrl)"
          >
            <v-icon size="30">mdi-close-circle-outline </v-icon>
          </v-btn>
          </v-img>
        </div>
        <v-file-input v-else
          accept="image/png, image/jpeg, image/bmp"
          small-chips
          :label="$t('line_setting.file_richmenu_image')"
          @change="handleFileUpload"
        ></v-file-input>
        <div
          v-if="validateMsg !== ''"
          class="pl-3 red--text"
          style="font-size: 12px"
        >
          {{ validateMsg }}
        </div>

        <v-card-actions class="text-center d-block">
          <ENJIBtn
            @click="close()"
            color="#333333"
            outlined
            width="120"
            class="mr-3 btn-font-size-line-card"
          >
            {{ $t('button.close') }}
          </ENJIBtn>
          <ENJIBtn
            @click="submit"
            color="#6170E8"
            width="120"
            class="ml-3 btn-font-size-line-card"
            :class="{
              'disable-events':
                !this.richmenuData.richmenu_json || this.selectedFile == null,
            }"
          >
            {{ $t('button.update') }}
          </ENJIBtn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import axios from 'axios'

export default {
  components: { ENJIBtn },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    user: Object,
    companyData: Object
  },
  data() {
    return {
      richmenuData: {
        id: '',
        richmenu_json: ''
      },
      selectedFile: null,
      imageUrl: null,
      textRules: {
        regex: (v) =>
          /\S/.test(v) || !/\s/.test(v) || this.$t('alert.required_text'),
      },
      validateMsg: '',
    }
  },
  created() {
    this.getSampleRichmenuByType(this.$constants.richMenuType.supervisor)
  },
  computed: {
    imageSrc() {
      return this.imageUrl
    }
  },
  methods: {
    getSampleRichmenuByType(richMenuType) {
      this.showLoading({ show: true })
      this.$cognito
      .isAuthenticated()
      .then(async (auth) => {
      this.$axiosM
          .post(
          '/sample/info',
          {
            richmenu_type: richMenuType,
          },
          {
            headers: {
              Authorization: auth.idToken,
            },
          }
          )
          .then((response) => {
            this.showLoading({ show: false })
            if (response.data !== null) {
              this.richmenuData = response.data[0]
              const sample_json = this.richmenuData.richmenu_json
              this.richmenuData.richmenu_json = sample_json.replace(/%LIFF_ID%/g, this.companyData.liff_id).replace(/%company_id%/g, this.companyData.id)
              this.getSampleRichmenuImage(this.richmenuData.media_id, auth.idToken)
            }
          })
          .catch((error) => {
            this.showLoading({ show: false })
            if (error.response.status === 500) {
              //redirect to page error 500
              this.$router.replace('/500')
              return
            }
            this.showSnack({
              text: this.$t('alert.error_get_data_failed'),
              color: 'error',
              timeout: 6000,
            })
          })
        })
        .catch((error) => {
          console.error(error)
          this.showLoading({ show: false })
          this.showSnack({
              text: this.$t('alert.login_again'),
              color: 'error',
              timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })
    },
    getSampleRichmenuImage(mediaId, authToken) {
      this.$axiosM
        .post(
        '/sample/richmenuImage',
        {
          media_id: mediaId,
        },
        {
          headers: {
            Authorization: authToken,
          },
        }
        )
        .then((response) => {
          this.imageUrl = response.data
          this.downloadImageFromS3Req(response.data)
        })
    },
    downloadImageFromS3Req(url) {
      axios.get(url, {
        responseType: 'blob',
      }).then((response) => {
        const imageBlob = new Blob([response.data])
        const imageFile = new File([imageBlob], 'sample_richmenu_image.png', { type: 'image/png' });
        this.handleFileUpload(imageFile)
      })
    },
    handleFileUpload(file) {
      this.selectedFile = file
    },
    submit() {
      this.showLoading({ show: true })
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          this.showLoading({ show: true, text: this.$t('alert.processing') })
          let formData = new FormData()
          formData.append('file', this.selectedFile)
          formData.append('company_id', auth.companyId)
          formData.append('json', this.richmenuData.richmenu_json.trim())
          this.$axiosM
            .post('/line/richmenu/create/supervisor', formData, {
              headers: {
                Authorization: auth.idToken,
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              if (response.data.statusCode !== 200) {
                let errorMsg = ''
                switch (response.data.message) {
                  case this.$constants.richMenuError.invalidJSON:
                    errorMsg = this.$t('line_setting.invalid_json')
                    break
                  case this.$constants.richMenuError.richMenuImgError:
                    errorMsg = this.$t('line_setting.rich_menu_img_error')
                    break
                  default:
                    errorMsg = response.data.message
                }
                this.showSnack({
                  text: errorMsg,
                  color: 'error',
                  timeout: 6000,
                })
                this.showLoading({ show: false })
                return
              }
              this.$emit(
                'setRichMenu',
                this.$constants.richMenuType.supervisor,
                response.data.richMenuId
              )
              this.$emit('clickClose')
              this.showLoading({ show: false })
            })
            .catch((error) => {
              console.log(error)
              this.$emit('clickClose')
              this.showLoading({ show: false })
              this.showSnack({
                text: this.$t('alert.error_processing_failed'),
                color: 'error',
                timeout: 6000,
              })
            })
        })
        .catch((error) => {
          console.error(error)
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.login_again'),
            color: 'error',
            timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })
    },
    close() {
      this.$emit('clickClose')
    },
    deleteFile() {
      this.imageUrl = null
      this.selectedFile = null
    },
  },
}
</script>
<style scoped>
.custom-textarea .v-input__control fieldset {
  border: none !important;
}
.img-preview {
  width: 100%;
  aspect-ratio: 78/70;
  border: 1px solid #707070;
}
</style>
