<template>
  <div>
    <router-view v-bind:user.sync="user"></router-view>
    <snackbar-vue />
    <loading-message-vue />
  </div>
</template>

<script>
import SnackbarVue from '@/components/Snackbar.vue'
import LoadingMessageVue from '@/components/LoadingMessage.vue'

export default {
  props: {
    liffURL: String,
    oaLineId: String,
  },
  components: {
    SnackbarVue,
    LoadingMessageVue,
  },
  data() {
    return {
      user: {
        id: '',
        parent: '',
        phone: '',
        kana: '',
        birthday: '',
        userId: '',
        manageId: '',
        token: '',
        isClient: false,
        liffURL: '',
      },
    }
  },
  created() {
    this.initializeLiff()
  },
  methods: {
    initializeLiff() {
      // PC or LINE
      this.user.isClient = liff.isInClient()
      // 編集用ID
      const companyId = this.$route.query.companyId
      if (companyId != undefined) {
        this.user.companyId = companyId
      }
      this.user.liffURL = this.liffURL
      this.user.oaLineId = this.oaLineId
      // アクセストークン取得
      this.user.token = liff.getAccessToken()
    },
  },
}
</script>
