<template>
  <div>
    <MenuLeft v-if="isMenuVisible" :menuTops="mainMenu" />
    <div class="main-container">
      <router-view style="height: 100%" />
    </div>
    <snackbar-vue />
    <loading-message-vue />
  </div>
</template>

<script>
import SnackbarVue from '@/components/Snackbar.vue'
import LoadingMessageVue from '@/components/LoadingMessage.vue'
import MenuLeft from '@/components/Menu.vue'
import LiffService from '../utils/LiffService'
export default {
  metaInfo: function () {
    return {
      meta: [
        { name: 'viewport', content: 'width=device-width,initial-scale=0.68' },
      ],
    }
  },
  components: {
    SnackbarVue,
    LoadingMessageVue,
    MenuLeft,
  },
  data() {
    return {
      isMenuVisible: true,
      mainMenu: [
        {
          title: this.$t('navbar.menu_view_child_info'),
          path: '/manager/member/list',
        },
        {
          title: this.$t('navbar.menu_attendance_history'),
          path: '/manager/history',
        },
        {
          title: this.$t('navbar.menu_group_bulk_message'),
          path: '/manager/message/bulk',
        },
        {
          title: this.$t('navbar.menu_send_history'),
          path: '/manager/message/history',
        },
        {
          title: this.$t('navbar.menu_master_management'),
          path: '/manager/master',
        },
        {
          title: this.$t('navbar.menu_media'),
          path: '/manager/album/upload',
        },
        {
          title: this.$t('album.download_history'),
          path: '/manager/album/download/history',
        },
        {
          title: this.$t('attendance.enter_mode'),
          path: '/manager/check/in?mode=hidemenu',
          btnOpen: 'mdi-open-in-new',
        },
        {
          title: this.$t('attendance.leave_mode'),
          path: '/manager/check/out?mode=hidemenu',
          btnOpen: 'mdi-open-in-new',
        },
        {
          title: this.$t('line_setting.title'),
          path: '/line/setting',
        },
      ],
      adminMenu: [
        {
          title: this.$t('navbar.menu_admin_management'),
          path: '/manager/company/master',
        },
      ],
    }
  },
  created() {
    const mode = this.$route.query['mode']
    if (mode === 'hidemenu') {
      this.isMenuVisible = false
    }
    this.isAuthenticated()
  },
  methods: {
    isAuthenticated() {
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          const { companyId } = await LiffService.getLiffId(auth.companyId)
          if (!companyId) {
            this.$cognito.logout()
            return this.$router.replace('/manager/signin')
          }

          if (this.$cognito.isAdministrator(auth.groups)) {
            this.mainMenu = this.adminMenu
          }
        })
        .catch((_session) => {
          this.$cognito.logout()
          this.$router.replace('/manager/signin')
        })
    },
  },
}
</script>

<style>
.main-container {
  padding: 40px;
  height: 100%;
  overflow: hidden;
}
</style>
