<template>
  <div class="d-flex">
    <v-dialog
      persistent
      v-model="supervisorRichMenuDialog"
      width="600px"
      height="100%"
      :transition="dialogTransition"
      content-class="custom-dialog-content"
      :retain-focus="false"
    >
      <v-card
        class="messageBulk overflow-auto"
        height="100vh"
        min-height="100vh"
      >
        <v-card-title class="pt-8 px-10 custom-title-head"
          >{{ $t('line_setting.alias') }}
        </v-card-title>
        <v-card-text class="px-10 pb-5 text-line-card">
          {{ $t('line_setting.supervisor_richmenu_of_staff') }}
        </v-card-text>
        <v-card-text class="bt-0" :class="[isMobile ? 'px-4' : 'px-10']">
          <v-form ref="send_data">
            <label class="font-weight-medium"
              ><span class="span-color-line-card">*</span>JSON</label
            >
            <v-textarea
              v-model="supervisorJson"
              outlined
              clearable
              background-color="#EEEEEE"
              class="custom-textarea"
              height="500"
            />
            <div class="pb-4 col-md-4 col-4" v-if="supervisorImgUrl">
              <label class="font-weight-medium">{{ $t('line_setting.file_richmenu_image') }}</label>
              <v-img class="mt-2 img-preview" :src="supervisorImgUrl">
              <v-btn
                icon
                class="float-right"
                @click.stop="deleteFile($constants.richMenuType.staff_supervisor)"
              >
                <v-icon size="30">mdi-close-circle-outline </v-icon>
              </v-btn>
              </v-img>
            </div>
            <v-file-input v-else
              accept="image/png, image/jpeg, image/bmp"
              small-chips
              :label="$t('line_setting.file_richmenu_image')"
              @change="handleSupervisorImgUpload"
            ></v-file-input>

            <v-card-actions class="text-center d-block">
              <ENJIBtn
                @click="close()"
                color="#333333"
                outlined
                width="120"
                class="mr-3 btn-font-size-line-card"
              >
                {{ $t('button.close') }}
              </ENJIBtn>
              <ENJIBtn
                @click="openStaffRichMenuDialog"
                color="#6170E8"
                width="120"
                class="ml-3 btn-font-size-line-card"
                :class="{
                  'disable-events':
                    !this.supervisorJson ||
                    (this.supervisorImg == null && this.supervisorImgUrl == null),
                }"
              >
                {{ $t('button.next') }}
              </ENJIBtn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="staffRichMenuDialog"
      width="600px"
      height="100%"
      :transition="dialogTransition"
      content-class="custom-dialog-content"
      :retain-focus="false"
    >
      <v-card
        class="messageBulk overflow-auto"
        height="100vh"
        min-height="100vh"
      >
        <v-card-title class="pt-8 px-10 custom-title-head"
          >{{ $t('line_setting.alias') }}
        </v-card-title>
        <v-card-text class="px-10 pb-5 text-line-card">
          {{ $t('line_setting.staff_richmenu') }}
        </v-card-text>
        <v-card-text class="bt-0" :class="[isMobile ? 'px-4' : 'px-10']">
          <v-form ref="send_data">
            <label class="font-weight-medium"
              ><span class="span-color-line-card">*</span>JSON</label
            >
            <v-textarea
              v-model="staffJson"
              outlined
              clearable
              background-color="#EEEEEE"
              class="custom-textarea"
              height="500"
            />
            <div class="pb-4 col-md-4 col-4" v-if="staffImgUrl">
              <label class="font-weight-medium">{{ $t('line_setting.file_richmenu_image') }}</label>
              <v-img class="mt-2 img-preview" :src="staffImgUrl">
              <v-btn
                icon
                class="float-right"
                @click.stop="deleteFile($constants.richMenuType.staff)"
              >
                <v-icon size="30">mdi-close-circle-outline </v-icon>
              </v-btn>
              </v-img>
            </div>
            <v-file-input v-else
              small-chips
              accept="image/png, image/jpeg, image/bmp"
              :label="$t('line_setting.file_richmenu_image')"
              @change="handleStaffImgUpload"
            ></v-file-input>

            <v-card-actions class="text-center d-block">
              <ENJIBtn
                @click="openSupervisorRichMenuDialog"
                color="#333333"
                outlined
                width="120"
                class="mr-3 btn-font-size-line-card"
              >
                {{ $t('button.back') }}
              </ENJIBtn>
              <ENJIBtn
                @click="submit"
                color="#6170E8"
                width="120"
                class="ml-3 btn-font-size-line-card"
                :class="{
                  'disable-events':
                    !this.staffJson ||
                    !this.supervisorJson ||
                    (this.staffImg === null && this.staffImgUrl == null) ||
                    (this.supervisorImg === null && this.supervisorImgUrl == null),
                }"
              >
                {{ $t('button.update') }}
              </ENJIBtn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import axios from 'axios'

export default {
  components: { ENJIBtn },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    user: Object,
    companyData: Object
  },
  data() {
    return {
      supervisorRichMenuDialog: true,
      staffRichMenuDialog: false,
      supervisorJson: '',
      staffJson: '',
      supervisorImg: null,
      staffImg: null,
      supervisorImgUrl: null,
      staffImgUrl: null,
      textRules: {
        regex: (v) =>
          /\S/.test(v) || !/\s/.test(v) || this.$t('alert.required_text'),
      },
      dialogTransition: 'fade-transition-right',
    }
  },
  created() {
    this.getSampleRichmenuByType(this.$constants.richMenuType.staff_supervisor)
  },
  methods: {
    getSampleRichmenuByType(richMenuType) {
      this.showLoading({ show: true })
      if (richMenuType == this.$constants.richMenuType.staff_supervisor) {
        this.supervisorJson = ''
      } else {
        this.staffJson = ''
      }

      this.$cognito
      .isAuthenticated()
      .then(async (auth) => {
      this.$axiosM
          .post(
          '/sample/info',
          {
            richmenu_type: richMenuType,
          },
          {
            headers: {
              Authorization: auth.idToken,
            },
          }
          )
          .then((response) => {
            this.showLoading({ show: false })
            if (response.data.length > 0) {
              const sample_json = response.data[0].richmenu_json
              const update_json = sample_json.replace(/%LIFF_ID%/g, this.companyData.liff_id).replace(/%company_id%/g, this.companyData.id)
              if (richMenuType == this.$constants.richMenuType.staff_supervisor) {
                this.supervisorJson = update_json
              } else {
                this.staffJson = update_json
              }
              this.getSampleRichmenuImage(response.data[0].media_id, auth.idToken, richMenuType)
            }
          })
          .catch((error) => {
            console.log(error)
            this.showLoading({ show: false })
            if (error.response.status === 500) {
              //redirect to page error 500
              this.$router.replace('/500')
              return
            }
            this.showSnack({
              text: this.$t('alert.error_get_data_failed'),
              color: 'error',
              timeout: 6000,
            })
          })
        })
        .catch((error) => {
          console.error(error)
          this.showLoading({ show: false })
          this.showSnack({
              text: this.$t('alert.login_again'),
              color: 'error',
              timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })
    },
    getSampleRichmenuImage(mediaId, authToken, richMenuType) {
      this.$axiosM
        .post(
        '/sample/richmenuImage',
        {
          media_id: mediaId,
        },
        {
          headers: {
            Authorization: authToken,
          },
        }
        )
        .then((response) => {
          if (richMenuType == this.$constants.richMenuType.staff_supervisor) {
            this.supervisorImgUrl = response.data
          } else {
            this.staffImgUrl = response.data
          }
          this.downloadImageFromS3Req(response.data, richMenuType)
        })
    },
    downloadImageFromS3Req(url, richMenuType) {
      axios.get(url, {
        responseType: 'blob',
      }).then((response) => {
        const imageBlob = new Blob([response.data])
        const imageFile = new File([imageBlob], 'sample_richmenu_image.png', { type: 'image/png' });
        if (richMenuType == this.$constants.richMenuType.staff_supervisor) {
          this.handleSupervisorImgUpload(imageFile)
        } else {
          this.handleStaffImgUpload(imageFile)
        }
      })
    },
    handleSupervisorImgUpload(file) {
      this.supervisorImg = file
    },
    handleStaffImgUpload(file) {
      this.staffImg = file
    },
    submit() {
      this.showLoading({ show: true })
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          this.showLoading({ show: true, text: this.$t('alert.processing') })
          let formData = new FormData()
          formData.append('supervisorImg', this.supervisorImg)
          formData.append('staffImg', this.staffImg)

          formData.append('company_id', auth.companyId)
          formData.append('supervisorAlias', 'alias-supervisor')
          formData.append('staffAlias', 'alias-staff')

          formData.append('supervisorJson', this.supervisorJson.trim())
          formData.append('staffJson', this.staffJson.trim())

          this.$axiosM
            .post('/line/richmenu/create/staff', formData, {
              headers: {
                Authorization: auth.idToken,
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              if (response.data.statusCode !== 200) {
                let errorMsg = ''
                switch (response.data.message) {
                  case this.$constants.richMenuError.invalidJSON:
                    errorMsg = this.$t('line_setting.invalid_json')
                    break
                  case this.$constants.richMenuError.richMenuImgError:
                    errorMsg = this.$t('line_setting.rich_menu_img_error')
                    break
                  default:
                    errorMsg = response.data.message
                }
                this.showSnack({
                  text: errorMsg,
                  color: 'error',
                  timeout: 6000,
                })
                this.showLoading({ show: false })
                return
              }
              this.$emit(
                'setRichMenu',
                this.$constants.richMenuType.staff,
                response.data.richMenuId
              )
              this.$emit('clickClose')
              this.showLoading({ show: false })
            })
            .catch((error) => {
              console.log(error)
              this.showLoading({ show: false })
              this.showSnack({
                text: this.$t('alert.error_processing_failed'),
                color: 'error',
                timeout: 6000,
              })
            })
        })
        .catch((error) => {
          console.error(error)
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.login_again'),
            color: 'error',
            timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })
    },
    close() {
      this.$emit('clickClose')
    },
    openSupervisorRichMenuDialog() {
      this.staffRichMenuDialog = false
      this.supervisorRichMenuDialog = true
    },
    openStaffRichMenuDialog() {
      this.getSampleRichmenuByType(this.$constants.richMenuType.staff)
      this.supervisorRichMenuDialog = false
      this.staffRichMenuDialog = true
    },
    deleteFile(richMenuType) {
      if (richMenuType == this.$constants.richMenuType.staff_supervisor) { 
        this.supervisorImgUrl = null
        this.supervisorImg = null
      } else {
        this.staffImgUrl = null
        this.staffImg = null
      }
    },
  },
}
</script>
<style scoped>
.custom-textarea .v-input__control fieldset {
  border: none !important;
}
.img-preview {
  width: 100%;
  aspect-ratio: 78/70;
  border: 1px solid #707070;
}
</style>
