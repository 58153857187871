<template>
  <v-app>
    <v-main :style="{ background: this.$constants.backgroundColor }">
      <router-view
        v-if="isLiffOK"
        v-bind:liffURL.sync="liffURL"
        v-bind:oaLineId.sync="oaLineId"
        style="height: 100%; width: 100%; overflow: auto"
      />
    </v-main>
  </v-app>
</template>

<script>
import LiffService from './views/utils/LiffService'
export default {
  name: 'App',

  data: () => ({
    isLiffOK: false,
    liffURL: '',
    oaLineId: '',
  }),
  async created() {
    await this.initializeLiff()
  },
  methods: {
    async initializeLiff() {
      // companyID
      let companyId = ''
      const queryLiffState = this.$route.query['liff.state']
      const query = this.$route.query['companyId']
      if (queryLiffState || query) {
        if (queryLiffState !== undefined) {
          const queryParams = new URLSearchParams(queryLiffState.split('?')[1])
          companyId = queryParams.get('companyId')
        }
        if (query !== undefined) {
          companyId = query
        }
        try {
          const { liffId, liffURL, oaLineId } = await LiffService.getLiffId(
            companyId
          )
          if (!liffId) {
            console.log('Liff can not exists')
            return
          }
          let isUseMock = false
          if (process.env.VUE_APP_MOCK_FLG === 'true') {
            isUseMock = true
          }
          await liff.init({
            liffId,
            mock: isUseMock,
            withLoginOnExternalBrowser: false,
          })
          this.oaLineId = oaLineId
          this.liffURL = liffURL
          this.isLiffOK = true
        } catch (error) {
          console.log('LIFF initialization failed.', error)
        }
      } else {
        this.isLiffOK = true
      }
    },
  },
}
</script>
