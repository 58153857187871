<template>
  <div>
    <v-container fluid class="pa-0" style="height: 100%">
      <v-card style="height: 100%">
        <v-card-title class="border-bottom mb-2 custom-font-title">
          {{ $t('navbar.menu_send_history') }}
        </v-card-title>
        <hr size="2" style="border-top: 1px solid #d0d0d0" />
        <v-card-title>
          <v-row justify="space-between">
            <v-col cols="4">
              <InputSearch height="44" v-model="search" />
            </v-col>
          </v-row>
        </v-card-title>
        <hr size="2" style="border-top: 1px solid #d0d0d0" />
        <v-data-table
          height="calc(100vh - 310px)"
          :page.sync="page"
          :items="messageList"
          :search="search"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :custom-filter="customFilter"
          fixed-header
          class="elevation-1"
          :headers="headers"
          :items-per-page="itemsPerPage"
          :no-results-text="$t('alert.not_found') "
          hide-default-footer
        >
          <template v-slot:[`item.timestamp`]="{ item }">
            {{ formatDate(item.timestamp) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <ENJIIconBtn
              color="#6170E8"
              class="px-6"
              height="40"
              icon="mdi-email-outline"
              @click="openDialogMsg(item)"
            >
              {{ $t('button.confirm_msg') }}
            </ENJIIconBtn>
          </template>
          <template v-slot:[`item.method`]="{ item }">
            {{ formatMethod(item.method) }}
          </template>
        </v-data-table>
        <hr size="2" style="border-top: 1px solid #d0d0d0" />
        <Pagination v-model="page" :length="pageCount" />
      </v-card>
    </v-container>

    <!-- dialog messages -->
    <v-dialog persistent v-model="showDialogMsg" width="794px" scrollable>
      <v-card class="mx-auto">
        <v-card-title class="pt-10 px-16 justify-center custom-title-head"
          >{{ $t('message.message_content') }}
        </v-card-title>
        <v-card-text class="msg-content mt-3 review-msg text-center">
          <p>{{ messagesContent }}</p>

          <v-row class="mt-3 justify-center" v-if="messageAttachments && messageAttachments.length > 0">
            <v-col cols="6" md="3" v-for="(attachment, index) in messageAttachments" :key="index" >
              <v-img :src="attachment.url" width="100%" class="image-preview" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-center px-9 my-3">
          <ENJIBtn
            @click="closeDialogMsg"
            color="#333333"
            outlined
            class="px-9 mr-3 btn-font-size-line-card"
          >
            {{ $t('button.close') }}
          </ENJIBtn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import ENJIIconBtn from '@/components/buttons/ENJIIconBtn'
import InputSearch from '@/components/inputs/InputSearch'
import Pagination from '@/components/Pagination'
import ENJIBtn from '@/components/buttons/ENJIBtn'
export default {
  components: {
    ENJIIconBtn,
    InputSearch,
    Pagination,
    ENJIBtn,
  },
  data() {
    return {
      page: 1,
      search: '',
      sortBy: 'timestamp',
      sortDesc: true,
      itemsPerPage: 100,
      showDialogMsg: false,
      messagesContent: '',
      messageAttachments: [],
      headers: [
        {
          text: this.$t('message.send_date_time'),
          value: 'timestamp',
        },
        { text: this.$t('message.sender'), sortable: false, value: 'sender' },
        {
          text: this.$t('message.receiver'),
          sortable: false,
          value: 'receiver',
        },
        {
          text: this.$t('message.send_method'),
          sortable: false,
          value: 'method',
        },
        { text: '', sortable: false, value: 'actions', align: 'right' },
      ],
      messageList: [],
    }
  },
  created() {
    this.initialize()
  },
  computed: {
    pageCount() {
      return Math.ceil(this.messageList.length / this.itemsPerPage)
    },
  },
  methods: {
    initialize() {
      this.$cognito.isAuthenticated().then(async (auth) => {
        this.showLoading({ show: true })
        this.$axiosM
          .post(
            '/message/list',
            { company_id: auth.companyId },
            {
              headers: {
                Authorization: auth.idToken,
              },
            }
          )
          .then((response) => {
            this.showLoading({ show: false })
            this.messageList = response.data
          })
          .catch(() => {
            console.log(error)
            this.showLoading({ show: false })
            this.showSnack({
              text: this.$t('alert.error_get_data_failed'),
              color: 'error',
              timeout: 6000,
            })
          })
          .finally(() => {
            // this.showLoading({ show: false })
          })
      })
    },
    customFilter(value, search, item) {
      return (
        !!search &&
        (item.sender.toLowerCase().includes(search.toLowerCase()) ||
          item.receiver.toLowerCase().includes(search.toLowerCase()) ||
          item.message.toLowerCase().includes(search.toLowerCase()))
      )
    },
    formatMethod(value) {
      switch (value) {
        case this.$constants.messageMethod.individual:
          return this.$t('message.send_method_individual')
        case this.$constants.messageMethod.bulk:
          return this.$t('message.send_method_bulk')
      }
    },
    formatDate(date) {
      return dayjs(date).format(this.$t('datetime.YYYY-MM-DDHH:mm:ss'))
    },
    openDialogMsg(content) {
      this.showDialogMsg = true
      this.messagesContent = content.message
      this.messageAttachments = content.attachment
    },
    closeDialogMsg() {
      this.showDialogMsg = false
    },
  },
}
</script>

<style scoped>
.msg-content {
  font-size: 16px !important;
  color: #161a1a;
  padding: 24px 80px !important;
}
.review-msg {
  white-space: pre-wrap;
}

.image-preview {
  aspect-ratio: 78/70;
}
</style>
