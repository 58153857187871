<template>
  <div>
    <div fluid class="pa-0 staff-master-container">
      <div>
        <v-card class="main mx-auto">
          <v-card-title>
            <v-row justify="space-between">
              <v-col cols="4">
                <InputSearch height="44" v-model="search" />
              </v-col>
              <v-col cols="2" class="d-flex justify-end">
                <ENJIBtn
                  color="#6170EB"
                  height="44"
                  class="px-6"
                  @click="
                    editItem({
                      id: '',
                      name: '',
                      qr: '',
                      company_id: '',
                      email: '',
                    })
                  "
                >
                  {{ $t('master.create_new') }}
                </ENJIBtn>
              </v-col>
            </v-row>
          </v-card-title>
          <hr size="2" style="border-top: 1px solid #d0d0d0" />
          <v-data-table
            height="calc(100vh - 280px)"
            :page.sync="page"
            :headers="headers"
            :items="staffList"
            :search="search"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :custom-filter="customFilter"
            :items-per-page="itemsPerPage"
            :loading="loading"
            hide-default-footer
            fixed-header
            :loading-text="$t('alert.loading')"
            :no-results-text="$t('alert.not_found')"
            class="elevation-1"
            item-style="font-size: 16px"
          >
            <template v-slot:[`item.edit`]="{ item }">
              <v-icon class="mr-3" @click="editItem(item)"> mdi-pencil </v-icon>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:[`item.email`]="{ item }">
              {{ item.email }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex align-items-center">
                <v-divider class="mr-3" vertical inset></v-divider>
                <ENJIIconBtn
                  v-if="!isRoot(item.created_by)"
                  height="40"
                  color="#6170E8"
                  style="margin-right: 12px"
                  @click="openQR(item)"
                >
                  <img width="20" height="20" :src="qrcode" />
                  {{ $t('kindergarten_list.qrcode') }}
                </ENJIIconBtn>
              </div>
            </template>
          </v-data-table>
          <Pagination v-model="page" :length="pageCount" />
        </v-card>
      </div>
    </div>
    <!-- dialog -->
    <div v-if="dialog">
      <v-dialog
        persistent
        v-model="dialog"
        width="600px"
        height="100vh"
        :transition="dialogTransition"
        content-class="custom-dialog-content"
      >
        <StaffForm
          @closeDialog="onCloseDialog"
          @createStaff="onCreateStaff"
          @delete="deleteStaff"
          :staffData="staffData"
          :staffList="staffList"
        >
        </StaffForm>
      </v-dialog>
    </div>
    <div v-if="qrDialog">
      <v-dialog persistent v-model="qrDialog" width="464px">
        <!-- QR画面 -->
        <DispQR
          :qrData="qrData"
          :name="name"
          :id="id"
          @clickClose="onQrDialogClose"
          @update="updateQR"
        />
      </v-dialog>
    </div>
  </div>
</template>
<style>
/*== custom container ==*/
.staff-master-container .v-sheet.v-card {
  border-radius: 0px 12px 12px 12px;
}
.staff-master-container .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

/*== custom Table ==*/
.elevation-1.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  color: #5d6060;
  font: normal normal normal 16px/18px Noto Sans JP;
  font-size: 16px;
}
.elevation-1.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 16px;
  font: normal normal normal 16px/18px Noto Sans JP;
  color: #161a1a;
  min-height: 57px;
  height: 57px;
}

.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-child(1),
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:nth-child(1) {
  padding-left: 24px !important;
  padding-right: 0;
}
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:nth-child(2),
.elevation-1.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th:nth-child(2) {
  padding-left: 6px;
  padding-right: 16px;
}
</style>

<script>
import DispQR from '@/components/create/DispQR.vue'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import ENJIIconBtn from '@/components/buttons/ENJIIconBtn.vue'
import InputSearch from '@/components/inputs/InputSearch.vue'
import Pagination from '@/components/Pagination.vue'
import StaffForm from '@/components/StaffForm.vue'
import LiffService from '@/views/utils/LiffService'

import qrcode from '@/assets/qrcode.svg'

export default {
  components: {
    ENJIBtn,
    ENJIIconBtn,
    InputSearch,
    Pagination,
    StaffForm,
    DispQR,
  },
  computed: {
    APP_TITLE: {
      get: function () {
        return process.env.VUE_APP_TITLE
      },
    },
  },
  data() {
    return {
      status: -1,
      staffData: {},
      dialog: false,
      dialogTransition: 'fade-transition-right',
      staffList: [],
      search: '',
      sortBy: 'sortNo',
      sortDesc: false,
      qrcode,
      qrDialog: false,
      qrData: '',
      name: '',
      id: '',
      headers: [
        {
          text: '',
          value: 'edit',
          align: 'left',
          width: '10',
          sortable: false,
        },
        {
          text: this.$t('master.staff_name'),
          value: 'name',
          align: 'left',
        },
        {
          text: this.$t('master.mail_address'),
          value: 'email',
          align: 'left',
        },
        {
          text: '',
          value: 'actions',
          align: 'center',
          sortable: false,
          width: '100',
        },
      ],
      editedIndex: -1,
      editedItem: {},
      loading: true,
      page: 1,
      itemsPerPage: 100,
      liffURL: '',
      companyId: '',
    }
  },
  created() {
    this.isAuthenticated()
  },
  computed: {
    pageCount() {
      return Math.ceil(this.staffList.length / this.itemsPerPage)
    },
  },
  methods: {
    isAuthenticated() {
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          this.initialize()
          await this.getCompanyByID(auth.companyId)
        })
        .catch((_session) => {
          this.$router.replace('/manager/signin')
        })
    },
    initialize() {
      this.showLoading({ show: true })

      // 園児情報取得
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          this.$axiosM
            .post(
              '/user/list',
              { company_id: auth.companyId },
              {
                headers: {
                  Authorization: auth.idToken,
                },
              }
            )
            .then((response) => {
              this.loading = false
              this.showLoading({ show: false })
              this.companyId = auth.companyId
              this.staffList = response.data
            })
            .catch((error) => {
              console.log(error)
              this.loading = false
              this.showLoading({ show: false })
              if (error.response.status === 500) {
                //redirect to page error 500
                this.$router.replace('/500')
                return
              }
              this.showSnack({
                text: this.$t('alert.error_get_data_failed'),
                color: 'error',
                timeout: 6000,
              })
            })
        })
        .catch((error) => {
          console.error(error)
          this.loading = false
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.login_again'),
            color: 'error',
            timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })
    },
    editItem(item) {
      if (item.id) {
        this.editedIndex = this.staffList.indexOf(item.id)
      }
      this.staffData = Object.assign({}, item)
      this.dialog = true
    },
    customFilter(value, search, item) {
      const searchLowerCase = this.search.toLowerCase()
      return (
        !!search &&
        (item.name.toLowerCase().includes(searchLowerCase) ||
          item.email.toLowerCase().includes(searchLowerCase))
      )
    },
    openQR(item) {
      this.qrData = `${this.liffURL}/member/line/user/connect/${item.qr}?companyId=${this.companyId}`
      ;(this.name = item.name), (this.id = item.id), (this.qrDialog = true)
    },
    updateQR(value) {
      this.qrData = `${this.liffURL}/member/line/user/connect/${value}?companyId=${this.companyId}`
    },
    async getCompanyByID(companyId) {
      const { liffURL } = await LiffService.getLiffId(companyId)
      this.liffURL = liffURL
    },
    onQrDialogClose() {
      this.qrDialog = false
    },
    onCloseDialog() {
      this.dialog = false
    },
    deleteStaff() {
      this.dialog = false
      this.initialize()
    },
    onCreateStaff(value) {
      this.loading = true
      this.showLoading({ show: true })
      if (value.id) {
        this.$cognito
          .isAuthenticated()
          .then(async (auth) => {
            this.$axiosM
              .post('/user/update', value, {
                headers: {
                  Authorization: auth.idToken,
                },
              })
              .then(() => {
                this.loading = false
                this.showLoading({ show: false })
                this.initialize()
              })
              .catch(() => {
                this.loading = false
                this.showLoading({ show: false })
                this.showSnack({
                  text: this.$t('alert.update_failed'),
                  color: 'error',
                  timeout: 6000,
                })
              })
              .finally(() => {
                this.showLoading({ show: false })
                this.dialog = false
              })
          })
          .catch((error) => {
            console.error(error)
            this.loading = false
            this.showLoading({ show: false })
            this.showSnack({
              text: this.$t('alert.login_again'),
              color: 'error',
              timeout: 6000,
            })
            this.$router.replace('/manager/signin')
          })
      } else {
        this.$cognito
          .isAuthenticated()
          .then(async (auth) => {
            this.$axiosM
              .post(
                '/user/register',
                { ...value, company_id: auth.companyId },
                {
                  headers: {
                    Authorization: auth.idToken,
                  },
                }
              )
              .then(() => {
                this.loading = false
                this.showLoading({ show: false })
                this.initialize()
              })
              .catch(() => {
                this.loading = false
                this.showLoading({ show: false })
                this.showSnack({
                  text: this.$t('alert.regist_failed'),
                  color: 'error',
                  timeout: 6000,
                })
              })
              .finally(() => {
                this.showLoading({ show: false })
                this.dialog = false
              })
          })
          .catch((error) => {
            console.error(error)
            this.loading = false
            this.showLoading({ show: false })
            this.showSnack({
              text: this.$t('alert.login_again'),
              color: 'error',
              timeout: 6000,
            })
            this.$router.replace('/manager/signin')
          })
      }
    },
    isRoot(createBy) {
      return createBy === 'root'
    }
  },
}
</script>
