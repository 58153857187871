import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"d-flex flex-column",attrs:{"height":"100vh"}},[_c(VCardTitle,{staticClass:"text-album pt-8 px-10 custom-title-head"},[_vm._v(_vm._s(_vm.$t('album.new_album'))+" ")]),_c(VForm,{ref:"newAlbumData",on:{"submit":function($event){$event.preventDefault();return _vm.createAlbum.apply(null, arguments)}}},[_c(VCardText,{staticClass:"px-10 bt-0"},[_c('label',{staticClass:"custom-label"},[_c('span',[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('album.album_name'))+" ")]),_c(VTextField,{attrs:{"outlined":"","dense":"","height":"44px","background-color":"#EEEEEE","rules":[_vm.required, _vm.textRules.regex, _vm.lengthRules, _vm.checkDuplicateAlbum]},model:{value:(_vm.newAlbumData.name),callback:function ($$v) {_vm.$set(_vm.newAlbumData, "name", $$v)},expression:"newAlbumData.name"}}),_c(VCardActions,{staticClass:"text-center d-block mt-3"},[_c('ENJIBtn',{staticClass:"px-9 mr-3 btn-font-size",attrs:{"color":"#333333","outlined":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")]),_c('ENJIBtn',{staticClass:"px-9 ml-3 btn-font-size",attrs:{"color":"#6170E8"},on:{"click":_vm.createAlbum}},[_vm._v(" "+_vm._s(_vm.$t('button.save'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }