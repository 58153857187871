<template>
  <v-card class="mx-auto text-center" align="center">
    <v-card-title
      class="d-block text-center"
      style="font-size: 20px; font-weight: 700; color: #333333"
    >
      {{ $t('alert.delete_confirm_title') }}
    </v-card-title>
    <v-card-text style="color: #161a1a; font-size: 16px">{{
      $t('alert.delete_confirm')
    }}</v-card-text>
    <v-card-actions class="text-center d-block pb-10 pt-0">
      <ENJIBtn
        @click="onClose"
        color="#333333"
        depressed
        outlined
        height="44"
        class="btn-outline"
      >
        {{ $t('member_info.cancel') }}
      </ENJIBtn>
      <ENJIBtn
        @click="onDelete"
        color="#DC313B"
        height="44"
        depressed
        class="btn-delete ml-0"
      >
        {{ $t('member_info.delete') }}
      </ENJIBtn>
    </v-card-actions>
  </v-card>
</template>
<style scoped>
.btn-outline {
  margin-right: 24px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.btn-delete {
  padding-left: 44px !important;
  padding-right: 44px !important;
}
</style>
<script>
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'

export default {
  components: {
    ENJIBtn,
  },
  methods: {
    onDelete() {
      this.$emit('delete')
    },
    onClose() {
      this.$emit('close')
    },
  },
}
</script>
