import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"100%"}},[_c(VSheet,{staticClass:"mt-4",attrs:{"height":"70"}},[_c(VToolbar,{staticClass:"calendar-toolbar",attrs:{"flat":"","dense":""}},[_c(VToolbarTitle,{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"ml-auto"},[_c(VBtn,{staticClass:"mr-4 toolbar-action",attrs:{"width-":"48","height":"48","outlined":"","color":"grey darken-2"},on:{"click":_vm.prev}},[_c(VIcon,{staticStyle:{"font-size":"32px"},attrs:{"small":""}},[_vm._v(" mdi-chevron-left ")])],1),_c(VBtn,{staticClass:"mr-4 toolbar-action today",attrs:{"width-":"48","height":"48","outlined":"","color":"grey darken-2"},on:{"click":_vm.setToday}},[_vm._v(" "+_vm._s(_vm.$t('kindergarten_list.today'))+" ")]),_c(VBtn,{staticClass:"mr-1 toolbar-action",attrs:{"width-":"48","height":"48","outlined":"","color":"grey darken-2"},on:{"click":_vm.next}},[_c(VIcon,{staticStyle:{"font-size":"32px"},attrs:{"small":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)],1),_c(VSheet,{staticClass:"calendar-panel",attrs:{"height":"calc(100vh - 200px)"}},[_c(VCalendar,{ref:"calendar",attrs:{"locale":_vm.getLocale,"color":"primary","events":_vm.events,"event-color":_vm.getEventColor,"type":"month","event-more":false,"event-text-color":_vm.getEventTextColor,"event-margin-bottom":4},on:{"click:day":_vm.handleDayClick,"change":_vm.updateRange},scopedSlots:_vm._u([{key:"event",fn:function({ event }){return [_c('div',{staticClass:"event-name text-center",domProps:{"innerHTML":_vm._s(event.name)}})]}},{key:"day",fn:function({ date }){return [(date === _vm.focus)?_c('div',{staticStyle:{"position":"absolute","top":"0","left":"0","right":"0","bottom":"0","border":"2px solid #6170e8","z-index":"2"},style:({
            'background-color': _vm.hasAbsenceDays.includes(date)
              ? 'transparent'
              : '#6170E81A',
          })},[_vm._v("   ")]):_vm._e(),_vm._l((_vm.hasAbsenceDays),function(datelack){return _c('div',{key:datelack},[(date === datelack)?_c('span',{staticStyle:{"position":"absolute","top":"0","left":"0","right":"0","bottom":"0"},style:({
              'background-color': '#DC313B1A',
              border: '1px solid #EEEEEE',
            })},[_vm._v("   ")]):_vm._e()])})]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }