<template>
  <v-container fluid>
    <qrcode-stream @detect="onDetect"></qrcode-stream>
    <v-btn class="back-btn mt-6 font-weight-bold" @click="back">
      {{ $t('member_info.back') }}
    </v-btn>
  </v-container>
</template>

<style>
.back-btn {
  border: 1px solid black;
  border-radius: 24px;
  background-color: white !important;
  padding: 10px 36px !important;
}
</style>
<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
  components: {
    QrcodeStream,
  },
  methods: {
    async onDetect(promise) {
      try {
        const { content, location } = await promise
        const parsedUrl = new URL(content)
        const qr = parsedUrl.searchParams.get('qr')
        this.$emit('onScanned', qr)
      } catch (error) {
        console.log(error)
        this.$emit('onScanned', '')
      }
    },
    back() {
      this.$emit('back')
    },
  },
}
</script>
