import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"flat":"","tile":""}},[_c(VCardText,{staticClass:"font-weight-bold custom-text"},[_vm._v(_vm._s(_vm.$t('member_contact.consult_fill_form')))]),_c('hr',{staticStyle:{"border-top":"1px solid #d0d0d0"},attrs:{"size":"2"}}),_c(VCardText,[_c(VForm,{ref:"sendItem"},[_c('label',{staticClass:"custom-label",staticStyle:{"left":"0","right":"0"}},[_c('span',[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('member_contact.consult_content')))]),_c(VTextarea,{staticClass:"custom-textarea",attrs:{"rows":15,"outlined":"","background-color":"#EEEEEE","rules":[_vm.required, _vm.textRules.regex],"disabled":_vm.loading},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c(VCardActions,{staticClass:"justify-center"},[_c('ENJIBtn',{attrs:{"color":"#6170E8","height":"44","width":"120","elevation":"0"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('button.send')))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }