<template>
  <v-card
    width="100%"
    class="messageBulk overflow-auto"
    height="100vh"
    min-height="100vh"
    :class="[isMobile ? '' : 'zero-border-radius-right-side']"
  >
    <v-card-title v-show="!isMobile" class="pt-8 px-10 custom-title-head"
      >{{ $t('navbar.menu_group_bulk_message') }}
    </v-card-title>
    <v-card-text
      v-if="isMobile"
      class="px-8 mb-0 text-line-card pb-0"
      style="border-bottom: 2px solid #c9c9c9"
    >
      <v-row class="my-0">
        <v-col cols="2" class="py-0">
          <v-btn icon height="44" class="pt-3" @click="back">
            <v-icon large color="black">mdi-chevron-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8" class="py-0">
          <v-card-text
            class="font-weight-bold custom-text text-center"
            style="line-height: 24px"
            >{{ $t('send_bulk_message.input_message') }}</v-card-text
          >
        </v-col>
        <v-col></v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else class="px-10 pb-5 text-line-card">{{
      $t('line_connect.enter_your_message')
    }}</v-card-text>
    <v-card-text class="bt-0" :class="[isMobile ? 'px-4' : 'px-10']">
      <v-form ref="send_data">
        <label class="font-weight-medium"
          ><span class="span-color-line-card">*</span
          >{{ $t('kindergarten_list.line_message') }}</label
        >
        <v-textarea
          v-model="message"
          outlined
          :rules="[messageBulkLengthRule]"
          :rows="13"
          clearable
          background-color="#EEEEEE"
          class="custom-textarea"
        />
        <file-pond
          :disabled="files.length > 3"
          ref="pond"
          name="filepond"
          v-model="files"
          :label-idle="$t('album.tap_to_attach_img')"
          :allow-multiple="true"
          :maxFiles="4"
          accepted-file-types="image/*"
          :server="createServerConfig()"
          @processfiles="handleUploadedFile"
          @warning="showWarning"
        />
        <div
          v-if="validateMsg !== ''"
          class="pl-3 red--text"
          style="font-size: 12px"
        >
          {{ validateMsg }}
        </div>

        <div class="d-flex flex-wrap">
          <v-chip
            v-for="(file, index) in fileList"
            :key="file.id"
            class="mt-2 mr-2"
            close
            @click:close="deleteFile(file)"
            >{{ file.fileName }}</v-chip
          >
        </div>
        <v-card-actions class="text-center d-block">
          <ENJIBtn
            @click="isMobile ? openMemberDialog() : close()"
            color="#333333"
            outlined
            :width="isMobile ? 140 : 120"
            class="mr-3 btn-font-size-line-card"
          >
            {{
              isMobile
                ? $t('send_bulk_message.sender_confirmation')
                : $t('button.close')
            }}
          </ENJIBtn>
          <ENJIBtn
            @click="submit"
            color="#6170E8"
            :width="isMobile ? 140 : 120"
            class="ml-3 btn-font-size-line-card"
            :class="{
              'disable-events':
                this.message.trim() === '' && this.fileList.length === 0,
            }"
          >
            {{ $t('member_contact.send') }}
          </ENJIBtn>
        </v-card-actions>
      </v-form>
    </v-card-text>
  </v-card>
</template>
<script>
import 'filepond/dist/filepond.min.css'
import vueFilePond from 'vue-filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import ENJIBtn from './buttons/ENJIBtn.vue'

const FilePond = vueFilePond(FilePondPluginFileValidateType)

export default {
  components: { ENJIBtn, FilePond },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    fileList: [],
    user: Object,
  },
  data() {
    return {
      files: [],
      validateMsg: '',
      message: '',
      maxImgSize: 10 * 1024 * 1024, // 10MB
      textRules: {
        regex: (v) =>
          /\S/.test(v) || !/\s/.test(v) || this.$t('alert.required_text'),
      },
      messageBulkLengthRule: (value) =>
        value.length <= 500 || this.$t('alert.max_length', { number: 500 }),
    }
  },
  methods: {
    createServerConfig() {
      return {
        process: (fieldName, file, _metadata, load) => {
          this.showLoading({ show: true, text: this.$t('alert.processing') })

          let maxSize
          let fileType
          let contentType
          if (file.type.startsWith('image/')) {
            fileType = 'image'
            maxSize = this.maxImgSize
            contentType = 'image/jpeg'
          } else {
            this.validateMsg = 'This file type is not supported'
            this.showLoading({ show: false })
            return
          }

          if (file.size > maxSize) {
            this.validateMsg = this.$t('send_bulk_message.over_10MB')
            this.showLoading({ show: false })
            return
          }
          setTimeout(() => {
            load('timeout')
          }, 30000)
          // TODO: move upload file to Bulk.vue
          if (!this.isMobile) {
            this.$cognito
              .isAuthenticated()
              .then(async (auth) => {
                // Get SignedURL
                this.$axios
                  .post('/media/upload/presignedurl', {
                    company_id: auth.companyId,
                    albumId: this.$constants.s3MsgAlbum,
                    type: fileType,
                  })
                  .then(async ({ data }) => {
                    //Upload file by SignedURL
                    const uploadConfig = {
                      headers: {
                        'Content-Type': contentType,
                      },
                      timeout: 600000, // Timeout set to 10 minutes
                    }
                    this.$axios
                      .put(data.signedUrl, file, uploadConfig)
                      .then(async (_response) => {
                        this.$axios
                          .post('/media/presignedurl', {
                            mediaId: data.objectId,
                            company_id: auth.companyId,
                            albumId: this.$constants.s3MsgAlbum,
                            type: 'image',
                          })
                          .then((response) => {
                            let result = {
                              id: data.objectId,
                              signedUrl: data.signedUrl,
                              fileName: file.name,
                              url: response.data.url,
                              albumId: this.$constants.s3MsgAlbum
                            }
                            load(response.data.url)
                            this.$emit('uploadFileSuccess', result)
                            this.validateMsg = ''
                            this.showLoading({ show: false })
                          })
                          .catch((error) => {
                            console.log(error)
                            this.showLoading({ show: false })
                          })
                      })
                      .catch((error) => {
                        console.log(error)
                        this.showLoading({ show: false })
                      })
                  })
                  .catch((error) => {
                    console.log(error)
                    this.showLoading({ show: false })
                  })
              })
              .catch((_session) => {
                this.$router.replace('/manager/signin')
              })
          } // end if !isMobile
          if (this.isMobile) {
            // Get SignedURL
            this.$axios
              .post('/media/upload/presignedurl', {
                company_id: this.user.companyId,
                albumId: this.$constants.s3MsgAlbum,
                type: fileType,
              })
              .then(async ({ data }) => {
                //Upload file by SignedURL
                const uploadConfig = {
                  headers: {
                    'Content-Type': contentType,
                  },
                  timeout: 600000, // Timeout set to 10 minutes
                }
                this.$axios
                  .put(data.signedUrl, file, uploadConfig)
                  .then(async (_response) => {
                    this.$axios
                      .post('/media/presignedurl', {
                        mediaId: data.objectId,
                        company_id: this.user.companyId,
                        albumId: this.$constants.s3MsgAlbum,
                        type: 'image',
                      })
                      .then((response) => {
                        let result = {
                          id: data.objectId,
                          signedUrl: data.signedUrl,
                          fileName: file.name,
                          url: response.data.url,
                          albumId: this.$constants.s3MsgAlbum,
                        }
                        load(response.data.url)
                        this.$emit('uploadFileSuccess', result)
                        this.validateMsg = ''
                        this.showLoading({ show: false })
                      })
                      .catch((error) => {
                        console.log(error)
                        this.showLoading({ show: false })
                      })
                  })
                  .catch((error) => {
                    console.log(error)
                    this.showLoading({ show: false })
                  })
              })
          } // end if isMobile
        },
      }
    },
    deleteFile(media) {
      this.showLoading({ show: true, text: this.$t('alert.processing') })
      this.$axios
        .post('/media/message/delete', {
          mediaId: media.id,
          company_id: media.company_id,
          albumId: this.$constants.s3MsgAlbum,
        })
        .then(async (_response) => {
          const indexToRemove = this.fileList.findIndex(
            (item) => item.id === media.id
          )
          if (indexToRemove !== -1) {
            this.$emit('clickRemoveFile', indexToRemove)
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 500) {
            //redirect to page error 500
            this.$router.replace('/500')
            return
          }
        })
    },
    back() {
      this.$emit('back')
    },
    close() {
      this.message = ''
      this.$emit('clickClose')
    },
    openMemberDialog() {
      this.$emit('onOpenMemberDialog')
    },
    handleUploadedFile() {
      this.showLoading({ show: false })
    },
    showWarning(error, _file) {
      switch (error.body) {
        case 'Max files':
          this.validateMsg = this.$t('send_bulk_message.more_4file')
          break
      }
    },
    submit() {
      if (this.$refs.send_data.validate()) {
        this.$emit('clickSend', this.message.trim(), this.fileList)
      }
    },
    reduceFileCount() {
      this.files[this.files.length - 1].release()
      this.files.pop()
    },
  },
}
</script>
<style scoped>
.text-line-card {
  font-size: 16px;
  color: #161a1a !important;
}

.span-color-line-card {
  color: red;
}
.label-line-card {
  font-size: 14px;
  color: #9e9e9e;
}
.btn-font-size-line-card {
  font-size: 16px !important;
  height: 44px !important;
}
.custom-textarea .v-input__control fieldset {
  border: none !important;
}
::v-deep(.filepond--root) {
  margin-bottom: 0;
}
::v-deep(.filepond--root .filepond--credits) {
  display: none;
}

::v-deep(.filepond--file-info) {
  display: none;
}

::v-deep(.filepond--list) {
  display: none;
}
</style>
