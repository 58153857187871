<template>
  <div style="height: 100%">
    <v-card
      class="main mx-auto d-flex"
      style="height: 100%; flex-direction: column"
    >
      <div class="message-bulk-container pa-0">
        <div v-if="status === 0" class="main mx-auto">
          <v-card-title class="border-bottom mb-2 custom-font-title">
            {{ $t('navbar.menu_group_bulk_message') }}
          </v-card-title>
          <hr size="2" style="border-top: 1px solid #d0d0d0" />
          <v-card-title>
            <v-row>
              <v-col cols="4">
                <InputSearch height="44" v-model="search" />
              </v-col>
              <v-col cols="1">
                <ENJIBtn
                  height="44"
                  class="px-2"
                  color="#333333"
                  outlined
                  @click="clearSelectedTags()"
                >
                  {{ $t('send_bulk_message.clear') }}
                </ENJIBtn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-chip-group
            style="height: 300px"
            class="selectable-chip pl-8 mb-4"
            v-model="selectedTags"
            multiple
            column
          >
            <v-chip
              class="mr-4"
              style="height: 40"
              v-for="tag in tagList"
              :key="tag.id"
            >
              {{ tag.name }}
            </v-chip>
          </v-chip-group>
          <hr size="2" style="border-top: 1px solid #d0d0d0" />

          <v-data-table
            height="calc(100vh - 410px)"
            :headers="headers"
            :items="filteredMemberList"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :custom-sort="customSort"
            :loading="loading"
            hide-default-footer
            fixed-header
            :loading-text="$t('alert.loading_child_info')"
            class="elevation-1"
            item-style="font-size: 16px"
            disable-pagination
            no-data-text=""
            :no-results-text="$t('alert.not_found')"
          >
            <template v-slot:[`item.checkbox`]="{ item }">
              <v-checkbox
                class="text-end checkbox-delete"
                v-model="receiverSelected"
                :value="item.id"
              ></v-checkbox>
            </template>
            <template v-slot:[`item.full_name`]="{ item }">
              {{ item.full_name }}
            </template>
            <template v-slot:[`item.supervisor_names`]="{ item }">
              <p class="mb-0">{{ item.supervisor_names }}</p>
            </template>
            <template v-slot:[`item.tags`]="{ item }">
              <v-chip-group column>
                <v-chip
                  color="#616161"
                  text-color="#FFFFFF"
                  style="height: 40"
                  v-for="tag in item.tags"
                  :key="tag.id"
                >
                  {{ tag.name }}
                </v-chip>
              </v-chip-group>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions class="d-flex justify-center">
            <ENJIBtn
              height="44"
              @click="createMessage"
              color="#6170E8"
              style="padding: 0 18px"
              :class="{ 'disable-events': (filteredMemberList.length == 0 || receiverSelected.length == 0) }"
            >
              {{ $t('send_bulk_message.create_message') }}
            </ENJIBtn>
          </v-card-actions>
        </div>
      </div>
    </v-card>
    <!-- dialog -->
    <div v-if="msgDialog || msgConfirmDialog">
      <v-dialog
        persistent
        v-model="msgDialog"
        width="600px"
        height="100%"
        :transition="dialogTransition"
        content-class="custom-dialog-content"
      >
        <MessageBulk
          ref="messageBulk"
          v-on:clickClose="onMsgDialogClose"
          v-on:clickSend="openMessageConfirm"
          :fileList="fileList"
          @clickRemoveFile="removeFile"
          @uploadFileSuccess="addFile"
        ></MessageBulk>
      </v-dialog>
      <v-dialog
        persistent
        v-model="msgConfirmDialog"
        width="600px"
        height="100vh"
        :transition="dialogTransition"
        content-class="custom-dialog-content"
      >
        <MessageBulkConfirm
          v-on:clickClose="onMsgConfirmDialogClose"
          v-on:clickSubmit="sendMsg"
          :messageConfirm="messageConfirm"
          :fileList="fileList"
        ></MessageBulkConfirm>
      </v-dialog>
    </div>
  </div>
</template>
<style scoped>
.selectable-chip {
  max-height: 100px;
}
::v-deep(.selectable-chip .v-slide-group__wrapper) {
  overflow: auto !important;
}
.selectable-chip .v-chip--active {
  background-color: #6170e8 !important;
  color: white !important;
  border: none !important;
  border: 1px solid #6170e8 !important;
}
.selectable-chip .v-chip.v-chip--clickable.theme--light.v-size--default {
  background-color: #fff;
  border: 1px solid #707070;
}

.disable-events {
  pointer-events: none;
  opacity: 0.3;
}
::v-deep(.zero-border-radius-right-side) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
</style>

<script>
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import InputSearch from '@/components/inputs/InputSearch.vue'
import MessageBulk from '@/components/MessageBulk.vue'
import MessageBulkConfirm from '@/components/MessageBulkConfirm.vue'

export default {
  components: {
    ENJIBtn,
    InputSearch,
    MessageBulk,
    MessageBulkConfirm,
  },
  computed: {
    APP_TITLE: {
      get: function () {
        return process.env.VUE_APP_TITLE
      },
    },
  },
  data() {
    return {
      allTagsIndex: 0,
      selectedTags: [0],
      status: -1,
      memberList: [],
      tagList: [],
      search: '',
      sortBy: 'sortNo',
      sortDesc: false,
      headers: [
        {
          text: '',
          value: 'checkbox',
          align: 'left',
          width: '10',
          sortable: false,
        },
        {
          text: this.$t('send_bulk_message.child_name'),
          value: 'full_name',
          align: 'left',
        },
        {
          text: this.$t('send_bulk_message.supervisor_name'),
          value: 'supervisor_names',
          align: 'left',
        },
        {
          text: this.$t('send_bulk_message.tag'),
          value: 'tags',
          align: 'left',
        },
      ],
      loading: true,
      msgDialog: false,
      msgConfirmDialog: false,
      messageConfirm: '',
      fileList: [],
      dialogTransition: 'fade-transition-right',
      receiverSelected: [],
    }
  },
  created() {
    this.isAuthenticated()
  },
  watch: {
    selectedTags(newVal, oldVal) {
      this.handleTagChanges(newVal, oldVal)
    },
    filteredMemberList(newVal) {
      this.receiverSelected = newVal.map(item => item.id)
    }
  },
  computed: {
    filteredMemberList() {
      let filteredData = this.memberList
      // Apply search filter
      if (this.search) {
        let textSearch = this.search.trim().toLowerCase()
        filteredData = filteredData.filter((item) => {
          const name = `${item.firstname} ${item.lastname}`
          return (
            name.toLowerCase().includes(textSearch) ||
            item.supervisors.some((supervisor) =>
              supervisor.name.toLowerCase().includes(textSearch)
            )
          )
        })
      }

      // Apply tag filter
      filteredData = filteredData.filter((item) => {
        if (this.selectedTags.includes(this.allTagsIndex)) {
          return true;
        } else {
          return this.selectedTags.some((index) =>
            item.tags.some((tag) => tag.id === this.tagList[index].id)
          );
        }
      })
      return filteredData
    },
  },
  methods: {
    isAuthenticated() {
      this.$cognito
        .isAuthenticated()
        .then((__auth) => {
          this.initialize()
        })
        .catch((_session) => {
          this.$router.replace('/manager/signin')
        })
    },
    async initialize() {
      // 園児情報取得
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          const idToken = auth.idToken
          const companyId = auth.companyId
          this.showLoading({ show: true })
          Promise.allSettled([
            this.getTags(idToken, companyId),
            this.getMembers(idToken, companyId),
          ]).then((results) => {
            const [tagResult, memberResult] = results
            if (
              tagResult.status == 'rejected' ||
              memberResult.status == 'rejected'
            ) {
              this.$router.replace('/500')
              return
            }
            this.tagList = tagResult.value.data
            this.tagList.unshift({name: this.$t('send_bulk_message.all_tag')})
            this.memberList = memberResult.value.data.map((item) => ({
              ...item,
              tags: this.tagList.filter((tag) => item.tags.includes(tag.id)),
            }))
            this.loading = false
            this.status = 0
            this.showLoading({ show: false })
          })
        })
        .catch((__error) => {
          this.loading = false
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.login_again'),
            color: 'error',
            timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })
    },
    customSort(items, sortBy, sortDesc) {
      let aTagNames = ''
      let bTagNames = ''
      return items.sort((a, b) => {
        if (sortBy[0] === 'tags') {
          aTagNames = a.tags.map(tag => tag.name.toLowerCase()).join('');
          bTagNames = b.tags.map(tag => tag.name.toLowerCase()).join('');
        } else if (sortBy[0] === 'full_name') {
          aTagNames = a.full_name
          bTagNames = b.full_name
        } else if (sortBy[0] === 'supervisor_names') {
          aTagNames = a.supervisor_names
          bTagNames = b.supervisor_names
        }
        if (!sortDesc[0]) {
              return aTagNames.localeCompare(bTagNames);
          } else {
              return bTagNames.localeCompare(aTagNames);
          }
      });
    },
    clearSelectedTags() {
      this.selectedTags = []
    },
    handleTagChanges(newTags, oldTags) {
      if (!newTags.includes(this.allTagsIndex) && !oldTags.includes(this.allTagsIndex)) {
        return
      }

      newTags.forEach(newTag => {
        if (!oldTags.includes(newTag)) {
          if (newTag != this.allTagsIndex) {
            this.selectedTags = this.selectedTags.filter(item => item !== this.allTagsIndex)
          } else {
            this.selectedTags = [this.allTagsIndex]
          }
        }
      })
    },
    onMsgDialogClose() {
      this.msgDialog = false
      // TODO: Delete list files are uploaded
      this.fileList = []
    },
    createMessage() {
      this.msgDialog = true
    },
    openMessageConfirm(msg, files) {
      this.msgDialog = false
      this.msgConfirmDialog = true
      this.messageConfirm = msg
      this.fileList = files
    },
    onMsgConfirmDialogClose() {
      this.msgConfirmDialog = false
      this.msgDialog = true
    },
    // send message to LINE
    sendMsg() {
      this.$cognito
        .isAuthenticated()
        .then(async (auth) => {
          this.showLoading({ show: true, text: this.$t('alert.processing') })
          let apiPromises = []
          this.filteredMemberList.forEach((member) => {
            if (!this.receiverSelected.includes(member.id)) {
              return
            }
            member.supervisors.forEach((supervisor) => {
              if (supervisor.connected === false) {
                return
              }
              apiPromises.push(
                this.sendSingleMsg(
                  auth.idToken,
                  auth.name,
                  supervisor.userId,
                  supervisor.name,
                  auth.companyId,
                  this.fileList
                )
              )
            })
          })
          Promise.allSettled(apiPromises).then((results) => {
            const isSuccess = results.every(
              (result) =>
                result.status === 'fulfilled' && result.value.status === 200
            )

            if (isSuccess) {
              this.showSnack({
                text: this.$t('alert.sending_completed'),
                timeout: 2000,
              })
            } else {
              this.showSnack({
                text: this.$t('alert.send_failed'),
                color: 'error',
                timeout: 4000,
              })
            }
            this.showLoading({ show: false })
            this.status = 0
            this.msgConfirmDialog = false
            this.memberDialog = false
            // TODO: Delete list files are uploaded
            this.fileList = []
          })
        })
        .catch((__error) => {
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.login_again'),
            color: 'error',
            timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })
    },
    sendSingleMsg(idToken, adminName, userId, parentName, companyId, fileList) {
      return this.$axiosM.post(
        '/line/message',
        {
          sender: adminName,
          userId,
          receiver: parentName,
          message: this.messageConfirm,
          method: this.$constants.messageMethod.bulk,
          company_id: companyId,
          attachFile: fileList.map((file) => ({ url: file.url, key: `${companyId}/${file.albumId}/${file.id}` })),
        },
        {
          headers: {
            Authorization: idToken,
          },
        }
      )
    },
    getMembers(idToken, companyId) {
      return this.$axiosM.post(
        '/member/connected/all',
        { company_id: companyId },
        {
          headers: {
            Authorization: idToken,
          },
        }
      )
    },
    getTags(idToken, companyId) {
      return this.$axiosM.post(
        '/tag/list',
        {
          company_id: companyId,
        },
        {
          headers: {
            Authorization: idToken,
          },
        }
      )
    },
    removeFile(index) {
      this.$refs.messageBulk.reduceFileCount()
      this.fileList.splice(index, 1)
      this.showLoading({ show: false })
    },
    addFile(file) {
      this.fileList.unshift(file)
    },
    formatSupervisorNames(supervisors) {
      const supervisorNames = supervisors.map((supervisor) => supervisor.name)
      const combinedNames = supervisorNames.join(
        this.$t('kindergarten_list.comma')
      )
      return combinedNames
    },
  },
}
</script>
