<template>
  <div>
    <v-card style="min-height: 100%">
      <v-card-title class="pt-8 px-10 custom-title-head">{{
        $t('member_info.child_info')
      }}</v-card-title>
      <v-container class="py-0">
        <v-form ref="memberData" class="px-6 py-0">
          <label class="label-form-member"
            ><span>*</span
            >{{ $t('kindergarten_list.form_mobile_numnber') }}</label
          >
          <v-text-field
            type="tel"
            v-model="memberData.phone"
            outlined
            :rules="[required, phoneRules.regex, maxPhoneRules]"
            dense
            background-color="#EEEEEE"
            style="font-size: 16px"
            height="44px"
            class="custom-input"
          >
          </v-text-field>
          <v-row justify="center" align="baseline">
            <v-col>
              <label class="label-form-member"
                ><span>*</span
                >{{ $t('kindergarten_list.form_child_first_name') }}</label
              >
              <v-text-field
                v-model="memberData.firstname"
                outlined
                :rules="[required, textRules.regex, textNameRules]"
                dense
                background-color="#EEEEEE"
                style="font-size: 16px"
                height="44px"
                class="custom-input"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <label class="label-form-member"
                ><span>*</span
                >{{ $t('kindergarten_list.form_child_last_name') }}</label
              >
              <v-text-field
                v-model="memberData.lastname"
                outlined
                :rules="[required, textRules.regex, textNameRules]"
                dense
                background-color="#EEEEEE"
                style="font-size: 16px"
                height="44px"
                class="custom-input"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <label class="label-form-member"
            ><span>*</span
            >{{ $t('kindergarten_list.form_child_furigana') }}</label
          >
          <v-text-field
            v-model="memberData.kana"
            outlined
            :rules="[required, textRules.regex, fullNameRules]"
            dense
            background-color="#EEEEEE"
            style="font-size: 16px"
            height="44px"
            class="custom-input"
          >
          </v-text-field>
          <label class="label-form-member"
            ><span>*</span
            >{{ $t('kindergarten_list.form_child_birthday') }}</label
          >
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            ref="menu"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="memberData.birthday"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                :rules="[required]"
                dense
                background-color="#EEEEEE"
                style="font-size: 16px"
                height="44px"
                class="custom-input"
                placeholder="yyyy/mm/dd"
                append-icon="mdi-calendar"
                @click:append="onIconClick"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="memberData.birthday"
              ref="picker"
              :active-picker.sync="activePicker"
              :max="new Date().toISOString().substr(0, 10)"
              :picker-date="pickerDate"
              locale="jp-ja"
              :day-format="(date) => new Date(date).getDate()"
              :min="pickerMinDate"
              @input="menu = false"
            >
            </v-date-picker>
          </v-menu>
          <div
            style="
              max-height: 200px;
              overflow-y: auto;
              background-color: #eeeeee;
              border-radius: 2px;
            "
            class="px-3"
          >
            <template>
              <v-chip-group
                active-class="active-chip"
                column
                multiple
                v-model="selectedTags"
              >
                <v-chip
                  style="height: 40"
                  v-for="(tag, index) in tagList"
                  :key="index"
                >
                  {{ tag.name }}
                </v-chip>
              </v-chip-group>
            </template>
          </div>
          <div class="mt-6" v-if="memberData.supervisors.length > 0">
            <label class="label-form-member"
              ><span>*</span>{{ $t('kindergarten_list.form_supervisor_name') }}
            </label>

            <v-text-field
              v-for="(supervisor, index) in memberData.supervisors"
              :key="index"
              v-model="supervisor.name"
              outlined
              :rules="[required, textRules.regex, fullNameRules]"
              dense
              background-color="#EEEEEE"
              style="font-size: 16px"
              height="44px"
              class="custom-input"
            >
            </v-text-field>
          </div>

          <v-card-actions class="text-center d-block mt-3">
            <ENJIBtn
              @click="close"
              color="#333333"
              outlined
              class="px-9 mr-3 btn-font-size-line-card"
            >
              {{ $t('button.close') }}
            </ENJIBtn>
            <ENJIBtn
              @click="submit"
              color="#6170E8"
              class="px-9 ml-3 btn-font-size-line-card"
            >
              {{ $t('button.save') }}
            </ENJIBtn>
          </v-card-actions>
        </v-form>
        <v-card-text v-if="this.memberData.id">
          <v-row align="center" class="mt-4">
            <v-col class="d-flex justify-end pr-0">
              <v-checkbox
                class="text-end checkbox-delete"
                v-model="checkedDelete"
                :label="$t('checkbox.delete')"
              ></v-checkbox>
            </v-col>
            <v-col>
              <ENJIIconBtn
                icon="mdi-trash-can-outline"
                color="#DC313B"
                :class="{ 'disable-events': !checkedDelete }"
                @click="deleteMember"
              >
                {{ $t('member_face.delete') }}
              </ENJIIconBtn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-row align="center" class="mt-10">
            <v-col class="d-flex justify-end pb-12"> </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>
<style>
.custom-input .v-input__control fieldset {
  border: none;
}
.label-form-member {
  font-size: 14px;
  color: #9e9e9e;
}
.label-form-member > span {
  color: red;
}
.active-chip {
  background-color: #6170e8 !important;
  color: white !important;
  border: 1px solid #6170e8;
}
.theme--light.v-chip:not(.v-chip--active) {
  background-color: #fff;
  border: 1px solid #707070;
}
</style>

<script>
import dayjs from 'dayjs'
import ENJIBtn from '@/components/buttons/ENJIBtn.vue'
import Tags from '@/components/Tags.vue'
import ENJIIconBtn from '@/components/buttons/ENJIIconBtn.vue'

export default {
  components: {
    ENJIBtn,
    Tags,
    ENJIIconBtn,
  },
  props: {
    memberData: Object,
    tagList: Array,
  },
  data() {
    return {
      checkedDelete: false,
      loading: false,
      activePicker: null,
      pickerDate: '2010-01-01',
      pickerMinDate: '2000-01-01',
      menu: false,
      required: (value) => !!value || this.$t('alert.required_text'),
      textNameRules: (value) =>
        value.length <= 10 || this.$t('alert.max_length', { number: 10 }),
      fullNameRules: (value) =>
        value.length <= 25 || this.$t('alert.max_length', { number: 25 }),
      maxPhoneRules: (value) =>
        value.length <= 13 || this.$t('alert.max_length', { number: 13 }),
      textRules: {
        regex: (v) => /\S/.test(v) || this.$t('alert.required_text'),
      },
      phoneRules: {
        regex: (v) => /^[+0-9\s]*$/.test(v) || this.$t('alert.digit_only'),
      },
      zipRules: {
        regex: (v) => /^[0-9]{7}$/.test(v) || this.$t('alert.7digit_only'),
      },
      emailRules: {
        regex: (v) =>
          /^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || this.$t('alert.invalid_mail'),
      },
      selectedTags: [],
    }
  },
  mounted() {
    this.initPickerDate()
    this.selectedTags = this.memberData.tags.map((obj) => {
      return this.tagList.findIndex((item) => item.id === obj.id)
    })
  },
  watch: {
    menu(val) {
      val &&
        setTimeout(
          () => ((this.activePicker = 'YEAR'), (this.pickerDate = null))
        )
    },
  },
  methods: {
    initPickerDate() {
      this.pickerDate = dayjs()
        .subtract(10, 'y')
        .startOf('y')
        .format(this.$constants.dateFormat)
      this.pickerMinDate = dayjs()
        .subtract(20, 'y')
        .startOf('y')
        .format(this.$constants.dateFormat)
    },
    close() {
      this.$emit('clickClose')
    },
    deleteMember() {
      this.$cognito
        .isAuthenticated()
        .then((auth) => {
          // 園児削除
          this.$axiosM
            .post(
              '/member/delete',
              {
                id: this.memberData.id,
              },
              {
                headers: {
                  Authorization: auth.idToken,
                },
              }
            )
            .then((_response) => {
              this.updateMemberCount(auth, -1)
                .then((_response) => { 
                  this.loading = false
                  this.$emit('delete')
                })
                .catch((error) => {
                  console.log(error)
                  this.loading = false
                  this.showLoading({ show: false })
                })
            })
            .catch((_error) => {
              this.loading = false
              this.showLoading({ show: false })
              this.showSnack({
                text: this.$t('alert.delete_failed'),
                color: 'error',
                timeout: 6000,
              })
            })
        })
        .catch((_session) => {
          this.loading = false
          this.showLoading({ show: false })
          this.showSnack({
            text: this.$t('alert.login_again'),
            color: 'error',
            timeout: 6000,
          })
          this.$router.replace('/manager/signin')
        })
    },
    submit() {
      if (this.$refs.memberData.validate()) {
        this.loading = true
        this.showLoading({ show: true })
        this.$cognito.isAuthenticated().then(async (auth) => {
          this.memberData.supervisors.forEach((supervisors, index) => {
            this.memberData.supervisors[index].name =
              this.memberData.supervisors[index].name.trim()
          })
          const tags = this.selectedTags.map((index) => this.tagList[index].id)
          const created_at = this.memberData.created_at || dayjs().valueOf()
          this.$axiosM
            .post(
              '/member/update',
              {
                ...this.memberData,
                firstname: this.memberData.firstname.trim(),
                lastname: this.memberData.lastname.trim(),
                kana: this.memberData.kana.trim(),
                tags: tags,
                company_id: auth.companyId,
                created_at,
              },
              {
                headers: {
                  Authorization: auth.idToken,
                },
              }
            )
            .then(() => {
              if (this.memberData.id === '') {
                this.updateMemberCount(auth, 1)
                  .then((_response) => {
                    this.loading = false
                    this.showLoading({ show: false })
                    this.$emit('submit')
                  })
                  .catch((error) => {
                    console.log(error)
                    this.loading = false
                    this.showLoading({ show: false })
                  })
              } else {
                this.loading = false
                this.showLoading({ show: false })
                this.$emit('submit')
              }
            })
            .catch((_error) => {
              this.loading = false
              this.showLoading({ show: false })
            })
        })
      } else {
        this.$vuetify.goTo(0)
      }
    },
    updateMemberCount(auth, count) {
      const startOfDate = dayjs().startOf('date').valueOf()
      return this.$axiosM.post(
        '/history/update/member-count',
        {
          startOfDate,
          company_id: auth.companyId,
          count,
          memberId: count === -1 ? this.memberData.id : undefined,
        },
        { headers: { Authorization: auth.idToken } }
      )
    },
    onIconClick() {
      this.$refs.menu.isActive = !this.$refs.menu.isActive
    },
  },
}
</script>
