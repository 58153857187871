<template>
  <v-snackbar
    v-model="show"
    :color="color"
    :timeout="timeout"
    :multi-line="true"
    elevation="0"
    :min-height="isMobile ? 48 : 57"
    :height="isMobile ? 48 : undefined"
    :min-width="isMobile ? undefined : 482"
    rounded
    class="mb-4"
    :class="{ 'mobile-snackbar': isMobile, 'pc-snackbar': !isMobile }"
    :content-class="isMobile ? '' : 'text-center'"
  >
    {{ text }}
    <template v-if="isMobile" v-slot:action="{ attrs }" style="height: 100%">
      <v-icon color="white" size="27" @click="show = false">mdi-close</v-icon>
    </template>
  </v-snackbar>
</template>
<style scoped>
.mobile-snackbar ::v-deep(.v-snack__wrapper.theme--dark) {
  background-color: #424242;
}
.mobile-snackbar ::v-deep(.v-snack__content) {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
}
.mobile-snackbar ::v-deep(.theme--dark .v-snack__content) {
  border-right: 2px solid #121212 !important;
}
.mobile-snackbar ::v-deep(.error .v-snack__content) {
  border-right: 2px solid #b30000 !important;
}
.mobile-snackbar ::v-deep(.v-snack__action) {
  justify-content: center;
  width: 48px !important;
  margin-right: 0px;
}

.pc-snackbar ::v-deep(.v-snack__wrapper.theme--dark) {
  background-color: #5d6060;
}
.pc-snackbar ::v-deep(.v-snack__content) {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
}
</style>

<script>
export default {
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'snackbar/SHOW_MESSAGE') {
        this.text = state.snackbar.text
        this.color = state.snackbar.color
        this.timeout = state.snackbar.timeout
        this.show = true
        this.isMobile = state.snackbar.isMobile
      }
    })
  },
  data() {
    return {
      text: '',
      color: '',
      timeout: -1,
      show: false,
      isMobile: false,
    }
  },
}
</script>
