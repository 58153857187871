import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"height":"100vh"}},[_c(VCardTitle,{staticClass:"pt-8 px-10 custom-title-head"},[_vm._v(_vm._s(_vm.$t('line_connect.line_sending'))+" ")]),_c(VCardText,{staticClass:"px-10 pb-0 text-line-card"},[_vm._v(_vm._s(_vm.$t('line_connect.enter_your_message')))]),_c(VCardText,{staticClass:"px-10 py-4 text-line-card"},[_vm._v(_vm._s(_vm.$t('line_connect.mr', { name: _vm.formatSupervisorNames(_vm.lineData.supervisors), })))]),_c(VCardText,{staticClass:"px-10 bt-0"},[_c(VForm,{ref:"send_data"},[_c('label',[_c('span',{staticClass:"span-color-line-card"},[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('kindergarten_list.line_message')))]),_c(VTextarea,{staticClass:"custom-textarea",attrs:{"outlined":"","rules":[_vm.required, _vm.messageLengthRule],"rows":13,"clearable":"","background-color":"#EEEEEE"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),_c(VCardActions,{staticClass:"text-center d-block"},[_c('ENJIBtn',{staticClass:"px-9 mr-3 btn-font-size-line-card",attrs:{"color":"#333333","outlined":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('button.close'))+" ")]),_c('ENJIBtn',{staticClass:"px-9 ml-3 btn-font-size-line-card",attrs:{"color":"#6170E8"},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('member_contact.send'))+" ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }